import {
    SpaceBetween, Box, Link, Icon, Header
} from '@cloudscape-design/components';
import React from 'react';
const Architecture = props => {
    return (
        <React.Fragment>
            <Box variant='strong'>Architecture</Box>
            <Box><img src={props.architecture} alt="Artchitecture Diagram" width='100%'/></Box>
        </React.Fragment>
    )
}
export default Architecture;