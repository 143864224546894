// CloudFront table tooltip content
import {
  HelpPanel, SpaceBetween, Box, Icon,
} from '@cloudscape-design/components';

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const CFTableTools = [
  <HelpPanel header={<h2>PoP (Point of Presence)</h2>} loadingText="index1">
    <p>
      The CloudFront Edge location closest to user serving the request. Identified from <code>x-amz-cf-pop</code> response header
    </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Cache</h2>} loadingText="index2">
    <div>
      <ul>
        <li>Hit - if content was served from cache</li>
        <li>Miss - content was not present in the Edge location and was fetched from origin</li>
      </ul>
    </div>
  </HelpPanel>,
  <HelpPanel header={<h2>Compression</h2>} loadingText="index3">
    <p>
      Specify what compression was applied to the response payload. Depending on your browsers <code>Accept-Encoding</code>
      request header.
    </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Age</h2>} loadingText="index4">
    <p>
      Time in seconds since the object was cached.
    </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Time taken</h2>} loadingText="index5">
    <p>
      Round trip time taken in milliseconds to fetch the response payload.
    </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Origin Region</h2>} loadingText="index6">
    <p>
      AWS Region where the backend origin is deployed, i.e, where is your ALB & S3 bucket located?
    </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Time to First Byte</h2>} loadingText="index7">
    <p>
      Time to First Byte (TTFB) in milliseconds is the difference between responseStart - requestStart in Performance API

    </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Time to Last Byte</h2>} loadingText="index8">
    <p>
      Time to Last Byte (TTLB) in milliseconds is the difference between responseEnd - requestStart in Performance API
      https://siusin.github.io/perf-timing-primer/
    </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Total time</h2>} loadingText="index9">
    <p>
      Total time taken in milliseconds to fetch the object. Includes DNS lookup, connection establishment and time taken to send/receive data.
      Learn more https://siusin.github.io/perf-timing-primer/
    </p>
    <SpaceBetween direction='vertical' size='xxs'>
      <Box><strong>p90 - 90th percentile</strong> 90% of the requests will be faster than the value of p90</Box>


      <Box><strong>P50 - 50th percentile. </strong>50% of the request will be faster than the value of p50</Box>
      <Box>The Lower the number, the faster the performance</Box>
    </SpaceBetween>
  </HelpPanel >,
  <HelpPanel header={<h2>Size</h2>} loadingText="index10">
    <p>
      Size of the content in bytes.1 kilobyte = 1,024 bytes.  </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Compression</h2>} loadingText="index11">
    <p>
      Gzip and Brotli (Br) supported by CloudFront.   </p>
  </HelpPanel>,
  <HelpPanel header={<h2>HTTP Protocol</h2>} loadingText="index12">
    <ul>
      <li>
        http/1.1 - the legacy standard using TCP
      </li>
      <li>
        h2 - HTTP/2 using the TCP protocol and transmission
      </li>
      <li>
        h3 - HTTP/3 using the Quic protocol and UDP transmission
      </li>
    </ul>
  </HelpPanel >,
  <HelpPanel header={<h2>TCP/TLS negotiation time</h2>} loadingText="index13">
    <p>
      TCP/TLS negotiation time in milliseconds comes here    </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Data Transfer</h2>} loadingText="index14">
    <p>
      Enter the monthly volume of DTO (data transfer out) that you are currently incurring.You can find this information in your Cost Explorer or AWS Bill.   </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Location </h2>} loadingText="index15">
    <p>
      Where is your content being delivered? Select the country where your end users are located.   </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Compressible </h2>} loadingText="index16">
    <p>
      Content types like Javascript,CSS and other non-binary formats are generally
      compressible using gzip/brotli. Images, Audio, and Video are generally not compressible file types and would reduce the percentage.
      Estimate how compressible your content is based on the file type.  </p>
  </HelpPanel>,
  <HelpPanel header={<h2>CloudFront Savings Bundle </h2>} loadingText="index17"
    footer={
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/savings-bundle.html" target="_blank">
              CloudFront Savings Bundle
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      The CloudFront Security Savings Bundle is a self-service offering that allows customers to achieve 30% cost-savings on CloudFront and
      10% cost-savings on WAF in exchange for a commit.This is available to all customers (except those already on a Private Pricing Agreement) and there is no minimum commitment.</p>
  </HelpPanel>,
];

export default CFTableTools;
