import { useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { CookiesProvider, useCookies } from 'react-cookie'

export default function useAuth() {
  const [isAuth, setIsAuth] = useState(null);
  const navigate = useNavigate();
  // const [cookies, setCookie] = useCookies(['CognitoIdentityServiceProvider.us-east-1_vXh8uo1UN'])

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      // console.log("in hook useEffect");
      // console.log(event);
      // console.log(data)
      switch (event) {
        case "signIn":
          //console.log(Auth.currentAuthenticatedUser)
          Auth.currentAuthenticatedUser().then((currentUser) => {
            // console.log("in signin promise ");
            // console.log(JSON.stringify(currentUser));
            currentUser.expiryTTL = new Date().getTime() + (86400*1000);
            setIsAuth(currentUser);
            localStorage.setItem("user", JSON.stringify(currentUser));
            // setCookie('CognitoIdentityServiceProvider.us-east-1_vXh8uo1UN', currentUser, { path: '/' ,secure})
          })
          break;
        case "oAuthSignOut":
          console.log("signing out");
          // console.log(isAuth);
          setIsAuth(null);
          localStorage.removeItem("user");
          break;
        case "signOut":
          console.log("signing out");
          // console.log(isAuth);
          setIsAuth(null);
          localStorage.removeItem("user");
          navigate("/");
          break;
        case "cognitoHostedUI":
          break;
      }
    });
  }, []);

  // console.log("isauth invoked and returned");
  // console.log(localStorage.getItem("user"))
  let userStorage = null;
  if(localStorage.getItem("user")){
    try{
     userStorage = JSON.parse(localStorage.getItem("user"));
    }catch(e){
      console.log("No user",e);
      userStorage = null;
    }
  }
  if(isAuth || userStorage){
    const currentTime = new Date().getTime();
    // console.log("Current time :",currentTime);
    if((isAuth && currentTime>isAuth.expiryTTL) || (userStorage && currentTime>userStorage.expiryTTL)){
      localStorage.removeItem("user");
      // console.log("user TTL has passed");
      userStorage = false;
      setIsAuth(null);
    }
  }
  return isAuth ? isAuth : userStorage;
  // return isAuth ? isAuth : false;
}