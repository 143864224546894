export const SPECS=`Here is the configuration for your CloudFront distribution:

Origins:
- S3 bucket: A new S3 bucket needs to be provisioned
- ALB: The hostname is origin.pgjaiganesh.com  

Cache Behaviors:
- /images/*, *.css, *.js -> S3 bucket origin 
  - Using 'CachingOptimized' managed cache policy
  - Default compression and response header policy settings
- All other paths -> ALB origin
  - Using 'AllViewerExceptHostHeader' managed origin request policy  
  - Default compression and response header policy settings

Logging:
- Enable standard logging to a new S3 bucket
- No real-time log streaming enabled

Alternate Domain:
- Use custom domain www.pgjaiganesh.com
- Provision a new ACM certificate in us-east-1 region

Other Advanced Settings: 
- Enable HTTP/2 and HTTP/3 protocol support
- No geo-restrictions configured
- Configure custom error pages for 4xx HTTP status codes with 4 minute TTL
- No continuous deployment or origin failover configuration
`