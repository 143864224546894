import React from 'react';
import {
    TopNavigation,
    Box,
    Link,
    Input,
    Icon,
} from '@cloudscape-design/components';
import '../styles/form.scss';
import logo from './images/Edgeportal.png';
// import Icon from "@cloudscape-design/components/icon";

export default props => {

    return (
        <TopNavigation
            className='toppanel'
            identity={{
                href: "/",
                title: <React.Fragment>
                    <Box variant='strong' > Edge Services Demo</Box> {props.label && <Box variant='code'>- {props.label}</Box>}
                </React.Fragment>,
                logo: {
                    src: logo,
                    alt: "Edge Services Demo"
                }
            }} 
            // search={
            //     <Link href="" variant="secondary">
                     
            //     </Link>
            //   }
              utilities={[
                {
                  type: "button",
                  text: <Icon name="search" variant="subtle"/>,
                  href: "/contentdiscovery",
                }
            ]}
            // utilities={

            //     <Icon name="search" variant="subtle" />

            // }
            // utilities={[
            // "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSIzMXB4IiB2aWV3Qm94PSIwIDAgNDMgMzEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxnIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxyZWN0IGZpbGw9IiMyMzJmM2UiIHN0cm9rZT0iI2Q1ZGJkYiIgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSI0MiIgaGVpZ2h0PSIzMCIgcng9IjIiPjwvcmVjdD4KICAgICAgICA8dGV4dCBmb250LWZhbWlseT0iQW1hem9uRW1iZXItUmVndWxhciwgQW1hem9uIEVtYmVyIiBmb250LXNpemU9IjEyIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHRzcGFuIHg9IjkiIHk9IjE5Ij5Mb2dvPC90c3Bhbj4KICAgICAgICA8L3RleHQ+CiAgICA8L2c+Cjwvc3ZnPgo=",
            //     {
            //         type: "menu-dropdown",
            //         iconName: "notification",
            //         title: "Notifications",
            //         ariaLabel: "Notifications (unread)",
            //         badge: true,
            //         // disableUtilityCollapse: false,
            //         items: [
            //             {
            //                 id: "settings-org",
            //                 text: "Organizational settings"
            //             },
            //             {
            //                 id: "settings-project",
            //                 text: "Project settings"
            //             }
            //         ]
            //     },
            // ]}
            i18nStrings={{
                searchIconAriaLabel: "Search",
                searchDismissIconAriaLabel: "Close search",
                overflowMenuTriggerText: "More",
                overflowMenuTitleText: "All",
                overflowMenuBackIconAriaLabel: "Back",
                overflowMenuDismissIconAriaLabel: "Close menu"
            }}
        />
    );
}
