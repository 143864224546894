import React from 'react';
import DataProvider from '../../resources/data-provider';
import ServiceNavigation from '../ServiceNavigation.jsx';
import ExtendingPanel from '../ExtendingPanel.jsx';
import SynopsisPanel from '../SynopsisPanel';
import prettyBytes from 'pretty-bytes';
import TopPanel from '../TopPanel';
import BottomPanel from '../BottomPanel';
import architectureImg from './images/architecture.png';
import {
    AppLayout,
    Button,
    ColumnLayout,
    Form,
    SpaceBetween,
    Container,
    Link,
    HelpPanel,
    Box,
    Icon,
    Cards,
    Select,
    Table,
    Alert,
} from '@cloudscape-design/components';

import '../../styles/form.scss';
import BrowserCheck from '../BrowserCheck';
import { sendAnalytics, sendError } from '../../resources/rum-provider.ts';
const demoModule = "ImageOptimization";

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class ImageOptimization extends React.Component {

    constructor(props) {
        super(props);
        // console.log(performance.now());

        this.state = { images: [], alert: false };
        this.dataProvider = new DataProvider();
        this.onInputChange = this.onInputChange.bind(this);
        this.handleClick = this.handleClick.bind(this);

        const observer = new PerformanceObserver((list, obj) => {
            list.getEntries()
                .forEach((entry) => {
                    // console.log("Entry1 :", entry);
                    // console.log("Resized url :", this.state.resizedUrl);
                    if (entry.name === this.state.resizedUrl) {
                        console.log("Entry1 :", entry);
                        let attrs = {};
                        let elem = entry.serverTiming.find(el => el.name === 'format');
                        attrs['type'] = elem ? elem.description : this.state.format ? this.state.format.label : this.state.selectedImage[0].type;
                        attrs['size'] = entry.encodedBodySize;
                        this.setState({ resizedImageAttrs: attrs });
                        // console.log("Attrs :%j", attrs);
                    }
                    // Process "mark" and "frame" events
                });
        });
        observer.observe({
            entryTypes: ["resource"]
        });

        // function perfObserver(list, observer) {
        //     console.log("In perfObserver");
        //     // Process the "measure" event
        // }
    }

    componentDidCatch(error, info) {
        console.log(error);
        // cwr('recordError', error);
        sendError({ demo: demoModule, error: error });
    };

    componentDidMount() {
        this.dataProvider.getData('images', response => this.setState({ images: response }));
    }

    onInputChange(name, e) {
        e.preventDefault();
        // console.log("In onInputChange %s %j", name, e);

        if (name === 'image') {
            let attrs = {};
            // console.log("Event :%j", e['target'].clientWidth);
            // exifr.parse(document.getElementById("modifiedImage"))
            //     .then(output => console.log("output :%j", output));

            attrs['height'] = e['target'].naturalHeight;
            attrs['width'] = e['target'].naturalWidth;
            this.setState({ resizedImageDimensions: attrs });

            // console.log("In image attrs :%j", attrs);
        }
        else if (name === 'resizeTo' || name === 'watermark' || name === 'format') {
            this.setState({ [name]: e.detail.selectedOption })
        }
        else {
            this.handleAlert();
            this.setState({ [name]: e.detail.selectedItems })
        }
    }

    handleAlert() {
        console.log("In handleAlert");
        this.setState({ alert: false });
    }

    handleClick(e) {
        e.preventDefault();
        performance.clearResourceTimings();
        if (!this.state.selectedImage || !(this.state.format || this.state.watermark || this.state.resizeTo)) {
            console.log("Image not selected");
            this.setState({ alert: true });
            return;
        }
        else {
            this.handleAlert();
        }
        sendAnalytics({ demo: demoModule });

        // console.log("In handleClick");
        let selectedImage = this.state.selectedImage[0];
        let resizedUrl = `${selectedImage.cfDomain}${selectedImage.orgPath}${selectedImage.name}?`;
        let op = [];
        if (this.state.resizeTo) {
            op.push(`${this.state.resizeTo.value}`);
        }
        if (this.state.watermark) {
            op.push(`${this.state.watermark.value}`);
        }
        if (this.state.format) {
            op.push(`${this.state.format.value}`);
        }
        resizedUrl = `${resizedUrl}op=${op.join(",")}&r=${Math.random(100)}`;
        this.setState({ resizedUrl: resizedUrl });

        // this.dataProvider.getCFData({}, resizedUrl, response => {
        //     console.log("Response :%j", response);
        // }, this.dataProvider);

        // this.populateAdditionalInfo(resizedUrl);
    }

    // populateAdditionalInfo(url) {
    //     const resourceTiming = performance.getEntriesByType("resource");
    //     console.log("Resource timing :%j", resourceTiming);
    //     console.log("Resized url ", url);
    //     // this.urls[url]['performance'] = [];
    //     resourceTiming
    //         .filter(res => (res.name === url))
    //         .map(res => {
    //             console.log("filtered :%j", res);
    //             // this.urls[url]['performance'].push(res);
    //         });
    // }

    render() {

        return (
            <React.Fragment>
                <TopPanel label={demoModule} />
                <AppLayout
                    navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
                    // breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
                    content={
                        <Content
                            // Changes the Help panel content when the user clicks an 'info' link
                            replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
                            handleClick={this.handleClick}
                            onInputChange={this.onInputChange}
                            handleAlert={this.handleAlert}
                            currentState={this.state}
                        />
                    }
                    contentType="default"
                    tools={Tools[this.state.toolsIndex]}
                    onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
                    toolsOpen={this.state.toolsOpen}
                    navigationOpen={this.state?.navigationState}
                    onNavigationChange={e => this.setState({ navigationState: e.detail.open })}
                />
                <BottomPanel label="ImageOptimization" />
            </React.Fragment>
        );
    }
};

const Content = props => {

    return (
        <React.Fragment>
            <BrowserCheck />
            <SpaceBetween direction='vertical' size='l'>
                <SynopsisPanel label="Synopsis" architectureImg={architectureImg} videolink="https://broadcast.amazon.com/videos/678806">
                    In this demo, you will learn how image can be optimized and served on the fly using EdgeServices.
                </SynopsisPanel>

                <Container disableHeaderPaddings>
                    <form onSubmit={props.handleClick}>
                        <Form
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Alert type='error'
                                        visible={props.currentState.alert}
                                        onDismiss={() => props.handleAlert}
                                        // dismissAriaLabel={<Box variant='strong'>Close alert</Box>}
                                        dismissible
                                    // header={<Box variant='p'>Select AWS Region</Box>}
                                    >
                                        <Box variant='small'>Select an image and operation to perform</Box>
                                    </Alert>
                                    <Button variant="primary">Resize</Button>

                                </SpaceBetween>
                            }
                        >
                            <div style={{ height: 400 + 'px', overflow: 'auto', padding: 10 + 'px' }}>

                                <SpaceBetween size='l'>
                                    <SpaceBetween direction='horizontal' size='xxs'>

                                        {!props.currentState.selectedImage && <Box variant='strong'>Select a Image</Box>}
                                        {props.currentState.selectedImage && <Box variant='strong'>Selected Image : </Box>}
                                        <Box variant='code'>{props.currentState.selectedImage && props.currentState.selectedImage[0].name}</Box>

                                    </SpaceBetween>

                                    {/* <Box variant='strong'>Step1: Select an image</Box> */}
                                    <Cards trackBy={"name"} variant='full-page'
                                        onSelectionChange={props.onInputChange.bind(this, 'selectedImage')}

                                        // onSelectionChange={({ detail }) =>
                                        //     setSelectedItems(detail.selectedItems)
                                        // }
                                        selectedItems={props.currentState.selectedImage}

                                        // ariaLabels={{
                                        //     itemSelectionLabel: (e, t) => console.log(t.name),
                                        //     selectionGroupLabel: "Item selection"
                                        // }}
                                        cardDefinition={{
                                            header: item => (

                                                <SpaceBetween direction='horizontal' size='xxs'>
                                                    <Box variant='code'>Format: {item.type}, Size: {prettyBytes(item.size)}, {item.width}w x {item.height}h</Box>
                                                    <Link external href={`${item.cfDomain}${item.orgPath}${item.name}`} />
                                                </SpaceBetween>
                                            ),
                                            sections: [
                                                {
                                                    id: "description",
                                                    content: item =>
                                                        <Box>
                                                            <img className='image_class' src={`${item.cfDomain}${item.orgPath}${item.name}?op=d=350x200,f=jpg`} />
                                                            {/* <Link external href={`${item.cfDomain}${item.orgPath}${item.name}`} /> */}
                                                        </Box>

                                                },
                                            ]
                                        }}
                                        cardsPerRow={[
                                            { cards: 2 },
                                            { minWidth: 500, cards: 2 }
                                        ]}
                                        items={props.currentState.images.images}
                                        loadingText="Loading resources"
                                        selectionType="single"
                                    // empty={
                                    //     <Box textAlign="center" color="inherit">
                                    //         <b>No resources</b>
                                    //         <Box
                                    //             padding={{ bottom: "s" }}
                                    //             variant="p"
                                    //             color="inherit"
                                    //         >
                                    //             No resources to display.
                                    //         </Box>
                                    //         <Button>Create resource</Button>
                                    //     </Box>
                                    // }
                                    // header={<Box variant='p'>Step1: Select a image</Box>}
                                    />

                                </SpaceBetween>

                            </div>
                            <ColumnLayout columns={3} variant='text-grid' >

                                {/* <ColumnLayout columns={2}> */}
                                <SpaceBetween>
                                    <Box variant='strong'>Resize</Box>
                                    <Select
                                        selectedOption={props.currentState.resizeTo}
                                        onChange={props.onInputChange.bind(this, 'resizeTo')}
                                        options={[
                                            { label: "-", value: "-" },
                                            { label: "width=200,height=100", value: "d=200x100" },
                                            { label: "width=300,height=200", value: "d=300x200" },
                                            { label: "width=400,height=300", value: "d=400x300" },
                                        ]}
                                        selectedAriaLabel="Selected"
                                    />
                                </SpaceBetween>
                                <SpaceBetween>
                                    <Box variant='strong'>Watermark</Box>
                                    <Select
                                        selectedOption={props.currentState.watermark}
                                        onChange={props.onInputChange.bind(this, 'watermark')}
                                        options={[
                                            { label: "-", value: "-" },
                                            {
                                                label: "Amazon Logo", value: "w=amazon_logo.png", iconUrl: `${props.currentState.images.cfDomain}${props.currentState.images.orgPath}logos/amazon_logo.png?op=d=50x31,f=png`
                                            },
                                            {
                                                label: "AWS Logo", value: "w=aws_logo.png", iconUrl: `${props.currentState.images.cfDomain}${props.currentState.images.orgPath}/logos/aws_logo.png?op=d=50x31,f=png`
                                            },
                                        ]}
                                        selectedAriaLabel="Selected"
                                    />
                                </SpaceBetween>
                                <SpaceBetween>
                                    <Box variant='strong'>Format</Box>
                                    <Select
                                        selectedOption={props.currentState.format}
                                        onChange={props.onInputChange.bind(this, 'format')}
                                        options={[
                                            { label: "Auto Detect", value: "f=auto" },
                                            { label: "jpeg", value: "f=jpeg" },
                                            { label: "png", value: "f=png" },
                                            { label: "avif", value: "f=avif" },
                                            { label: "webp", value: "f=webp" },
                                        ]}
                                    />
                                </SpaceBetween>
                                {/* </ColumnLayout> */}
                            </ColumnLayout>
                        </Form>
                    </form>
                </Container>
                <ColumnLayout columns={2} variant='text-grid'>
                    <SpaceBetween direction='vertical' size='xxs'>
                        <Box variant='strong'>Modified Image</Box>
                        {props.currentState.resizedUrl && <img id="modifiedImage" src={props.currentState.resizedUrl} onLoad={props.onInputChange.bind(this, 'image')} />}
                    </SpaceBetween>

                    <Table
                        columnDefinitions={[
                            {
                                id: "source",
                                header: <Box variant='small'>Source</Box>,
                                cell: item => item.source || "-",
                            },
                            {
                                id: "size",
                                header: <Box variant='small'>Size</Box>,
                                cell: item => item.size || "-",
                            },
                            {
                                id: "format",
                                header: <Box variant='small'>Format</Box>,
                                cell: item => item.format || "-",
                            },
                            {
                                id: "dim",
                                header: <Box variant='small'>(w x h)</Box>,
                                cell: item => <Box variant='code'>{item.dim}</Box> || "-",
                            },
                        ]}
                        items={[
                            {
                                source: props.currentState.selectedImage && <Link external href={`${props.currentState.selectedImage[0].cfDomain}${props.currentState.selectedImage[0].orgPath}${props.currentState.selectedImage[0].name}`}>Original Image</Link>,
                                size: <Box variant='code'>{props.currentState.selectedImage && prettyBytes(props.currentState.selectedImage[0].size)}</Box>,
                                format: <Box variant='code'>{props.currentState.selectedImage && props.currentState.selectedImage[0].type}</Box>,
                                dim: props.currentState.selectedImage && `${props.currentState.selectedImage[0].width} x ${props.currentState.selectedImage[0].height}`,
                                // height: props.currentState.selectedImage && props.currentState.selectedImage[0].height,
                            },
                            {
                                source: props.currentState.resizedImageAttrs && <Link external href={`${props.currentState.resizedUrl}`}>Modified Image</Link>,
                                size: <Box variant='code'>{props.currentState.resizedImageAttrs && prettyBytes(props.currentState.resizedImageAttrs.size)}</Box>,
                                format: <Box variant='code'>{props.currentState.resizedImageAttrs?.type}</Box>,
                                dim: props.currentState.resizedImageDimensions && `${props.currentState.resizedImageDimensions.width} x ${props.currentState.resizedImageDimensions.height}`,
                                // height: props.currentState.resizedImageDimesions && props.currentState.resizedImageDimesions.height,
                            },
                        ]}
                        loadingText="Loading resources"
                        sortingDisabled
                        variant="embedded"
                        header={<Box variant='strong'>Comparison</Box>}
                    />
                </ColumnLayout>
                <ExtendingPanel label="Extend it">
                    <ColumnLayout columns={2} variant='text-grid' className='extend_it'>
                        <Box> Git repo</Box>
                        <Link href="https://github.com/aws-samples/image-optimization/" external>https://github.com/aws-samples/image-optimization/</Link>
                        <Box>Questions/ideas on the Image Optimization solution?</Box>
                        <SpaceBetween direction='vertical'>
                            <Box variant='strong'>
                                Achraf Souk,
                                achrsouk@amazon.fr
                            </Box>
                            <Box variant='strong'>
                                Jaiganesh Girinathan,
                                ganeshji@amazon.com
                            </Box>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExtendingPanel>
            </SpaceBetween>
        </React.Fragment >
    );
};

// Breadcrumb content
const BreadcrumbsItems =
    [
        {
            text: 'Home',
            href: '/'
        },
        {
            text: 'Image Optimization',
            href: '/image'
        }
    ];

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
    <HelpPanel
        header={<h3>Image Optimization</h3>}
        footer={
            <div>
                <h3>
                    Learn more <Icon name="external" />
                </h3>
                {/* <ul>
          <li>
            <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/private-content-signed-urls.html" target="_blank">
              Using SignedURLs in Amazon CloudFront?
            </a>
          </li>
        </ul> */}
            </div>
        }
    >
        <p>
            Image Optimization
        </p>
    </HelpPanel>,
];