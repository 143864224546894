import React from "react";
import Hls from "hls.js";
import { CMCD_STREAM_ENDPOINT } from "../../resources/prod-env";
import {
    Container,
    Grid,
    SpaceBetween,
    ColumnLayout,
    Box,
    Button,
    StatusIndicator,
    Link,
    Popover,
    Header,
} from "@cloudscape-design/components";
export default class HLSPlayer extends React.Component {
    constructor(props) {
        super(props);
        // console.log("cmcdObj", props.cmcdObj);

        this.state = {}
        this.parseCMCD = this.parseCMCD.bind(this);
        this.cmcdObj = props.cmcdObj;
        this.replaceToolsContent = props.replaceToolsContent;
        // this.entries = [];
    }

    componentDidMount() {
        const video = this.player;
        let hlsConfig = {
            maxBufferLength: 30,
            maxMaxBufferLength: 30,
            cmcd: true,
            contentId: 'ocean-demo-video'
        }
        const hls = new Hls(hlsConfig);
        const url = CMCD_STREAM_ENDPOINT;

        hls.loadSource(url);
        hls.attachMedia(video);

        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
            // console.log("manifest loaded, found " + data.levels.length + " quality level");
            // console.log(data);
            this.entries = [];
        });

        // hls.on(Hls.Events.FRAG_PARSED, this.printCMCD);
        const observe_all = new PerformanceObserver((list, obs) => {
            const perfEntries = list.getEntries();
            perfEntries
                .filter(res => (res.initiatorType == 'xmlhttprequest'))
                .map((entry) => this.cmcdObj.handleCMCD(this.parseCMCD(entry)));

            // this.cmcdObj(this.entries);
        });
        observe_all.observe({ entryTypes: ["resource"] });

        // let observer = new PerformanceObserver(this.parseCMCD);
    }

    parseCMCD(entry) {
        // if (entry.initiatorType != "") return;

        // console.log("PrintCMCD", observer);
        // console.log("PrintCMCD %j", entry.name);
        let cmcd = {};

        cmcd.attr = [];
        let urlParts = entry.name.split("?");
        let qs = decodeURIComponent(urlParts[1]);
        let url = urlParts[0];
        // console.log(url);
        if (url.indexOf("m3u8") != -1)
            cmcd.type = "manifest";
        else
            cmcd.type = "media";

        // cmcd.params = Buffer.from(data.frag.baseurl, "base64").toString();
        // console.log("CMCD :%j", cmcd);
        const queryParams = new URLSearchParams(qs);
        // console.log(queryParams.get("CMCD"));
        let values = queryParams.get("CMCD");
        values.split(",").map(key => cmcd.attr.push(key));
        return cmcd;
    };

    render() {
        return (
            <Container disableContentPaddings disableHeaderPaddings footer={
                <ColumnLayout columns={2} variant='text-grid'>
                    <Header variant="h5" info={
                        <Link variant="info" onFollow={() => this.replaceToolsContent(2)}>
                            Info
                        </Link>
                    }>Dashboard</Header>
                    {/* <Box variant='awsui-key-label'> </Box> */}

                    <SpaceBetween direction='horizontal'>
                        <Link external href="https://g-102d030035.grafana-workspace.us-east-1.amazonaws.com/d/LWk3kZ2nk/qoe?orgId=1">Quality of Experience</Link>
                        <Link external href="https://g-102d030035.grafana-workspace.us-east-1.amazonaws.com/d/DqWL_ko7k/troubleshooting?orgId=1&refresh=5s">Troubleshooting</Link>
                    </SpaceBetween>
                    <Box variant='awsui-key-label'> User name</Box>
                    <Box><code>demoportaluser</code> </Box>
                    <Box variant='awsui-key-label'> Password</Box>
                    <Popover
                        dismissButton={false}
                        position="top"
                        size="small"
                        triggerType="custom"
                        content={
                            <StatusIndicator type="success">
                                Password copied to clipboard
                            </StatusIndicator>
                        }
                    >
                        <Button iconName="copy" variant='normal'
                            onClick={() => navigator.clipboard.writeText('7T&Ar0zBi1@A')}>Copy</Button>
                    </Popover>
                    {/* <Box></Box> */}
                </ColumnLayout>
            }>
                <video
                    className="videoCanvas"
                    ref={player => (this.player = player)
                    }
                    autoPlay={true} controls={true} width="100%"></video>
            </Container>
        )
    }
}