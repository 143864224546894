import React from 'react';
import DataProvider from '../../resources/data-provider';
import ServiceNavigation from '../ServiceNavigation.jsx';
import TopPanel from '../TopPanel';
import BottomPanel from '../BottomPanel';

import {
    FEEDBACK_ENDPOINT,
} from '../../resources/prod-env.jsx';
import {
    AppLayout,
    Button,
    Form,
    FormField,
    Select,
    Grid,
    SpaceBetween,
    Container,
    Box,
    Alert,
    RadioGroup,
    Textarea,
} from '@cloudscape-design/components';

import '../../styles/form.scss';
import BrowserCheck from '../BrowserCheck';
function cwr(operation, payload) { };
export default class Acceleration extends React.Component {

    constructor(props) {
        super(props);
        // console.log(performance.now());
        // console.log("Global state ", this.context);

        this.state = {
            toolsIndex: 0, toolsOpen: false, alert: false, description: '',
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleAlert = this.handleAlert.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.dataProvider = new DataProvider();
    }

    componentDidCatch(error, info) {
        console.log(error);
        cwr('recordError', error);
    };

    componentDidMount() {
    }

    onInputChange(name, e) {
        e.preventDefault();
        // console.log("In onInputChange %s %j", name, e);
        if (name === "description" || name === "satisfied") {
            this.setState({ [name]: e.detail.value });
        }
        else if (name === "type") {
            this.setState({ [name]: e.detail.selectedOption });
        }
    }

    handleClick(e) {
        // console.log("In handleClick");
        e.preventDefault();

        if (!this.state.type || !this.state.description || !this.state.satisfied) {
            // console.log("Region not selected");
            this.setState({ alert: true });
            return;
        }
        else {
            this.handleAlert();
        }

        let payload = {};
        const userId = localStorage.getItem("userId");
        // console.log("user :%j", userId);

        if (userId) {
            payload.userId = userId;
        }
        payload.type = this.state.type.value;
        payload.description = this.state.description;
        payload.satisfied = this.state.satisfied;
        let url = `${FEEDBACK_ENDPOINT}/feedback/post`;
        let payloadStr = JSON.stringify(payload);
        console.log(payloadStr);
        this.dataProvider.postCFData(payloadStr, url, response => {
            // console.log("Response :%j", response);
            this.setState({ statusMsg: "Thank You for your feedback!" });
            this.resetValues();
        }, this.dataProvider);
    }

    handleAlert() {
        // console.log("In handleAlert");
        this.setState({ alert: false });
    }

    resetValues() {
        this.setState({ type: '', description: '', satisfied: '' });
    }

    render() {
        return (
            <React.Fragment>
                <TopPanel label="Feedback" />
                <AppLayout
                    navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
                    // breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
                    content={
                        <Content
                            // Changes the Help panel content when the user clicks an 'info' link
                            replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
                            handleClick={this.handleClick}
                            handleAlert={this.handleAlert}
                            onInputChange={this.onInputChange}
                            currentState={this.state}
                            update={this.update}
                        />
                    }
                    contentType="default"
                    // tools={Tools[this.state.toolsIndex]}
                    // onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
                    // toolsOpen={this.state.toolsOpen}
                    navigationOpen={this.state?.navigationState}
                    onNavigationChange={e => this.setState({ navigationState: e.detail.open })}
                />
                <BottomPanel label="Feedback" />
            </React.Fragment>
        );
    }
}

const Content = props => {

    return (
        <React.Fragment>
            <BrowserCheck />
            <SpaceBetween direction='vertical' size='l'>
                <Container>
                    <form onSubmit={props.handleClick}>
                        <Form
                            actions={
                                <SpaceBetween direction="horizontal" size="l">
                                    <Alert type='error'
                                        visible={props.currentState.alert}
                                        onDismiss={() => props.handleAlert()}
                                        // dismissAriaLabel={<Box variant='strong'>Close alert</Box>}
                                        dismissible
                                    // header={<Box variant='p'>Select AWS Region</Box>}
                                    >
                                        <Box variant='small'>All fields are mandatory.</Box>
                                    </Alert>
                                    <Button variant="primary">Submit</Button>
                                </SpaceBetween>
                            }
                        >
                            <Grid
                                gridDefinition={[
                                    { colspan: { default: 12 } },
                                    { colspan: { default: 3 } },
                                    { colspan: { default: 12 } },
                                    { colspan: { default: 4 } },
                                    { colspan: { default: 12 } },
                                ]}>
                                <Box variant='strong'>Thank you for taking the time to provide feedback.</Box>
                                <FormField label="Type">
                                    <Select
                                        selectedOption={props.currentState.type}
                                        onChange={props.onInputChange.bind(this, 'type')}
                                        options={[
                                            {
                                                label: "General Feedback",
                                                value: "general",
                                                description1: "Enter your message below."
                                            },
                                            {
                                                label: "New Demo Idea",
                                                value: "newdemo",
                                                description1: "Enter your idea."
                                            },
                                            {
                                                label: "Report an issue",
                                                value: "issue",
                                                description1: "Enter your message below. Provide as much detail as you can including the Demo name to help us understand your issue."
                                            },

                                        ]}
                                        filteringType="auto"
                                    />
                                </FormField>
                                <SpaceBetween size='xxs'>
                                    <Box variant='small'>{props.currentState.type?.description1}</Box>
                                    <Textarea
                                        onChange={props.onInputChange.bind(this, 'description')}
                                        value={props.currentState.description}
                                    />
                                </SpaceBetween>
                                <FormField label="Are you satisfied with the experience?">
                                    <RadioGroup
                                        onChange={props.onInputChange.bind(this, 'satisfied')}
                                        value={props.currentState.satisfied}
                                        items={[
                                            { value: "yes", label: "Yes" },
                                            { value: "no", label: "No" },
                                        ]}
                                    />
                                </FormField>
                                <Box variant='strong' float='right' textAlign='center' color='text-status-success'>{props.currentState.statusMsg}</Box>
                            </Grid>
                        </Form>
                    </form>
                </Container>
            </SpaceBetween>
        </React.Fragment >
    );
}