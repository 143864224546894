// CloudFront table tooltip content
import {
  ExpandableSection, SpaceBetween, Box,
  Header,
  Link,
  ColumnLayout,
} from '@cloudscape-design/components';
import Architecture from './common/architecture';
// CloudFront table tooltip content
import { Video } from 'react-feather';
import React from 'react';
const SynopsisPanelV3 = ({ label = "Synopsis", children, expanded, variant, architectureImgs, videolink,demotype }) => {

  let architectureElements = architectureImgs.map(function(img,index) {
    return <Architecture key={index} architecture={img} />;
  });

  return (
    <React.Fragment>
      {videolink && <ExpandableSection variant={variant} 
        headerText={
        <SpaceBetween direction='horizontal' size='xxl'>
          <Box variant='awsui-key-label'>{label}</Box>
          <Link external href={videolink}>
            <Video size={15} /> How to use this {demotype}?
          </Link>
        </SpaceBetween>}
        expanded={expanded}>
        <ColumnLayout>
          <Box> {children}</Box>
          {architectureElements}
        </ColumnLayout>
      </ExpandableSection >}
      {!videolink && <ExpandableSection variant={variant}
        headerText={<Box variant='awsui-key-label'>{label}</Box>} expanded={expanded}>
        <SpaceBetween direction='vertical' size='l'>
          <Box> {children}</Box>
          {architectureElements}
        </SpaceBetween>
      </ExpandableSection >}
    </React.Fragment>

    // <ExpandableSection variant={variant} header={label} expanded={expanded} >
    //   <SpaceBetween direction='vertical' size='l'>
    //     <Box> {children}</Box>
    //     {architectureImg && <Architecture architecture={architectureImg} />}
    //   </SpaceBetween>
    // </ExpandableSection>
  );
};

export default SynopsisPanelV3;
