import React from 'react';
import {
    TopNavigation,
    Box,
    Link,
} from '@cloudscape-design/components';
import '../styles/form.scss';

export default props => {

    return (
        <TopNavigation
            className='bottompanel'
            identity={{
                href: `/feedback?module=${props.label}`,
                title: <React.Fragment>
                    <Box variant='small'>Feedback</Box>
                </React.Fragment>,
            }}
            // utilities={[
            //     {
            //         type: "menu-dropdown",
            //         iconName: "notification",
            //         title: "Notifications",
            //         ariaLabel: "Notifications (unread)",
            //         badge: true,
            //         // disableUtilityCollapse: false,
            //         items: [
            //             {
            //                 id: "settings-org",
            //                 text: "Organizational settings"
            //             },
            //             {
            //                 id: "settings-project",
            //                 text: "Project settings"
            //             }
            //         ]
            //     },
            // ]}
            i18nStrings={{
                searchIconAriaLabel: "Search",
                searchDismissIconAriaLabel: "Close search",
                overflowMenuTriggerText: "More",
                overflowMenuTitleText: "All",
                overflowMenuBackIconAriaLabel: "Back",
                overflowMenuDismissIconAriaLabel: "Close menu"
            }}
        />
    );
}
