import React from 'react';
import DataProvider from '../../resources/data-provider';
import ServiceNavigation from '../ServiceNavigation.jsx';
import ExtendingPanel from '../ExtendingPanel.jsx';
import BrowserCheck from '../BrowserCheck';
import TopPanel from '../TopPanel';

import {
    WAFCOMMON_ENDPOINT,
} from '../../resources/prod-env.jsx';
import {
    TabLabel,
} from '../common';
import {
    AppLayout,
    Button,
    ColumnLayout,
    Form,
    FormField,
    Select,
    SpaceBetween,
    Container,
    Link,
    HelpPanel,
    Box,
    Icon,
    Alert,
    Toggle,
    Checkbox,
    Tabs,
} from '@cloudscape-design/components';

import '../../styles/form.scss';
// function cwr(operation, payload) { };
import { sendAnalytics, sendError } from '../../resources/rum-provider.ts';
const demoModule = "WAFSQLi";

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class WAFCommonFeatures extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleAlert = this.handleAlert.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.dataProvider = new DataProvider();
        this.state = {
            toolsIndex: 0, toolsOpen: false, alert: false,
            attackType: "", applyWAF: { checked: false },
            requestType: { checked: false },
            injectionType: "sqli", sqlisignature: "", xsssignature: "",
        };
    }

    onInputChange(name, e) {
        e.preventDefault();
        console.log("In onInputChange %s %j", name, e);
        if (name === "applyOn" || name === "sqlisignature" || name === "xsssignature"
            || name === "recordId") {
            this.setState({ [name]: e.detail.selectedOption });
            return;
        }
        else if (name === "attackType") {
            this.setState({ [name]: e.detail.value, signatures: this.signatures[e.detail.value] });
        }
        else if (name === 'applyWAF' || name === "requestType") {
            this.setState({ [name]: e.detail });
        }
        else if (name === 'injectionType') {
            this.setState({ [name]: e.detail.activeTabId });
        }
    }

    handleClick(e) {
        e.preventDefault();
        // if (this.state.injectionType==|| (this.state.attackType != 'none' && !this.state.signature)) {
        //     // console.log("Region not selected");
        //     this.setState({ alert: true });
        //     return;
        // }
        // else {
        //     this.handleAlert();
        // }
        sendAnalytics({ demo: demoModule });

        let payload = {};
        let url = "";
        console.log("Apply WAF:%j", this.state.applyWAF);

        if (this.state.injectionType === "sqli") {
            if (!this.state.recordId) {
                // console.log("Region not selected");
                this.setState({ alert: true });
                return;
            }
            else {
                this.handleAlert();
            }

            payload.action = "fetch";
            payload.recordId = this.state.recordId.value;
            url = `${WAFCOMMON_ENDPOINT}/sqli`;
            if (this.state.sqlisignature.value) {
                payload.recordId = this.state.sqlisignature.value;
            }
        }
        else if (this.state.injectionType === "xss") {
            if (!this.state.xsssignature) {
                // console.log("Region not selected");
                this.setState({ alert: true });
                return;
            }
            else {
                this.handleAlert();
            }
            payload.action = "xss";
            payload.recordId = this.state.xsssignature.value;
            url = `${WAFCOMMON_ENDPOINT}/xss`;

            if (this.state.xsssignature.value) {
                payload.recordId = this.state.xsssignature.value;
            }
        }


        // let payloadStr = JSON.stringify(payload);
        // console.log(payload);
        if (this.state.requestType.checked) {
            //xss request or POST method, so POST it
            let requestHeaders = {};
            if (this.state.applyWAF?.checked) {
                requestHeaders.applywaf = "true";
            }
            this.dataProvider.postWAFCommonData(payload, requestHeaders, url, response => {
                this.setState({ result: response });
                console.log("Response :%j", response);
            }, this.dataProvider);
        }
        else {
            let requestHeaders = {};
            if (this.state.applyWAF?.checked) {
                requestHeaders.applywaf = "true";
            }
            this.dataProvider.getWAFCommonData(payload, requestHeaders, url, response => {
                this.setState({ result: response });
                console.log("Response :%j", response);
            }, this.dataProvider);
        }
    }

    handleAlert() {
        // console.log("In handleAlert");
        this.setState({ alert: false });
    }
    componentDidCatch(error, info) {
        console.log(error);
        // cwr('recordError', error);
        sendError({ demo: demoModule, error: error });
    };

    componentDidMount() {
        // this.dataProvider.getData('images', response => this.setState({ images: response }));
    }

    render() {
        return (
            <React.Fragment>
                <TopPanel label={demoModule} />
                <AppLayout
                    navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
                    // breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
                    content={
                        <Content
                            // Changes the Help panel content when the user clicks an 'info' link
                            replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
                            handleClick={this.handleClick}
                            handleAlert={this.handleAlert}
                            onInputChange={this.onInputChange}
                            currentState={this.state}
                        />
                    }
                    contentType="default"
                    tools={Tools[this.state.toolsIndex]}
                    onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
                    toolsOpen={this.state.toolsOpen}
                    navigationOpen={this.state?.navigationState}
                    onNavigationChange={e => this.setState({ navigationState: e.detail.open })}
                />
            </React.Fragment>
        );
    }
};

const ResultPanel = props => {
    if (props.currentState?.result) {
        return (
            <ColumnLayout columns="2" variant='text-grid'>
                <SpaceBetween direction='vertical' size='l'>
                    <ValueWithLabel label="URL">
                        {props.currentState.result.url}
                    </ValueWithLabel>
                    <ValueWithLabel label="Request Body">
                        <Box variant='pre'>{JSON.stringify(props.currentState.result.requestBody, null, 2)}</Box>
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween direction='vertical' size='l'>
                    <ValueWithLabel label="Response Headers">
                        {JSON.stringify(props.currentState.result.responseHeaders, null, 2)}
                    </ValueWithLabel>
                    <ValueWithLabel label="Response">
                        <Box variant='pre'>{JSON.stringify(props.currentState.result.response, null, 2)}</Box>
                    </ValueWithLabel>
                </SpaceBetween>
            </ColumnLayout>
        )
    } else {
        return ("");
    }
};

const ValueWithLabel = ({ label, children }) => (
    <React.Fragment>
        <Box variant="awsui-key-label">{label}</Box>
        <Box>{children !== "NaN" && children}</Box>
    </React.Fragment>
);
const Content = props => {

    return (
        <React.Fragment>
            <BrowserCheck />
            <SpaceBetween direction='vertical' size='l'>
                <ExtendingPanel label="Synopsis">
                    In this demo, you can show how WAF SQLi/XSS feature works on various
                    request attributes.
                </ExtendingPanel>
                <Container>
                    <form onSubmit={props.handleClick}>
                        <Form
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    {props.currentState.alert && <Alert type='error'
                                        // visible={props.currentState.alert}
                                        onDismiss={() => props.handleAlert()}
                                        // dismissAriaLabel={<Box variant='strong'>Close alert</Box>}
                                        dismissible
                                    // header={<Box variant='p'>Select AWS Region</Box>}
                                    >
                                        <Box variant='small'>Select 'Record ID' fields and hit 'Fetch'</Box>
                                    </Alert>}
                                    <Button variant="primary">Fetch</Button>
                                </SpaceBetween>
                            }
                        >
                            <Tabs
                                onChange={props.onInputChange.bind(this, 'injectionType')}
                                activeTabId={props.currentState.injectionType}
                                tabs={[
                                    {
                                        label: <TabLabel label="SQL Injection" />,
                                        id: "sqli",
                                        content: <SQLiPanel currentState={props.currentState} onInputChange={props.onInputChange} replaceToolsContent={props.replaceToolsContent} />
                                    },
                                    {
                                        label: <TabLabel label="Cross Site Scripting" />,
                                        id: "xss",
                                        content: <XSSPanel currentState={props.currentState} onInputChange={props.onInputChange} replaceToolsContent={props.replaceToolsContent} />
                                    },
                                ]}
                            />
                        </Form>
                    </form>
                </Container>
                <Container header=<Box variant='h4'>Result</Box>>
                    <ResultPanel currentState={props.currentState} />
                </Container>
                <ExtendingPanel label="Extend it">
                    <ColumnLayout columns={2} variant='text-grid' className='extend_it'>
                        <Box>Questions/ideas on the demo?</Box>
                        <SpaceBetween direction='vertical'>
                            <Box variant='strong'>
                                Jaiganesh Girinathan,
                                ganeshji@amazon.com
                            </Box>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExtendingPanel>
            </SpaceBetween>
        </React.Fragment >
    );
};

const SQLiPanel = props => {
    return (
        <SpaceBetween direction='horizontal' size='l'>
            <FormField label="Record ID" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(3)}>
                    Info
                </Link>
            }>
                <Select
                    selectedOption={props.currentState?.recordId}
                    onChange={props.onInputChange.bind(this, 'recordId')}
                    options={[
                        { label: "1", value: "1" },
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "5", value: "5" },
                    ]}
                    selectedAriaLabel="Selected"
                />
            </FormField>
            <FormField label="Signature" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(4)}>
                    Info
                </Link>
            }>
                <Select
                    selectedOption={props.currentState?.sqlisignature}
                    onChange={props.onInputChange.bind(this, 'sqlisignature')}
                    options={[
                        { label: "None", value: "" },
                        { label: "10 OR 1=1", value: "10 OR 1=1" },
                        { label: "11 OR 1=1", value: "11 OR 1=1" },
                    ]}
                    selectedAriaLabel="Selected"
                />
            </FormField>
            <FormField label="Apply WAF" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(2)}>
                    Info
                </Link>
            }>
                <Checkbox
                    onChange={props.onInputChange.bind(this, 'applyWAF')}
                    checked={props.currentState?.applyWAF?.checked}
                />
            </FormField>
            <FormField label="Request Type" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(5)}>
                    Info
                </Link>
            }>
                <Toggle
                    onChange={props.onInputChange.bind(this, 'requestType')}
                    checked={props.currentState?.requestType?.checked}>
                    POST
                </Toggle>
            </FormField>
        </SpaceBetween>
    );
};

const XSSPanel = props => {
    return (
        <SpaceBetween direction='horizontal' size='l'>
            <FormField label="Signature" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(4)}>
                    Info
                </Link>
            }>
                <Select
                    selectedOption={props.currentState?.xsssignature}
                    onChange={props.onInputChange.bind(this, 'xsssignature')}
                    options={[
                        { label: "Some comment", value: "Some comment" },
                        { label: "<script>alert('Hello')</script>", value: "<script>alert('Hello')</script>" },
                        { label: "<script src='//www.somedomain.com/scripts.js></script>", value: "<script src='//www.somedomain.com/scripts.js></script>" },
                    ]}
                    selectedAriaLabel="Selected"
                />
            </FormField>
            <FormField label="Apply WAF" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(2)}>
                    Info
                </Link>
            }>
                <Checkbox
                    onChange={props.onInputChange.bind(this, 'applyWAF')}
                    checked={props.currentState?.applyWAF?.checked}
                />
            </FormField>
            <FormField label="Request Type" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(5)}>
                    Info
                </Link>
            }>
                <Toggle
                    onChange={props.onInputChange.bind(this, 'requestType')}
                    checked={props.currentState?.requestType?.checked}>
                    POST
                </Toggle>
            </FormField>
        </SpaceBetween>
    );
};

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
    <HelpPanel
        header={<h3>SQLi/XSS</h3>}
        footer={
            <div>
                <h3>
                    Learn more <Icon name="external" />
                </h3>
                {/* <ul>
          <li>
            <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/private-content-signed-urls.html" target="_blank">
              Using SignedURLs in Amazon CloudFront?
            </a>
          </li>
        </ul> */}
            </div>
        }
        index="0">
        <p>
            MODULE NAME
        </p>
    </HelpPanel >,
    <HelpPanel header={<h2>Apply On</h2>} index="1">
        Choose the request attribute to apply the attack signature
    </HelpPanel>,
    <HelpPanel header={<h2>Apply WAF</h2>} index="2">
        Apply WAF protection on the request
    </HelpPanel>,
    <HelpPanel header={<h2>Record ID</h2>} index="3">
        ID of the record to fetch from database
    </HelpPanel>,
    <HelpPanel header={<h2>Signature</h2>} index="4">
        Attack Signature
    </HelpPanel>,
    <HelpPanel header={<h2>Request Type</h2>} index="5">
        Whether to use GET (default) or POST request method.
    </HelpPanel>,
];