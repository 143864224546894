// Breadcrumb content
import {
  BreadcrumbGroup,
} from '@cloudscape-design/components';
const Breadcrumbs = (props) => (
  <BreadcrumbGroup
    items={props.items}
  />
);
export default Breadcrumbs;
