import * as React from "react";
import Flashbar from "@cloudscape-design/components/flashbar";
const { detect } = require('detect-browser');
const browser = detect();

const BrowserCheck = props => {

    const [items, setItems] = React.useState([
        {
            type: "warning",
            content: "This Demo works best on Google Chrome.",
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setItems([]),
            id: "message_1"
        }
    ]);

    switch (browser && browser.name) {
        case 'safari': return <Flashbar items={items} />;
        case 'firefox': return <Flashbar items={items} />;
        default: return "";
    }
};

export default BrowserCheck;