import percentile from 'stats-percentile';

const calculatePercentile = values => {
    let stats;
    if (values.length > 0) {
        let p90 = Math.round(percentile(values, 90));
        let p50 = Math.round(percentile(values, 50));
        let requestCount = values.length;
        stats = { p90: p90, p50: p50, requestCount: requestCount };
    }
    else {
        stats = { p90: 'NA', p50: 'NA', requestCount: 0 };
    }
    return stats;
}
export default calculatePercentile;