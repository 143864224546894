/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

  https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import ServiceNavigation from '../ServiceNavigation.jsx';
import ExtendingPanel from '../ExtendingPanel.jsx';
import SynopsisPanel from '../SynopsisPanel.jsx';
import TopPanel from '../TopPanel';
import BrowserCheck from '../BrowserCheck.jsx';
import {
  DEMOPORTAL_USAGE_ENDPOINT,
} from '../../resources/prod-env.jsx';
import {
  AppLayout,
  ColumnLayout,
  Grid,
  Header,
  SpaceBetween,
  Container,
  Link,
  HelpPanel,
  Box,
  Button,
  Popover,
  StatusIndicator
} from '@cloudscape-design/components';

import '../../styles/form.scss';

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class DemoPortalUsage extends React.Component {

  constructor(props) {
    super(props);
    // console.log(performance.now());
    this.state = {
      toolsIndex: 0, toolsOpen: false,
    };
  }

  handleClick(e) {
    e.preventDefault();

  }

  render() {
    return (
      <React.Fragment>
        <TopPanel label="Demo Portal Usage" />
        <AppLayout
          navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
          // breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
          content={
            <Content
              // Changes the Help panel content when the user clicks an 'info' link
              replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
            />
          }
          contentType="default"
          tools={Tools[this.state.toolsIndex]}
          onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
          toolsOpen={this.state.toolsOpen}
        />
      </React.Fragment>
    );
  }
}

// The content in the main content area of the App layout
const Content = props => {

  return (
    <React.Fragment>
      <BrowserCheck />
      <SpaceBetween direction='vertical' size='l'>
        <SynopsisPanel>
          Access to dashboard to see Demo Portal usage.
        </SynopsisPanel>
        <Grid
          gridDefinition={[
            { colspan: { default: 6, xxs: 6 } },
          ]} >
          <Container>
            <SpaceBetween direction='vertical' size='l'>
              <ColumnLayout columns={2} variant='text-grid'>
                <Header variant="h5"
                >Dashboard</Header>
                {/* <Box variant='awsui-key-label'> </Box> */}

                <SpaceBetween direction='horizontal'>
                  <Link external href={DEMOPORTAL_USAGE_ENDPOINT}> Demo Portal usage stats dashboard</Link>
                </SpaceBetween>
                <Box variant='awsui-key-label'> User name</Box>
                <Box><code>demoportaluser</code> </Box>
                <Box variant='awsui-key-label'> Password</Box>
                <Popover
                  dismissButton={false}
                  position="top"
                  size="small"
                  triggerType="custom"
                  content={
                    <StatusIndicator type="success">
                      Password copied to clipboard
                    </StatusIndicator>
                  }
                >
                  <Button iconName="copy" variant='normal'
                    onClick={() => navigator.clipboard.writeText('7T&Ar0zBi1@A')}>Copy</Button>
                </Popover>
              </ColumnLayout>
              <Box variant='small'>Once logged in,if you don't see the dashboard, using the 'Menu'
                on left-hand side, navigate to 'Dashboards -> Browse' and look for 'Demo Portal - Usage Stats'</Box>
            </SpaceBetween>
          </Container>
        </Grid>
        <ExtendingPanel label="Extend it">
          <ColumnLayout columns={2} variant='text-grid' className='extend_it'>
            <Box>
              Questions/ideas on this demo?</Box>
            <Box variant='strong'>
              Jaiganesh Girinathan,
              ganeshji@amazon.com
            </Box>
          </ColumnLayout>
        </ExtendingPanel>
      </SpaceBetween>
    </React.Fragment >
  );
};

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
  <HelpPanel
    header={<h2>Demo Portal Dashboard</h2>}
  >
  </HelpPanel>,
];
// select sc_status,count(*) from rtlogs2 where dt between '${__from:date:YYYY-MM-DD-HH}' and '${__to:date:YYYY-MM-DD-HH}' and sc_status!='200' and c_country in (${country:singlequote}) group by sc_status;