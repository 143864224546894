import React from 'react';
import DataProvider from '../../resources/data-provider';
import ServiceNavigation from '../ServiceNavigation.jsx';
import SynopsisPanel from '../SynopsisPanel';
import TopPanel from '../TopPanel';
import BottomPanel from '../BottomPanel';
import {
    INSPECT_REQUEST_ENDPOINT,
} from '../../resources/prod-env.jsx';
import {
    AppLayout,
    Button,
    Form,
    FormField,
    Select,
    Grid,
    SpaceBetween,
    Container,
    Link,
    HelpPanel,
    Icon,
    Box,
    Table,
} from '@cloudscape-design/components';

import '../../styles/form.scss';
import BrowserCheck from '../BrowserCheck';
// function cwr(operation, payload) { };
import { sendAnalytics, sendError } from '../../resources/rum-provider.ts';
const demoModule = "InspectRequest";

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class Inspect extends React.Component {

    constructor(props) {
        super(props);
        // console.log(performance.now());
        this.state = {
            toolsIndex: 0, toolsOpen: false, alert: false,
        };
        this.handleClick = this.handleClick.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.dataProvider = new DataProvider();
    }

    componentDidCatch(error, info) {
        console.log(error);
        // cwr('recordError', error);
        sendError({ demo: demoModule, error: error });
    };

    componentDidMount() {
        // this.dataProvider.getData('images', response => this.setState({ images: response }));
    }

    onInputChange(name, e) {
        e.preventDefault();
        // console.log("In onInputChange %s %j", name, e);
        this.setState({ [name]: e.detail.selectedOption });
    }

    handleClick(e) {
        e.preventDefault();
        // console.log("In handleClick %s,%s", this.state.cachePolicyOption?.value, this.state.originRequestPolicy?.value);
        let paths = [];
        paths.push(this.state.cachePolicyOption?.value);
        if (this.state.originRequestPolicy)
            paths.push(this.state.originRequestPolicy.value);

        let url = `${INSPECT_REQUEST_ENDPOINT}${paths.join("/")}`;
        if (this.state.queryString)
            url = `${url}${this.state.queryString.value}`;

        this.setState({ Endpoint: url });
        sendAnalytics({ demo: demoModule });

        // console.log("URL :%s", url);
        this.dataProvider.getInspectData({}, url, response => {
            console.log("Response :%j", response);
            let vreqh_event = response.vreqh_event?JSON.parse(response.vreqh_event):{};
            let vreqqs_event = response.vreqqs_event?JSON.parse(response.vreqqs_event):{};
            let vres_event = JSON.parse(response.vres_event);
            let oreq_event = JSON.parse(response.oreq_event);
            let ores_event = JSON.parse(response.ores_event);
            let oreqqs_event = [];

            Object.keys(response.oreqqs_event).map((key) => {
                console.log(key);
                oreqqs_event.push({ name: key, value: response.oreqqs_event[key] });
            }
            );

            console.log("Obj :%j", oreqqs_event)
            let vreqh_headers = vreqh_event;
            let vres_headers = vres_event.response.headers;

            let oreq_headers = oreq_event.Records[0].cf.request.headers;
            let ores_headers = ores_event.Records[0].cf.response.headers;

            this.setState({ vreqh_event: this.sortObj(vreqh_headers) });
            this.setState({ oreqh_event: this.sortObj(oreq_headers, true) });
            this.setState({ oresh_event: this.sortObj(ores_headers, true) });
            this.setState({ vresh_event: this.sortObj(vres_headers) });
            this.setState({ vreqqs_event: this.sortObj(vreqqs_event) });
            this.setState({ oreqqs_event: oreqqs_event });
        }, this.dataProvider);
    }

    sortObj(headers, atIndex) {
        let items = [];

        if (atIndex) {
            Object.keys(headers).map(key => {
                items[key] = headers[key][0].value;
            });
        } else {
            Object.keys(headers).map(key => {
                items[key] = headers[key].value;
            });
        }

        items = Object.keys(items).sort().reduce(function (result, key) {
            if (key !== 'vres_event' && key !== 'vreqh_event' && key !== 'ores_event' && key !== 'vreqqs_event')
                result[key] = items[key];
            return result;
        }, {});

        console.log("Sorted items :%j", items);

        let items1 = [];
        for (let name in items) {
            items1.push({ name: name, value: items[name] });
        }
        return items1;
    }

    render() {
        return (
            <React.Fragment>
                <TopPanel label="Inspect Request" />
                <AppLayout
                    navigation={< ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
                    // breadcrumbs={< Breadcrumbs items={BreadcrumbsItems} />}
                    content={
                        < Content
                            // Changes the Help panel content when the user clicks an 'info' link
                            replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
                            handleClick={this.handleClick}
                            onInputChange={this.onInputChange}
                            currentState={this.state}
                        />
                    }
                    contentType="default"
                    tools={Tools[this.state.toolsIndex]}
                    onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
                    toolsOpen={this.state.toolsOpen}
                    navigationOpen={this.state?.navigationState}
                    onNavigationChange={e => this.setState({ navigationState: e.detail.open })}
                />
                <BottomPanel label="InspectRequest" />
            </React.Fragment>
        );
    }
};

const Content = props => {

    return (
        <React.Fragment>
            <BrowserCheck />
            <SpaceBetween direction='vertical' size='l'>
                <SynopsisPanel label="Synopsis">
                    In this demo, we will inspect the request attributes:headers, query strings, cookies available in the 4 event triggers available in CloudFront.
                    Note the available request attributes varies by the Cache Policy and Origin Request Policy.
                </SynopsisPanel>
                <Container>
                    <form onSubmit={props.handleClick}>
                        <Form
                            actions={
                                <SpaceBetween direction="vertical" size="xs">
                                    <Button variant="primary">Request</Button>
                                </SpaceBetween>
                            }
                        >
                            <Grid
                                gridDefinition={[
                                    { colspan: 4 },
                                    { colspan: 4 },
                                    { colspan: 4 },
                                    { colspan: 4 }
                                ]}
                            >
                                <FormField label="Cache Policy" info={
                                    <Link variant="info" onFollow={() => props.replaceToolsContent(1)}>
                                        Info
                                    </Link>
                                }>
                                    <Select
                                        selectedOption={props.currentState.cachePolicyOption}
                                        onChange={props.onInputChange.bind(this, 'cachePolicyOption')}
                                        options={[
                                            {
                                                label: "Managed:Cache Optimized",
                                                value: "cacheoptimized",
                                                description: "This policy is designed to optimize cache efficiency by minimizing the values that CloudFront includes in the cache key."
                                            },
                                            {
                                                label: "Managed:Cache Disabled",
                                                value: "cachedisabled",
                                                description: "This policy disables caching. This policy is useful for dynamic content and for requests that are not cacheable."
                                            },
                                            {
                                                label: "Custom:Cache Optimized + Query String",
                                                value: "cacheoptimizedqs",
                                                description: "This policy is designed to enable caching with 'All' querystring included in cache policy."
                                            },
                                        ]}
                                        filteringType="auto"
                                    />
                                </FormField>
                                <FormField label="Origin Request Policy" info={
                                    <Link variant="info" onFollow={() => props.replaceToolsContent(2)}>
                                        Info
                                    </Link>
                                }>
                                    <Select
                                        selectedOption={props.currentState.originRequestPolicy}
                                        onChange={props.onInputChange.bind(this, 'originRequestPolicy')}
                                        options={[
                                            {
                                                label: "-",
                                                value: "",
                                                description: ""
                                            },
                                            {
                                                label: "UserAgentRefererHeaders",
                                                value: "useragentrefererheaders",
                                                description: "This policy includes only the User-Agent and Referer headers. It doesn’t include any query strings or cookies."
                                            },
                                            // {
                                            //     label: "CORS-CustomOrigin",
                                            //     value: "corscustomorigin",
                                            //     description: "This policy includes the header that enables cross-origin resource sharing (CORS) requests when the origin is a custom origin."
                                            // },
                                            // {
                                            //     label: "CORS-S3Origin",
                                            //     value: "corss3origin",
                                            //     description: "This policy includes the headers that enable cross-origin resource sharing (CORS) requests when the origin is an Amazon S3 bucket."
                                            // },
                                            {
                                                label: "AllViewer",
                                                value: "allviewer",
                                                description: "This policy includes all values (headers, cookies, and query strings) in the viewer request."
                                            },
                                        ]}
                                        filteringType="auto"
                                    />
                                </FormField>
                                <FormField label="Query String" info={
                                    <Link variant="info" onFollow={() => props.replaceToolsContent(3)}>
                                        Info
                                    </Link>
                                }>
                                    <Select
                                        selectedOption={props.currentState.queryString}
                                        onChange={props.onInputChange.bind(this, 'queryString')}
                                        options={[
                                            {
                                                label: "-",
                                                value: "",
                                                description: ""
                                            },
                                            {
                                                label: "param1=value1&params2=value2",
                                                value: "?param1=value1&params2=value2",
                                                description: "Two query string parameters."
                                            },
                                            {
                                                label: "param3=value3&params4=value4",
                                                value: "?param3=value3&params4=value4",
                                                description: "Two query string parameters not whitelisted."
                                            },
                                        ]}
                                        filteringType="auto"
                                    />
                                </FormField>
                                <Box variant='code'>Endpoint: {props.currentState.Endpoint}</Box>
                            </Grid>
                        </Form>
                    </form>
                </Container>
                <Container>
                    <Grid
                        gridDefinition={[
                            { colspan: 6 },
                            { colspan: 6 },
                            // { colspan: 12 },
                            { colspan: 6 },
                            { colspan: 6 },
                        ]}
                    >
                        <SpaceBetween direction='vertical' size='l'>
                            <Box variant='h5'>Viewer Request (CloudFront Functions)</Box>
                            <HeaderViewPanel label="Request Headers" event={props.currentState.vreqh_event} />
                            <QueryStringViewPanel event={props.currentState.vreqqs_event} />
                        </SpaceBetween>
                        <SpaceBetween direction='vertical' size='l'>
                            <Box variant='h5'>Origin Request (Lambda@Edge Function)</Box>
                            <HeaderViewPanel label="Request Headers" event={props.currentState.oreqh_event} />
                            <QueryStringViewPanel event={props.currentState.oreqqs_event} />
                        </SpaceBetween>
                        {/* <div className='inspect_img'><img src={CloudFrontImg} alt="Amazon CloudFront" width={100} /></div> */}
                        <SpaceBetween direction='vertical' size='l'>
                            <Box variant='h5'>Viewer Response (CloudFront Functions)</Box>
                            <HeaderViewPanel label="Response Headers" event={props.currentState.vresh_event} />
                        </SpaceBetween>
                        <SpaceBetween direction='vertical' size='l'>
                            <Box variant='h5'>Origin Response (Lambda@Edge Function)</Box>
                            <HeaderViewPanel label="Response Headers" event={props.currentState.oresh_event} />
                        </SpaceBetween>
                    </Grid>
                </Container>
            </SpaceBetween>
        </React.Fragment>
    );
}

const QueryStringViewPanel = props => {
    return (
        <Table variant='embedded' header={<Box variant='strong'>Query Strings</Box>}
            columnDefinitions={[
                {
                    id: 'key',
                    header: <Box variant='small'>Key</Box>,
                    cell: item => <Box variant='code'>{item.name}</Box>,
                },
                {
                    id: 'value',
                    header: <Box variant='small'>Value</Box>,
                    cell: item => <Box variant='code'>{item.value}</Box>,
                },
            ]}
            items={props.event}
            wrapLines
            resizableColumns
        />
    )
}

const HeaderViewPanel = props => {

    return (
        <Table variant='embedded' header={<Box variant='strong'>{props.label}</Box>}
            columnDefinitions={[
                {
                    id: 'key',
                    header: <Box variant='small'>Key</Box>,
                    cell: item => <Box variant='code'>{item.name}</Box>,
                },
                {
                    id: 'value',
                    header: <Box variant='small'>Value</Box>,
                    cell: item => <Box variant='code'>{item.value}</Box>,
                },
            ]}
            items={props.event}
            wrapLines
            resizableColumns
        />
    );
};

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
    <HelpPanel
        header={<h3>Inspect Request</h3>}
        footer={
            <div>
                <h3>
                    Learn more <Icon name="external" />
                </h3>
                {/* <ul>
          <li>
            <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/private-content-signed-urls.html" target="_blank">
              Using SignedURLs in Amazon CloudFront?
            </a>
          </li>
        </ul> */}
            </div>
        }
    >
        <p>
            Inspect Request and Response as it flows through the 4 different event triggers.
        </p>
    </HelpPanel>,
    <HelpPanel header={<h3>Cache Policy</h3>} loadingText="index2"
        footer={
            <div>
                <h3>
                    Learn more <Icon name="external" />
                </h3>
                <ul>
                    <li>
                        <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/working-with-policies.html" target="_blank">
                            Working with policies
                        </a>
                    </li>
                </ul>
            </div>
        }
    >
        <p>
            With a CloudFront cache policy, you can specify the HTTP headers, cookies, and query strings that CloudFront includes in the cache key. The cache key determines whether a viewer’s HTTP request results in a cache hit (the object is served to the viewer from the CloudFront cache). Including fewer values in the cache key increases the likelihood of a cache hit.

            You can also use the cache policy to specify time to live (TTL) settings for objects in the CloudFront cache, and enable CloudFront to request and cache compressed objects.
        </p>
    </HelpPanel>,
    <HelpPanel header={<h3>Origin Request Policy</h3>} loadingText="index2"
        footer={
            <div>
                <h3>
                    Learn more <Icon name="external" />
                </h3>
                <ul>
                    <li>
                        <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/working-with-policies.html" target="_blank">
                            Working with policies
                        </a>
                    </li>
                </ul>
            </div>
        }
    >
        <p>
            With a CloudFront origin request policy, you can specify the HTTP headers, cookies, and query strings that CloudFront includes in origin requests. These are the requests that CloudFront sends to the origin when there’s a cache miss.

            All of the values in the cache policy are automatically included in origin requests, but with an origin request policy you can include additional values in origin requests without including them in the cache key.
        </p>
    </HelpPanel>,
    <HelpPanel header={<h3>Query String</h3>} loadingText="index2">
        <p>
            Include query string in the requested URL.</p>
    </HelpPanel>,

];