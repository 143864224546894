import { AwsRum, AwsRumConfig } from 'aws-rum-web';
// import useAuth from '../components/useAuth.js';
let awsRum: AwsRum;

try {
    const config: AwsRumConfig = {
        sessionSampleRate: 1,
        guestRoleArn: "arn:aws:iam::646874766338:role/RUM-Monitor-us-east-1-646874766338-0423350713661-Unauth",
        identityPoolId: "us-east-1:159e9016-e0da-4b2a-bdba-48e6b6a69a9e",
        endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
        telemetries: ["performance", "errors", "http"],
        allowCookies: true,
        enableXRay: false,
        sessionEventLimit: 0,
        disableAutoPageView: true,
    };

    const APPLICATION_ID: string = '299e39f6-57e3-4816-8287-b116ff35c042';
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = 'us-east-1';

    awsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
    );
    // console.log("Initializing RUM");
} catch (error) {
    console.log("Error in CW RUM", error);
}

export const sendAnalytics = (payload:any) => {
    payload.userId = localStorage.getItem("userId");
    payload.profile = localStorage.getItem("userProfile");
    // console.log("In sendAnalytics :%j", payload);
    awsRum.recordEvent('usage_metrics', payload);
}

export const sendPageView = (location:string) => {
    let payload = { 
        userId: localStorage.getItem("userId") ,
        profile:localStorage.getItem("userProfile"),
        pageView:location
    };
    // payload["pageView"] = location;
    // console.log("In sendPageView :%j", payload);
    awsRum.recordEvent('pv_metrics', payload);
}

export const sendError = (payload:any) => {
    payload.userId = localStorage.getItem("userId");
    payload.profile = localStorage.getItem("userProfile");
    // console.log("In sendError :%j", payload);
    awsRum.recordError(payload);
}