import {
    SpaceBetween, Box, Link, Icon, Header
} from '@cloudscape-design/components';
const TabLabel = props => {
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            <Box variant='h5'>{props.label}</Box>
            {props.showinfo && <Link variant="info" onFollow={() => props.replaceToolsContent(props.showinfo)}>
                Info
            </Link>
            }
        </SpaceBetween>
    )
}
export default TabLabel;