/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import ServiceNavigation from './ServiceNavigation.jsx';
import TopPanel from './TopPanel.jsx';
import BottomPanel from './BottomPanel.jsx';
import {
  AppLayout,
  Box,
  Header,
  Grid,
} from '@cloudscape-design/components';

import '../styles/servicehomepage.scss';

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default class WIP extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <TopPanel />
        <AppLayout
          disableContentPaddings={true}
          navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
          content={<Content />}
          contentType="default"
          navigationOpen={this.state?.navigationState}
          onNavigationChange={e => this.setState({ navigationState: e.detail.open })}
          toolsHide={true}
        />
        <BottomPanel label="WIP" />
      </React.Fragment >
    );
  }
}

// The content in the main content area of the App layout
const Content = () => (
  <div className='wip_class'>
    <Box variant='h3'>Work in Progress...</Box>
  </div>
);

