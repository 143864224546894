import React from 'react';
import DataProvider from '../../resources/data-provider';
import ServiceNavigation from '../ServiceNavigation.jsx';
import ExtendingPanel from '../ExtendingPanel.jsx';
import BrowserCheck from '../BrowserCheck';
import TopPanel from '../TopPanel';

import {
    WAFATP_ENDPOINT,
} from '../../resources/prod-env.jsx';
import {
    AppLayout,
    Button,
    ColumnLayout,
    Form,
    FormField,
    SpaceBetween,
    Container,
    Link,
    HelpPanel,
    Box,
    Icon,
    Alert,
    Tiles,
    Checkbox,
    Header,
    Badge,
    Tabs,
} from '@cloudscape-design/components';

import {
    TabLabel,
  } from '../common';
  
import '../../styles/form.scss';
// function cwr(operation, payload) { };
import { sendAnalytics } from '../../resources/rum-provider.ts';
const demoModule = "WAFATP";

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class WAFATP extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleAlert = this.handleAlert.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.dataProvider = new DataProvider();
        this.state = {
            toolsIndex: 0, toolsOpen: false, alert: false,
            attackType: "", applyWAF: { checked: false },applyMode:"request",
            currentCounter:0,
            requestType: { checked: false },
        };
        this.signatures = {
            compromisedcredentials: {
                "username": "WAF_TEST_CREDENTIAL",
                "password": "WAF_TEST_CREDENTIAL_PASSWORD"
            },
            validcredentials: {
                "username": "valid_username",
                "password": "valid_password"
            },
            randompassword: {
                "username": "valid_username",
                "password": "RANDOM",
            },
            randomusername: {
                "username": "RANDOM",
                "password": "valid_password"
            },
            missingcredentials: {
                empty: ""
                // "username": "null",
                // "password": "null",
            },
            volumetricIP: {
                "username": "admin",
                "password": "admin123",
            },
            statuscode: {
                "username": "some_username",
                "password": "some_password",
                "signature":"statuscode",
            },
            header: {
                "username": "some_username",
                "password": "some_password",
                "signature":"header",
            },
            body: {
                "username": "some_username",
                "password": "some_password",
                "signature":"body",
            },
            json: {
                "username": "some_username",
                "password": "some_password",
                "signature":"json",
            },
        };
    }

    onInputChange(name, e) {
        e.preventDefault();
        console.log("In onInputChange %s %j", name, e);
        if (name === "applyMode") {
            this.setState({ [name]: e.detail.activeTabId });
        }
        else if (name === "attackType") {
            this.setState({ [name]: e.detail.value, signature: this.signatures[e.detail.value] });
        }
        else if (name === 'applyWAF') {
            this.setState({ [name]: e.detail });
        }
        this.setState({currentCounter:0});
        // else if (name == 'injectionType') {
        //     this.setState({ [name]: e.detail.activeTabId });
        // }
    }

    handleClick(e) {
        e.preventDefault();

        if (!this.state.attackType) {
            this.setState({ alert: true });
            return;
        }
        else {
            this.handleAlert();
        }
        sendAnalytics({ demo: demoModule });

        let payload = JSON.parse(JSON.stringify(this.state.signature));

        if (payload.password) {
            if (payload.password === "RANDOM") {
                payload.password = Math.random().toString(36).slice(2);
            }
        }
        if (payload.username) {
            if (payload.username === "RANDOM") {
                payload.username = Math.random().toString(36).slice(2);
            }
        }

        let url = `${WAFATP_ENDPOINT}/${this.state.applyMode === "request"?"wafatprequest":"wafatpresponse"}`;

        url=url+(this.state.applyMode === "response"?payload.signature:"");
        console.log("Apply URL:%j",url);

        let requestHeaders = {};
        if (this.state.applyWAF?.checked) {
            requestHeaders.applywaf = "true";
        }
        let noOfAttempts = 1;
        let attempts = 0;
        if (this.state.attackType === "volumetricIP") {
            noOfAttempts = 40;
        }

        let intervalCFImages = setInterval(() => {
            if (attempts < noOfAttempts) {
                let requestHeaders = {};
                if (this.state.applyWAF?.checked) {
                    requestHeaders.applywaf = "true";
                }
                attempts++;

                this.dataProvider.postWAFCommonData(payload, requestHeaders, url, response => {
                    response.timestamp = new Date().toLocaleString();
                    this.setState({ result: response });
                    console.log("Response :%j", response);
                }, this.dataProvider);
                if (this.state.attackType === "volumetricIP") {
                    this.setState({ currentCounter: attempts });
                }
                else{
                    this.setState({ currentCounter: this.state.currentCounter+attempts });
                }
            }
            else {
                clearInterval(intervalCFImages);
            }
        }, 500);
    }

    handleAlert() {
        // console.log("In handleAlert");
        this.setState({ alert: false });
    }
    componentDidCatch(error, info) {
        console.log(error);
        // cwr('recordError', error);
    };

    componentDidMount() {
        // this.dataProvider.getData('images', response => this.setState({ images: response }));
    }

    render() {
        return (
            <React.Fragment>
                <TopPanel label={demoModule} />
                <AppLayout
                    navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
                    // breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
                    content={
                        <Content
                            // Changes the Help panel content when the user clicks an 'info' link
                            replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
                            handleClick={this.handleClick}
                            handleAlert={this.handleAlert}
                            onInputChange={this.onInputChange}
                            currentState={this.state}
                        />
                    }
                    contentType="default"
                    tools={Tools[this.state.toolsIndex]}
                    onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
                    toolsOpen={this.state.toolsOpen}
                    navigationOpen={this.state?.navigationState}
                    onNavigationChange={e => this.setState({ navigationState: e.detail.open })}
                />
            </React.Fragment>
        );
    }
};

const ATPRequestPanel = props => {
    return (
        <SpaceBetween direction='horizontal' size='l'>
            <FormField label="Signature" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(4)}>
                    Info
                </Link>
            }>
                <Tiles
                    onChange={props.onInputChange.bind(this, 'attackType')}
                    value={props.currentState?.attackType}
                    columns={2}
                    items={[
                        {
                            label: "Compromised credentials",
                            // description: "Ex: /api/api.json",
                            value: "compromisedcredentials"
                        },
                        {
                            label: "Valid credentials",
                            // description: "Ex: /api/*",
                            value: "validcredentials"
                        },
                        {
                            label: "Random Password",
                            // description: "Ex: /api/*",
                            value: "randompassword"
                        },
                        {
                            label: "Volumetric IP",
                            // description: "Ex: /api/*",
                            value: "volumetricIP"
                        },
                        {
                            label: "Random Username",
                            // description: "Ex: /api/*",
                            value: "randomusername"
                        },
                        {
                            label: "Missing Credentials",
                            // description: "Ex: /api/*",
                            value: "missingcredentials"
                        },
                    ]} />
            </FormField>
            <FormField label="Apply WAF" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(2)}>
                    Info
                </Link>
            }>
                <Checkbox
                    onChange={props.onInputChange.bind(this, 'applyWAF')}
                    checked={props.currentState?.applyWAF?.checked}
                />
            </FormField>

        </SpaceBetween>
    );
};

const ATPResponsePanel = props => {
    return (
        <SpaceBetween direction='horizontal' size='l'>
            <FormField label="Signature" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(4)}>
                    Info
                </Link>
            }>
                <Tiles
                    onChange={props.onInputChange.bind(this, 'attackType')}
                    value={props.currentState?.attackType}
                    columns={2}
                    items={[
                        {
                            label: "Status Code",
                            description: "Ex: Success: 200 Ok, Failure: 403",
                            value: "statuscode"
                        },
                        {
                            label: "Header",
                            description: "Ex: Success: x-success:true, Failure: x-success:true",
                            value: "header"
                        },
                        {
                            label: "Body",
                            description: "Ex: Success: login success, Failure: login failed",
                            value: "body"
                        },
                        {
                            label: "JSON",
                            description: "Ex: Success: {xstatus:true}, Failure: {xstatus:false}",
                            value: "json"
                        },
                    ]} />
            </FormField>
            <FormField label="Apply WAF" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(2)}>
                    Info
                </Link>
            }>
                <Checkbox
                    onChange={props.onInputChange.bind(this, 'applyWAF')}
                    checked={props.currentState?.applyWAF?.checked}
                />
            </FormField>

        </SpaceBetween>
    );
};

const ResultPanel = props => {
    if (props.currentState?.result) {
        return (
            <ColumnLayout columns="2" variant='text-grid'>
                <SpaceBetween direction='vertical' size='l'>
                    <ValueWithLabel label="URL">
                        {props.currentState.result.url}
                    </ValueWithLabel>
                    <ValueWithLabel label="Request Body">
                        <Box variant='pre'>{JSON.stringify(props.currentState.result.requestBody, null, 2)}</Box>
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween direction='vertical' size='l'>
                    <ValueWithLabel label="Response Headers">
                        {JSON.stringify(props.currentState.result.responseHeaders, null, 2)}
                    </ValueWithLabel>
                    <ValueWithLabel label="Response">
                        <Box variant='pre'>{JSON.stringify(props.currentState.result.response, null, 2)}</Box>
                    </ValueWithLabel>
                </SpaceBetween>
            </ColumnLayout>
        )
    } else {
        return ("");
    }
};

const ValueWithLabel = ({ label, children }) => (
    <React.Fragment>
        <Box variant="awsui-key-label">{label}</Box>
        <Box>{children !== "NaN" && children}</Box>
    </React.Fragment>
);
const Content = props => {

    return (
        <React.Fragment>
            <BrowserCheck />
            <SpaceBetween direction='vertical' size='l'>
                <ExtendingPanel label="Synopsis">
                    In this demo, you can show how WAF ATP feature works on login forms with credentials
                    that are valid and compromised. ATP Response inspection feature is included as well.
                </ExtendingPanel>
                <Container>
                    <form onSubmit={props.handleClick}>
                        <Form
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    {props.currentState.alert && <Alert type='error'
                                        // visible={props.currentState.alert}
                                        onDismiss={() => props.handleAlert()}
                                        // dismissAriaLabel={<Box variant='strong'>Close alert</Box>}
                                        dismissible
                                    // header={<Box variant='p'>Select AWS Region</Box>}
                                    >
                                        <Box variant='small'>Select 'Signature' fields and hit 'Login'</Box>
                                    </Alert>}
                                    <Button variant="primary">Login</Button>
                                </SpaceBetween>
                            }
                        >

<Tabs
onChange={props.onInputChange.bind(this, 'applyMode')}
                tabs={[
                  {
                    label: <TabLabel label="Request" />,
                    id: "request",
                    content:   <ATPRequestPanel currentState={props.currentState} onInputChange={props.onInputChange} replaceToolsContent={props.replaceToolsContent} />

                  },
                  {
                    label: <TabLabel label="Response" />,
                    id: "response",
                    content:   <ATPResponsePanel currentState={props.currentState} onInputChange={props.onInputChange} replaceToolsContent={props.replaceToolsContent} />
                  },
                ]}
              />

                        </Form>
                    </form>
                </Container>
                <Container header={
                    <Header actions={
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Badge># Attempts {props.currentState.currentCounter}</Badge>
                            <Box variant='code'>{props.currentState.result?.timestamp}</Box>
                        </SpaceBetween>
                    }>
                        <Box variant='h4'>Result</Box></Header>}>
                    <ResultPanel currentState={props.currentState} />
                </Container>
                <ExtendingPanel label="Extend it">
                    <ColumnLayout columns={2} variant='text-grid' className='extend_it'>
                        <Box>Questions/ideas on the demo?</Box>
                        <SpaceBetween direction='vertical'>
                        <Box variant='strong'>
                            Yury Yakubov,
                            yakubovy@amazon.com
                                                        </Box>
                            <Box variant='strong' >
                                Jaiganesh Girinathan,
                                ganeshji@amazon.com
                            </Box>
                           
                        </SpaceBetween>
                    </ColumnLayout>
                </ExtendingPanel>
            </SpaceBetween >
        </React.Fragment >
    );
};

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
    <HelpPanel
        header={<h3>ATP</h3>}
        footer={
            <div>
                <h3>
                    Learn more <Icon name="external" />
                </h3>
                {/* <ul>
          <li>
            <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/private-content-signed-urls.html" target="_blank">
              Using SignedURLs in Amazon CloudFront?
            </a>
          </li>
        </ul> */}
            </div>
        }
        index="0">
        <p>
            MODULE NAME
        </p>
    </HelpPanel >,
    <HelpPanel header={<h2>Apply On</h2>} index="1">
        Choose the request attribute to apply the attack signature
    </HelpPanel>,
    <HelpPanel header={<h2>Apply WAF</h2>} index="2">
        Apply WAF protection on the request
    </HelpPanel>,
    <HelpPanel header={<h2>Record ID</h2>} index="3">
        ID of the record to fetch from database
    </HelpPanel>,
    <HelpPanel header={<h2>Signature</h2>} index="4">
        Attack Signature
    </HelpPanel>,
    <HelpPanel header={<h2>Request Type</h2>} index="5">
        Whether to use GET (default) or POST request method.
    </HelpPanel>,
];