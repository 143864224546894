import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { applyMode, applyDensity, Density, Mode } from '@cloudscape-design/global-styles';
// import './cwrum.ts';

// apply a color mode
// applyMode(Mode.Dark);
applyMode(Mode.Light);

// apply a content density mode
applyDensity(Density.Compact);

// applyDensity(Density.Comfortable);

// const root = ReactDOM.createRoot(document.getElementById('app'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// After
const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<BrowserRouter>
    <App /></BrowserRouter>);

// document.addEventListener('DOMContentLoaded', () => {
//   ReactDOM.render(
//     <HashRouter>
//       <App />
//     </HashRouter>,
//     document.querySelector('#app')
//   );
// });
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
