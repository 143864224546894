import React from 'react';
import DataProvider from '../../resources/data-provider';
import ServiceNavigation from '../ServiceNavigation.jsx';
import ExtendingPanel from '../ExtendingPanel.jsx';
import BrowserCheck from '../BrowserCheck';
import TopPanel from '../TopPanel';
import { Buffer } from "buffer";

import {
    CDN_FINDER_ENDPOINT,
} from '../../resources/prod-env.jsx';
import {
    AppLayout,
    Button,
    ColumnLayout,
    Form,
    FormField,
    Table,
    Header,
    SpaceBetween,
    Container,
    Link,
    HelpPanel,
    Box,
    Alert,
    Input,
    StatusIndicator,
} from '@cloudscape-design/components';

import '../../styles/form.scss';
import SynopsisPanel from '../SynopsisPanel';
import { sendAnalytics, sendError } from '../../resources/rum-provider.ts';
const demoModule = "CDNFinder";

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class CDNFinder extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleAlert = this.handleAlert.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.dataProvider = new DataProvider();
        this.state = {
            toolsIndex: 0, toolsOpen: false, alert: false,
            status: "takes about a minute",
            statusType: "info",
            segments: ['primary', 'secondarya', 'secondaryimages', 'secondaryscripts', 'secondarycss',
                'secondaryvideos'],
        };
    }

    onInputChange(name, e) {
        e.preventDefault();
        // console.log("In onInputChange %s %j", name, e.detail.value);
        let domain = e.detail.value;
        this.setState({ [name]: domain })
    }

    handleClick(e) {
        e.preventDefault();
        if (!this.state.domain) {
            // console.log("Region not selected");
            this.setState({ alert: true });
            return;
        }
        else {
            this.handleAlert();
        }

        sendAnalytics({ demo: demoModule });
        let domain = this.state.domain;
        if (!domain.startsWith("https://")) {
            domain = `https://${domain}`;
        }

        this.setState({
            status: "in progress, may take upto a minute..",
            statusType: "loading"
        });

        let promises = [];
        this.state.segments.forEach(segment => {
            this.state[[`${segment}`]] = [];
            promises.push(new Promise((resolve, reject) => this.fetchData(segment, domain, resolve)));
        });

        Promise.all(promises).then((values) => {
            console.log(values);
            this.setState({
                status: "completed",
                statusType: "info"
            });
        });
    }

    fetchData(segment, domain, resolve) {

        let payload = JSON.stringify({ domain: domain, segment: segment });
        payload = Buffer.from(payload).toString('base64');

        this.dataProvider.getCDNInfo(payload, CDN_FINDER_ENDPOINT, response => {
            console.log("Response : %s %j", segment, response.response[segment]);
            this.setState({
                [`${segment}`]: response.response[segment]
            });
            resolve("Done " + segment);
        }, this.dataProvider, {});
    }

    handleAlert() {
        // console.log("In handleAlert");
        this.setState({ alert: false });
    }
    componentDidCatch(error, info) {
        console.log(error);
        sendError(error);
        // cwr('recordError', error);
    };

    componentDidMount() {
        this.dataProvider.getData('images', response => this.setState({ images: response }));
    }

    render() {
        return (
            <React.Fragment>
                <TopPanel label={demoModule} />
                <AppLayout
                    navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
                    // breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
                    content={
                        <Content
                            // Changes the Help panel content when the user clicks an 'info' link
                            replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
                            handleClick={this.handleClick}
                            handleAlert={this.handleAlert}
                            onInputChange={this.onInputChange}
                            currentState={this.state}
                        />
                    }
                    contentType="default"
                    tools={Tools[this.state.toolsIndex]}
                    onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
                    toolsOpen={this.state.toolsOpen}
                    navigationOpen={this.state?.navigationState}
                    onNavigationChange={e => this.setState({ navigationState: e.detail.open })}
                />
            </React.Fragment>
        );
    }
};

const Content = props => {

    return (
        <React.Fragment>
            <BrowserCheck />
            <SpaceBetween direction='vertical' size='l'>
                <SynopsisPanel label="Synopsis" videolink="https://broadcast.amazon.com/videos/678808">
                    In this tool, you can detect the CDN, DNS, IP and Origin details for a given domain.
                    We use tools like dig, curl, whois, HTML content and response headers to derive these insights.
                    Uses <Link external href="https://www.crummy.com/software/BeautifulSoup/bs4/doc/">BeautifulSoup</Link> library for parsing the HTML content.
                    <Box>
                        Note:
                        Video URL are not present consistently in a page, many times they are injected on-demand through APIs. Having said that I did the following:
                        <ul>
                            <li>Look at page SEO related metadata that specify if this is a video content and any URL present</li>
                            <li>Look at all links available with the HTML content that end with .mp4 (short clips), .m3u8 (HLS) and .mpd (DASH)</li>
                        </ul>
                    </Box>
                </SynopsisPanel>
                <Container>
                    <form onSubmit={props.handleClick}>
                        <Form
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Alert type='error'
                                        visible={props.currentState.alert}
                                        onDismiss={() => props.handleAlert()}
                                        // dismissAriaLabel={<Box variant='strong'>Close alert</Box>}
                                        dismissible
                                    // header={<Box variant='p'>Select AWS Region</Box>}
                                    >
                                        <Box variant='small'>Enter domain name and hit 'Find'</Box>
                                    </Alert>
                                    <StatusIndicator type={props.currentState.statusType}>
                                        {props.currentState.status}
                                    </StatusIndicator>
                                    <Button variant="primary">Find</Button>
                                </SpaceBetween>
                            }
                        >

                            <FormField label="Domain name" info={
                                <Link variant="info" onFollow={() => props.replaceToolsContent(1)}>
                                    Info
                                </Link>
                            }>
                                <SpaceBetween direction='horizontal' size='xxxs'>
                                    {/* <Box padding={{ top: 'xs' }}>https://</Box> */}
                                    <Input inputMode='text' className='large_input_width'
                                        onChange={props.onInputChange.bind(this, 'domain')}
                                        value={props.currentState.domain}
                                        placeholder="Enter domain name"
                                    />
                                </SpaceBetween>

                            </FormField>

                        </Form>
                    </form>
                </Container>
                <SpaceBetween direction='vertical' size='l'>
                    {props.currentState.primary && <TablePanel label="Primary" results={props.currentState.primary} replaceToolsContent={props.replaceToolsContent} index="1" />}
                    {props.currentState.secondaryimages && <TablePanel label="Images" results={props.currentState.secondaryimages} replaceToolsContent={props.replaceToolsContent} index="7" />}
                    {props.currentState.secondaryscripts && <TablePanel label="Scripts" results={props.currentState.secondaryscripts} replaceToolsContent={props.replaceToolsContent} index="8" />}
                    {props.currentState.secondarya && <TablePanel label="Links" results={props.currentState.secondarya} replaceToolsContent={props.replaceToolsContent} index="9" />}
                    {props.currentState.secondarycss && <TablePanel label="CSS" results={props.currentState.secondarycss} replaceToolsContent={props.replaceToolsContent} index="10" />}
                    {props.currentState.secondaryvideos && <TablePanel label="Video" results={props.currentState.secondaryvideos} replaceToolsContent={props.replaceToolsContent} index="11" />}
                </SpaceBetween>
                <ExtendingPanel label="Extend it">
                    <ColumnLayout columns={2} variant='text-grid' className='extend_it'>
                        <Box>Questions/ideas on this demo?</Box>
                        <SpaceBetween direction='vertical'>
                            <Box variant='strong'>
                                Marcelo Pinto,
                                mpinto@amazon.com
                            </Box>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExtendingPanel>
            </SpaceBetween>
        </React.Fragment >
    );
};

const TablePanel = props => {

    return (
        <Table key={props.label}
            columnDefinitions={[
                {
                    id: "Domain",
                    header: <TableInfo label="Domain" index="1" replaceToolsContent={props.replaceToolsContent} />,
                    cell: item => item.domain || "-",
                },
                {
                    id: "IP",
                    header: <TableInfo label="IP" index="2" replaceToolsContent={props.replaceToolsContent} />,
                    cell: item => item.IP || "-",
                },
                {
                    id: "DNS",
                    header: <TableInfo label="DNS" index="3" replaceToolsContent={props.replaceToolsContent} />,
                    cell: item => item.DNS || "-"
                },
                {
                    id: "IP_owner",
                    header: <TableInfo label="IP Owner" index="4" replaceToolsContent={props.replaceToolsContent} />,
                    cell: item => item.IP_owner || "-"
                },
                {
                    id: "CDN",
                    header: <TableInfo label="CDN" index="5" replaceToolsContent={props.replaceToolsContent} />,
                    cell: item => item.CDN || "-"
                },
                {
                    id: "Origin",
                    header: <TableInfo label="Origin" index="6" replaceToolsContent={props.replaceToolsContent} />,
                    cell: item => item.Origin || "-"
                }
            ]}
            variant="container"
            items={props.results}
            loadingText="Loading data.."
            sortingDisabled
            header=<TableInfo label={props.label} replaceToolsContent={props.replaceToolsContent} index={props.index} />
        />
    );
};

const Loading = props => {
    if (props.results.length == 0)
        return "loading"
    else
        return ""
}
const TableInfo = props => {
    return (
        <Header variant="h5" info={
            <Link variant="info" onFollow={() => props.replaceToolsContent(props.index)}>
                Info
            </Link>}>{props.label}</Header>
    );
};

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
    <HelpPanel
        header={<h3>{demoModule}</h3>}
    >
        <p>
            Find CDN, DNS and other information of the primary domain entered
            and all secondary domains found in the HTML content.
        </p>
    </HelpPanel>,
    <HelpPanel header={<h2>Domain name</h2>}>
        Primary domain name to inspect
        eg: www.netflix.com
    </HelpPanel>,
    <HelpPanel header={<h2>IP</h2>}>
        One of the IP address for the domain found using 'dig'
    </HelpPanel>,
    <HelpPanel header={<h2>DNS</h2>}>
        DNS provider for the domain found using 'dig'
    </HelpPanel>,
    <HelpPanel header={<h2>IP Owner</h2>}>
        Owner of the IP found using 'whois'
    </HelpPanel>,
    <HelpPanel header={<h2>CDN</h2>}>
        CDN provider for this domain found using response headers
    </HelpPanel>,
    <HelpPanel header={<h2>Origin</h2>}>
        Origin/Backend deployed environment
    </HelpPanel>,
    <HelpPanel header={<h2>Images</h2>}>
        Top 5 domains used to load images
    </HelpPanel>,
    <HelpPanel header={<h2>Scripts</h2>}>
        Top 5 domains used to load Javascripts
    </HelpPanel>,
    <HelpPanel header={<h2>Links</h2>}>
        Top 5 domains used in hyperlinks on the page
    </HelpPanel>,
    <HelpPanel header={<h2>CSS</h2>}>
        Top 5 domains used to load CSS
    </HelpPanel>,
];