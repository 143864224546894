// CloudFront table content
import React from 'react';
import StatsPanel from './StatsPanel.jsx';

import {
  Table,
  Header,
  Link,
  HelpPanel,
  ColumnLayout,
  SpaceBetween,
  Box
} from '@cloudscape-design/components';

const CFTablePanel = props => {

  return (
    <div>
      <Table key={props.key1} variant='embedded'
        columnDefinitions={[
          {
            id: "pop",
            header: <TableInfo label="PoP" index="1" replaceToolsContent={props.replaceToolsContent} />,
            cell: item => item.pop || "-",
          },
          {
            id: "cache",
            header: <TableInfo label="Cache" index="2" replaceToolsContent={props.replaceToolsContent} />,
            cell: item => item.cache || "-",
          },
          // {
          //   id: "compression",
          //   header: <TableInfo label="Compression" index="3" replaceToolsContent={props.replaceToolsContent}/>,
          //   cell: item => item.compression || "-",
          // },
          {
            id: "age",
            header: <TableInfo label="Age(sec)" index="4" replaceToolsContent={props.replaceToolsContent} />,
            cell: item => item.age || "-"
          },
          {
            id: "time",
            header: <TableInfo label="Time Taken(msec)" index="5" replaceToolsContent={props.replaceToolsContent} />,
            cell: item => item.time || "-"
          }
        ]}
        items={props.results}
        loadingText="Loading data.."
        sortingDisabled
      />
      {/* <StatsPanel stats={props.stats} /> */}
    </div>
  );
};

const TableInfo = props => {
  return (
    <Header variant="h5" info={
      <Link variant="info" onFollow={() => props.replaceToolsContent(props.index)}>
        Info
      </Link>}>{props.label}</Header>
  );
};

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const CFTableTools = [
  <HelpPanel header={<h2>PoP (Point of Presence)</h2>}>
    <p>
      The CloudFront Edge location closest to user serving the request. Identified from <code>x-amz-cf-pop</code> response header
    </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Cache</h2>}>
    <div>
      <ul>
        <li>Hit - if content was served from cache</li>
        <li>Miss - content was not present in the Edge location and was fetched from origin</li>
      </ul>
    </div>
  </HelpPanel>,
  <HelpPanel header={<h2>Compression</h2>}>
    <p>
      Specify what compression was applied to the response payload. Depending on your browsers <code>Accept-Encoding</code>
      request header.
    </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Age</h2>}>
    <p>
      Specify the time in seconds since the object was cached.
    </p>
  </HelpPanel>,
  <HelpPanel header={<h2>Time taken</h2>}>
    <p>
      Round trip time taken in milliseconds to fetch the response payload.
    </p>
  </HelpPanel>
];

export default CFTablePanel;
