// CloudFront table tooltip content
import {
  ExpandableSection
} from '@cloudscape-design/components';

const ExtendingPanel = ({ key1, label, children, expanded, variant }) => {

  return (
    <ExpandableSection key={key1} variant={variant} header={label} expanded={expanded} >
      {children}
    </ExpandableSection>
  );
};

export default ExtendingPanel;
