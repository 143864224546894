/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

  https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import ServiceNavigation from '../ServiceNavigation.jsx';
import ExtendingPanel from '../ExtendingPanel.jsx';
import SynopsisPanel from '../SynopsisPanel.jsx';
import TopPanel from '../TopPanel';
import BottomPanel from '../BottomPanel.jsx';
import BrowserCheck from '../BrowserCheck.jsx';
import {
  WAF_DASHBOARD_ENDPOINT,
} from '../../resources/prod-env.jsx';
import {
  AppLayout,
  ColumnLayout,
  Grid,
  Header,
  SpaceBetween,
  Container,
  Link,
  HelpPanel,
  Box,
  Icon,
  Button,
  Popover,
  StatusIndicator
} from '@cloudscape-design/components';
import { sendAnalytics} from '../../resources/rum-provider.ts';
import '../../styles/form.scss';
const demoModule = "WAF Dashboard";

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class WAFDashboard extends React.Component {

  constructor(props) {
    super(props);
    // console.log(performance.now());
    this.state = {
      toolsIndex: 0, toolsOpen: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    // e.preventDefault();
    sendAnalytics({ demo: demoModule });
  }

  render() {
    return (
      <React.Fragment>
        <TopPanel label="WAF Dashboard" />
        <AppLayout
          navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
          // breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
          content={
            <Content
              // Changes the Help panel content when the user clicks an 'info' link
              replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
              handleClick={this.handleClick}
            />
          }
          contentType="default"
          tools={Tools[this.state.toolsIndex]}
          onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
          toolsOpen={this.state.toolsOpen}
        />
        <BottomPanel label="CMCD" />
      </React.Fragment>
    );
  }
}

// The content in the main content area of the App layout
const Content = props => {

  return (
    <React.Fragment>
      <BrowserCheck />

      <SpaceBetween direction='vertical' size='l'>
        <SynopsisPanel label="Synopsis">
          This demo shows a sample Dashboard that can be build using WAF logs with OpenSearch
        </SynopsisPanel>
        <Grid
          gridDefinition={[
            { colspan: { default: 6, xxs: 6 } },
          ]} >
          <Container>
            <SpaceBetween direction='vertical' size='l'>
              <ColumnLayout columns={2} variant='text-grid'>
                <Header variant="h5"
                >Dashboard</Header>
                {/* <Box variant='awsui-key-label'> </Box> */}

                <SpaceBetween direction='horizontal'>
                  <Link external href={WAF_DASHBOARD_ENDPOINT} onFollow={props.handleClick}> WAF Dashboard</Link>
                </SpaceBetween>
                <Box variant='awsui-key-label'> User name</Box>
                <Box><code>wafuser</code> </Box>
                <Box variant='awsui-key-label'> Password</Box>
                <Popover
                  dismissButton={false}
                  position="top"
                  size="small"
                  triggerType="custom"
                  content={
                    <StatusIndicator type="success">
                      Password copied to clipboard
                    </StatusIndicator>
                  }
                >
                  <Button iconName="copy" variant='normal'
                    onClick={() => navigator.clipboard.writeText('zwh$s6rj')}>Copy</Button>
                </Popover>
              </ColumnLayout>
              <Box variant='small'>Once logged in,if you don't see the dashboard, using the 'Menu'
                on left-hand side, navigate to 'WAFDashboard'</Box>
            </SpaceBetween>
          </Container>
        </Grid>
        <ExtendingPanel label="Extend it">
          <ColumnLayout columns={2} variant='text-grid' className='extend_it'>
            <Box> Git repo</Box>
            <Link href="https://github.com/rliwoch/aws-waf-dashboards-cdk" external>https://github.com/rliwoch/aws-waf-dashboards-cdk</Link>
            <Box> Blog Post</Box>
            <Link href="https://aws.amazon.com/blogs/security/deploy-dashboard-for-aws-waf-minimal-effort/" external></Link>
            <Box>Questions/ideas on the WAF Demo?</Box>
            <Box variant='strong'>
              Contact Authors on the blog
            </Box>
          </ColumnLayout>
        </ExtendingPanel>
      </SpaceBetween>
    </React.Fragment >
  );
};

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
  <HelpPanel
    header={<h2>WAF Dashboard</h2>}
    footer={
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <Link external href="https://aws.amazon.com/blogs/security/deploy-dashboard-for-aws-waf-minimal-effort/">
              Deploy a dashboard for AWS WAF with minimal effort
            </Link>
          </li>
        </ul>
      </div>
    }
  >
    The solution ingests WAF logs into an Open Search cluster and visualized using Kibana.
  </HelpPanel>,
  <HelpPanel header={<h3>CMCD Events</h3>} loadingText="index1">
    <Box>
      CMCD is a specification developed by WAVE (Web Application Video Ecosystem) project hosted by the Consumer Technology Association (CTA).
      It specifies how media players can convey QoE client-side metrics with each request,
      as a custom HTTP request header, as an HTTP query argument, or as a JSON object.
      CMCD specification containing full list of metrics can be found
      <Link external href="https://cdn.cta.tech/cta/media/media/resources/standards/pdfs/cta-5004-final.pdf"> here</Link>
    </Box>
    <Box>
      <ul>
        <li>
          <Box variant='strong'>Session ID (sid) </Box>identifies the current playback session and allows thousands of individual server log lines to be interpreted as a single user session and build reports on a session level. It can be also used for troubleshooting purposes: if there is a video session suffering from rebuffering, Session ID helps quickly find individual requests belonging to that session and provide them to Support for investigation.          </li>
        <li>
          <Box variant='strong'>Buffer starvation (bs) </Box>signals that the player is in rebuffering state and the video or audio playback were stalled right before sending the request. It indicates an issue to be addressed. By checking corresponding server-side metrics we can verify operational health of CDN server and see whether the issue is related to the server, or the root cause resides elsewhere, for example, in specific network segment or Origin.
        </li>
        <li>
          <Box variant='strong'>Buffer length (bl), Measured throughput (mtp),  Encoded bitrate (br) and Top bitrate (tb) </Box>allow to monitor the quality of experience (QoE) and tell us how happy the viewers are. For example, you can monitor what throughput is available for your viewers in different Geo locations and plan your content encoding profile accordingly. Top bitrate flags the best quality bitrate available for viewers while Encoded bitrate is the actual used bitrate - in ideal scenario they should be the same and if not, the QoE is not at its best. You can even work out a formula for an overall quality of experience score out of these metrics and use it for benchmarking CDNs.
        </li>
        <li>
          <Box variant='strong'>Content ID (cid), Object duration (d), Playback rate (pr), Streaming format (sf) and Stream type (st) </Box>can be used in content analytics to measure its popularity, engagement time and view it by various dimensions including Geo location, client device type and time of day.
        </li>
      </ul>
    </Box>
  </HelpPanel>,
  <HelpPanel header={<h3>Dashboards</h3>} loadingText="index2">
    <Box variant='h5'>Quality of Experience</Box>
    <Box>
      <ul>
        <li>
          This dashboard is intended to show QoE and traffic metrics.
          There are two sections (rows) - <Box variant='strong'>
            Quality of experience</Box> and <Box variant='strong'>Traffic figures</Box>. They can be minimized to help focus on other data.
        </li>

        <li>
          <Box variant='strong'>Concurrent</Box> Plays is estimated by counting the number of unique video session id (CMCD sid parameter) for the time period specified in Time picker.
          Since it is only 5 minutes video constantly played, the same client can start different playbacks even within 5 minutes time interval.
        </li>
        <li>
          <Box variant='strong'>Rebuffering percentage </Box>is calculated as percentage of requests signalling rebuffering (CMCD bs) to the total number of requests.
        </li>
        <li>
          Find top contributor to <Box variant='strong'>Rebuffering percentage </Box>by selecting different countries in Country variable. How many rebuffering events are in the US and how many in France?
          How the data changed in Average Encoded Bitrate and  Average Throughput per Client for the country producing most of rebuffering events?
        </li>
        <li>
          Check <Box variant='strong'>Average Throughput per Client</Box> for the US and for Ireland (IE) - depending on in what region the solution has been installed, the S3 Origin can be closer to one or another country which affect the throughput for cache Miss case. If Cache Hit rate is close to 100%, feel free to invalidate CloudFront distribution.
        </li>
        <li>
          Check the charts in <Box variant='strong'>Quality of experience </Box>section. Unlike the upper widgets,
          charts in this section are using percentile aggregation controlled by
          <Box variant='strong'> Percentiles aggregation </Box>variable.
        </li>
        <li>
          Compare <Box variant='strong'>Total Throughput</Box> and
          <Box variant='strong'> Measured Throughput</Box> in <Box variant='strong'>Traffic figures </Box>section.
          <Box variant='strong'>Total Throughput</Box> is calculated from CDN logs,
          by dividing the sum of bytes sent to the viewers by the time period.
          This way the picks are smoothed over period of time.

          <Box variant='strong'>Measured Throughput </Box>is measured from CMCD parameter measured throughput estimated by media player. In this case, traffic pick is not smoothed.
        </li>
        <li>
          <Box variant='strong'>Plays by GEO</Box> map shows a number of concurrent sessions originated in a country.
          <Box variant='strong'>Plays by PoP</Box> shows a number of concurrent sessions terminated by a CloudFront PoP. It can help see accuracy of request routing.
        </li>
      </ul>
    </Box>
    <Box variant='h5'>Troubleshooting</Box>
    <Box>
      <ul>
        <li>
          This dashboard is intended to help find a root cause of rebuffering events.
          <Box variant='strong'>Rebuffering Ratio</Box> is measured differently than in QoE dashboard.
          It quantifies the ratio of video sessions that have more than 1% of rebuffering requests to the total number of requests within selected <Box variant='strong'>Interval</Box>.
          For example, if the video chunks duration is 4 seconds, buffer length is 30 seconds, the player sends approx. 82 requests during 5 minutes.
          Therefore, just 2 requests signalling rebuffering would be more than 1% of all requests making the session being counted as stalled/rebuffered.
          Rebuffering Ratio chart can be used to set up an alert when percentage of rebuffering video plays exceeds a certain threshold and the root cause analysis is required.
        </li>
        <li>
          Using variables try to locate rebuffering to Country, Device type or Edge location. If it is evident that rebufferig is related to one of these dimensions it already suggests what can cause the issue.
          For example, if rebuffering happens on one of the CloudFront PoPs and for different viewer countries it signals that the PoP is under performing.
        </li>
        <li>
          <Box variant='strong'>Rebuffering Events Count</Box> shows the number of requests signalling rebuffering via CMCD parameter BS (Buffer starvation). It is sent by a player when it was in rebuffering state prior to this request. Therefore, here we use only dimensions that do not change during the same video playback - Country, Edge Location, Streaming format, Protocol, CloudFront distribution and Device type.
        </li>
        <li>
          To aid on the root cause investigation, the next widget shows excerpt from logs for the request prior to Buffer starvation signal. It includes client IP to determine viewer network ASN and CloudFront request ID required by CloudFront support for performance related tickets. It enables for more accurate issues tracing.
          Here we also can filter data by Response Result Type and HTTP status code.
        </li>
        <li>
          To conduct more thorough investigation and understand whether rebuffering is caused by CDN or Origin, we use <code>Time-to-fist-byte (TTFB)</code> from CloudFront logs, which is the number of seconds between CloudFront server receives the request and writes the first byte of the response to the underlying kernel TCP stack.
          There is no impact of external aspects on this value (such as network or the file size), therefore it is straightforward to use as an indication of a CDN server performance.
          However, when request is Cache Miss, the CDN server needs to wait till the response from Origin arrived, therefore in this case TTFB indicates performance of Origin.
          By using both TTFB for Cache and Miss case we can determine whether we need to focus investigation on CDN performance or Origin, and if there is no evidence on their performance degradation, the troubleshooting focus can be switched
          on network or clients issues.
          For this we merge TTFB and Rebuffering ratio on the same chart to see if one is correlated to another.
          For example, if there is simultaneous spike for both metrics it can signal correlation.
          Sometimes, TTFB can vary significantly and still remain within acceptable values.
          For example, spike in TTFB from 10 ms to 20 ms is 100% increase but 20 ms is acceptable delay for the application to start delivering the first byte without causing performance issues.
          Therefore, it is important to pay attention to the actual values and not only on TTFB line volatility.
          To help we that, we use 3rd line <Box variant='strong'>Moving average TTFB</Box> that measures the trend.
          This allows not to be misled by occasional spikes but rather see if there is an uptrend signalling issues.
        </li>
      </ul>
    </Box>
  </HelpPanel >,
];
