import React, { useEffect, useState } from 'react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import ServiceNavigation from './ServiceNavigation.jsx';
import TopPanel from './TopPanel';
import {
  AppLayout,
  Button,
  Box,
  ColumnLayout,
  Container,
  SpaceBetween,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

Amplify.configure({
  Auth: {

    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    //OPTIONAL - Amazon Cognito User Pool ID
    //userPoolId: 'us-east-1_jAUiew2Hu',
    userPoolId: 'us-east-1_vXh8uo1UN',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    //userPoolWebClientId: '18mtkivmnkhcd4r8rv7ev7jnk5',
    userPoolWebClientId: 'h4bfm677vbqadnul5e49t4fsa',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    //mandatorySignIn: false,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    //authenticationFlowType: 'ALLOW_USER_SRP_AUTH',

    oauth: {
      //domain: 'demoportal-authenticationstack.auth.us-east-1.amazoncognito.com',
      domain: 'edgeservicesdemo.auth.us-east-1.amazoncognito.com',
      scope: [
        'phone',
        'email',
        'openid',
        'profile',
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: 'https://demos.edge.aws.dev/',
      redirectSignOut: 'https://demos.edge.aws.dev/',
      responseType: 'code'
    }
  }
});

function App() {

  const user = localStorage.getItem("user");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  // console.log("login invoked and user is " + user)

  function getUser() {
    // console.log("getUser called")
    // console.log(user)
    return user
  }

  function signOutUser() {
    Auth.signOut();
  }

  return (
    <React.Fragment>
      <TopPanel label="Signin/SignOut" />
      <AppLayout
        // breadcrumbs={<Breadcrumbs />}
        // maxContentWidth
        navigationHide
        navigation={< ServiceNavigation />}
        toolsHide
        content={
          <Container header=<Box variant='h5'> Welcome to EdgeServices Demo Portal</Box> className="wip_class1">
          <ColumnLayout columns={2} variant='default'>

            {user ? (
              <Button variant="primary" onClick={() => signOutUser()}>Sign Out {user.username} </Button>
            ) : (
              <ColumnLayout columns={2} variant='text-grid'>

              <Button variant="primary" onClick={() => {

                Auth.federatedSignIn({ customProvider: "FederateOIDC" }).then(() => {

                  console.log("signed in");
                  navigate("/");
                })

              }}>Signin With Midway</Button>
<SpaceBetween direction='vertical' size='m'>
    <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
    />
    <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
    />
    <Button variant="primary" onClick={() => {
setError("");
Auth.signIn({ username:username,password:password }).then(() => {

  console.log("signed in custom");
  navigate("/");
}).catch((e)=>{
  console.log("Error");
  setError(e.message);
});

}}>Signin With Custom</Button>
    <Box color="text-status-error">{error}</Box>
</SpaceBetween>
</ColumnLayout>

            )}
          </ColumnLayout>
          </Container>
        }
        contentType="default"

      />
    </React.Fragment>
  );
}

export default App;
