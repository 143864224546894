/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React, { Children } from 'react';
import ServiceNavigation from './ServiceNavigation.jsx';
import TopPanel from './TopPanel.jsx';
import BottomPanel from './BottomPanel.jsx';

import {
  AppLayout,
  Button,
  ColumnLayout,
  FormField,
  Icon,
  Select,
  Container,
  Header,
  Box,
  Grid,
  SpaceBetween,
  Link,
  TextContent,
  ExpandableSection,
} from '@cloudscape-design/components';
import Breadcrumbs from './Breadcrumbs.jsx';
import '../styles/servicehomepage.scss';

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default props => {
  return (
    <React.Fragment>
      <TopPanel />
      <AppLayout
        // disableContentPaddings={true}
        // breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
        navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
        content={<Content />}
        contentType="default"
        onNavigationChange={e => this.setState({ navigationState: e.detail.open })}
        navigationOpen={props.state?.navigationState}
        toolsHide={true}
      />
      <BottomPanel label="Home" />
    </React.Fragment>
  );
};

// The content in the main content area of the App layout
const Content = props => (
  <Grid
    gridDefinition={[
      { colspan: { default: 12, xxs: 12 } },
      { colspan: { default: 6, xxs: 6 } },
      { colspan: { default: 6, xxs: 6 } },
      { colspan: { default: 12, xxs: 12 } },
      { colspan: { default: 12, xxs: 12 } },
    ]}
  >
    {/* <Container header=<Header variant='h2'>Welcome</Header> /> */}
    <ExpandableSection header="Why did we build Demo Portal?">
      <TextContent>
        <Box variant='p'>
          Showing demos are impactful in our customer engagement. While we can talk and explain how our service and feature works, showing a demo offers a tangible visual experience to supplement our messaging, they add color to our talk.
        </Box>
        <Box variant='p'>
          Demos are mostly <strong>setup manually</strong>, configuring different resources in correct AWS region, ensuring these resources have the right roles and permissions and generating some sample inputs/data to test it. It is a <strong>time consuming</strong> process and depends on 1/ the complexity of the demo we are trying to build, 2/ the skillsets of the individual trying to build it.
        </Box>
        <Box variant='p'>
          This leads to <strong>different flavors</strong> of demos trying to showcase the same feature, some use CLI, others use browser or other tools like Postman etc. We strive to be consistent in the messaging but in demos there is lack of consistency in the way we showcase a feature capability.
        </Box>
        <Box variant='p'>
          Further, once a demo is build <strong>maintaining them</strong> over a period of time is a challenge because 1/ they were mostly built by hand and hard to reproduce &  repeat them, 2/ not easy to discover which configuration is for which demo and we end making changes to an existing setup leading to drift in configuration over time. We are not sure if things will work and need to review and test them each time, they are not readily available.
        </Box>
        <Box variant='p'>
          In some situations just building a demo is not enough and we need <strong>sufficient data</strong> to showcase them meaningfully. Examples are historical log analysis, sample images for image optimizations, sample redirects definitions,  signatures for SQLi, XSS etc. In these situations, we try and generate some sample data but are not able to capture and showcase variance in the dataset effectively.
        </Box>
        <Box variant='strong'>What we want to achieve?</Box>
        <Box variant='p'>
          Having a <strong>central repository</strong> of <strong>always-on functional demos</strong> will alleviate some of these challenges. The demos should showcase how a feature works with different variations possible. These demos should be readily available with <strong>zero setup</strong> and no maintenance.
          Further, to streamline the visual experience we will adopt AWSUI (https://cloudscape.design/) which is used in AWS console experience.
          Each demo should be self sufficient in terms of describing what is the intend, the associated architecture, tooltips describing each field and providing more information to external AWS documentation.
        </Box>
        <Box variant='p'>
          Further, the backend resources powering a demo should be available via a CloudFormation template. This will allow deeper study of the configuration and use as baseline for further customization by individual.
        </Box>
        <Box variant='strong'>Target audience</Box>
        <Box variant='p'>
          Demos should be accessible to wider community and is focused on enabliing TFC members and seller, but could be utilized by Edge SA’s as well if need be.
        </Box>
        <Box variant='strong'>Where will it be deployed and access?</Box>
        <Box variant='p'>
          Demos should be deployed in a dedicated Isengard account and deployments should be automated with CI/CD pipeline. We will also define common baseline WAF rules and associate them to public endpoints to protect them from attacks.

          For access, ideally, we should integrate with AWS Single Sign-on process so we don’t have to maintain user credentials and next alternative would be to use Cognito to create user accounts for all members.
        </Box>
        <Box variant='strong'>What this framework does not cover?</Box>
        <Box variant='p'>
          The demo framework is focused on building functional demos and its not a walk-through of the AWS console and show how to configure a particular feature. Console walk throughs and how to setup are covered by videos and blog posts, solutions etc. However, each demo backend resources can be studied and extended through the CloudFormation templates.
        </Box>
        <Box variant='strong'>Have a demo idea or want to contribute?  </Box>
        <Box>
          Contact
          <SpaceBetween direction='vertical'>
            <Box>Jaiganesh Girinathan, ganeshji@</Box>
            <Box>Sergio Czernichow, czernich@</Box>
          </SpaceBetween>
        </Box>
      </TextContent>
    </ExpandableSection>
    <Container header={<Header variant='h3'>What's new?</Header>}>
      <ColumnLayout columns={1} borders='horizontal'>
        <SpaceBetween direction='vertical' size='xxxs'>
          <Box><Link href="/contentdiscovery">Tools: Discover Content</Link></Box>
          <Box>
            An application of GenAI, this tool helps find content across our various content sources including blogs, workshops, Immersion Days, YouTube and Cloud@Edge.
          </Box>
        </SpaceBetween>
        <SpaceBetween direction='vertical' size='xxxs'>
          <Box><Link href="/wafrbr">WAF: RBR</Link></Box>
          <Box>
            Improvised to showcase the newer rate-based rule aggregations and rate limiting by requests attributes like forwarded IP address, query string/argument, URI path and its combinations.
          </Box>
        </SpaceBetween>
        <SpaceBetween direction='vertical' size='xxxs'>
          <Box><Link href="/wafcustom">WAF: Custom Request/Response</Link></Box>
          <Box>
            In this demo, you can show how WAF can be used to insert headers & generate custom responses
          </Box>
        </SpaceBetween>
        <SpaceBetween direction='vertical' size='xxxs'>
          <Box><Link href="/wafatp">WAF: ATP</Link></Box>
          <Box>
            In this demo, you can show how WAF ATP feature works on login forms with credentials
            that are valid and compromised. Demo allows using request and response attributes.
            Updated to add showcase response handling by ATP as well.
          </Box>
        </SpaceBetween>
        <SpaceBetween direction='vertical' size='xxxs'>
          <Box><Link href="/acceleration">CloudFront: Acceleration</Link></Box>
          <Box>
            Updated this demo to 1/ include options to specify number of attempts on the test(default 25), 2/ allow bringing your endpoints to compare and test.
          </Box>
        </SpaceBetween>
        <SpaceBetween direction='vertical' size='xxxs'>
          <Box><Link href="/multicdn">Observability: Multi-CDN</Link></Box>
          <Box>
            This demo shows how we can compare performance metrics(as seen by viewers) between
            Akamai and CloudFront. The traffic is split using DNS weighted routing traffic policy.
          </Box>
        </SpaceBetween>
        <SpaceBetween direction='vertical' size='xxxs'>
          <Box><Link href="/cfrum">Observability: Website Performance</Link></Box>
          <Box>
            This demo shows how we can get deeper performance metrics(as seen by viewers).
            The solution is extendable to include comparison scenraios when CloudFront Continuous Deployment feature is used.
            The traffic is split between 'prod' and 'stage' environment in this case.
          </Box>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
    <Container header={<Header variant='h3'>Coming soon..</Header>}>
      <ColumnLayout columns={1} borders='horizontal'>
        <SpaceBetween direction='vertical' size='xxxs'>
          <Box variant='strong'>Global Accelerator</Box>
          <Box>See in action the benefits of AGA</Box>
        </SpaceBetween>
      </ColumnLayout>
    </Container >
    <Box variant='h3'>All demos</Box>
    <Grid
      gridDefinition={
        [
          { colspan: { default: 3, xxs: 6 } },
          { colspan: { default: 9, xxs: 6 } },
          { colspan: { default: 9, xxs: 6 } },
          { colspan: { default: 9, xxs: 6 } },
          { colspan: { default: 9, xxs: 6 } },
          { colspan: { default: 9, xxs: 6 } },
          { colspan: { default: 9, xxs: 6 } },
          { colspan: { default: 9, xxs: 6 } },
          { colspan: { default: 9, xxs: 6 } },
          { colspan: { default: 9, xxs: 6 } },
          { colspan: { default: 9, xxs: 6 } },
          { colspan: { default: 9, xxs: 6 } },
          { colspan: { default: 9, xxs: 6 } },
          { colspan: { default: 9, xxs: 6 } },
          { colspan: { default: 9, xxs: 6 } },
        ]}
    >
      <DemoTile link="/acceleration" title="Acceleration" service="CloudFront:">
        In this demo, we will show you the application acceleration when it is served through CloudFront vs directly from Origin such as S3 or ALBs.
        You can choose the AWS region which will act as Origin. We will run tests accessing assets via
        CloudFront and comparing that versus other origins to see the acceleration and
        compression benefits as applicable. This demo displays a graph of performance
        differences in 4 categories: Images, APIsGET, CSS/Javascript, and APIsPOST.
        You have visibility into the content requested, the time it took to fetch the
        content, its size, age in CloudFront cache and what type of compression was applied.
      </DemoTile>
      <DemoTile link="/cmcd" title="Common-Media-Client-Data" service="CloudFront:">
        This demo provides an example of how Common [Media Client Data (CMCD) can be
        used with CloudFront, Amazon Timestream and Amazon Managed Grafana.
        A dashboard with pre-defined set of widgets build out of CloudFront logs
        enriched with CMCD metrics is available.
      </DemoTile>
      <DemoTile link="/signedurl" title="Signed URL/Cookies" service="CloudFront:">
        This demo showcases CloudFront's authorization feature with SignedURLs and SignedCookies
      </DemoTile>
      <DemoTile link="/image" title="Image Optimizations" service="CloudFront:">
        This demo showcases CloudFront use along with CloudFront Functions and Lambda service to optimize images on the fly.
      </DemoTile>
      <DemoTile link="/caching" title="Caching" service="CloudFront:">
        This demo showcases how you can control the cache times using 'Cache-Control' response headers from origin.
      </DemoTile>
      <DemoTile link="/inspect" title="Inspect Request" service="CloudFront:">
        In this demo, we will inspect the request attributes:headers, query strings, cookies available in the 4 event triggers
        available in CloudFront. Note the available request attributes varies by the Cache Policy and Origin Request Policy.
      </DemoTile>
      <DemoTile link="/wafdashboard" title="WAF Logs" service="Observability:">
        In this demo, you can showcase a fully automated dashboard for AWS Web Application Firewall (WAF) service logs.
        The solution uses logs generated and collected by AWS WAF, and displays them in a user-friendly dashboard.
      </DemoTile>
      <DemoTile link="/cdnfinder" title="CDN Finder" service="CloudFront:">
        This tool helps detect the CDN, DNS, IP and Origin details for a given domain.
        We use tools like dig, curl, whois, HTML content and response headers to derive these insights.
      </DemoTile>
      <DemoTile link="/wafsqli" title="WAF: SQLi/XSS" service="WAF:">
        In this demo, you can show how WAF SQLi/XSS feature works on various
        request attributes.
      </DemoTile>
      <DemoTile link="/wafrbr" title="RBR" service="WAF:">
        In this demo, you can show how WAF Rate based rules can be used to
        block request floods.
      </DemoTile>
      <DemoTile link="/wafatp" title="ATP" service="WAF:">
        In this demo, you can show how WAF ATP feature works on login forms with credentials
        that are valid and compromised.
      </DemoTile>
      <DemoTile link="/wafcaptcha" title="CAPTCHA" service="WAF:">
        In this demo, you can show how WAF CAPTCHA works.
      </DemoTile>
      <DemoTile link="/wafcustom" title="Custom Request/Response" service="WAF:">
      In this demo, you can show how WAF can be used to insert headers & generate custom responses
      </DemoTile>
      <DemoTile link="/multicdn" title="Multi-CDN" service="Observability:">
        This demo shows how we can compare performance metrics(as seen by viewers) between
        Akamai and CloudFront. The traffic is split using DNS weighted routing traffic policy.
      </DemoTile>
      <DemoTile link="/cfrum" title="Website Performance" service="Observability:">
        This demo shows how we can get deeper performance metrics(as seen by viewers).
        The solution is extendable to include comparison scenraios when CloudFront Continuous Deployment feature is used.
        The traffic is split between 'prod' and 'stage' environment in this case.
      </DemoTile>
      <DemoTile link="/videocomparitor" title="Video Comparitor" service="MediaServices:">
        This is a tool to subjectively compare two video streams. It can help to determine how changes in encode settings effect 
        video quality. The player provides frame accurate shuttle controls to locate specific scenes and a slider enabling the user to reveal 
        areas of the frame to focus on. Clip and position information can be shared with others to aid discussion and demonstrate 
        video artefacts or other areas of interest. 
      </DemoTile>
      <DemoTile link="/contentdiscovery" title="Discovery Content" service="Tools:">
        An application of GenAI, this tool helps find content across our various content sources including blogs, workshops, Immersion Days, YouTube and Cloud@Edge.
      </DemoTile>
    </Grid>
  </Grid >
);

const DemoTile = ({ link, children, service, title }) => {
  return (
    <Container header=<Header><Link fontSize="heading-s" href={link}>{service} {title}</Link></Header>>
      <Box variant='p'>
        {children}
      </Box>
    </Container>
  );
}
// Breadcrumb content
const BreadcrumbsItems =
  [
    {
      text: 'Home',
      href: '/'
    },
  ];