import React from 'react';
import DataProvider from '../../resources/data-provider';
import ServiceNavigation from '../ServiceNavigation.jsx';
import ExtendingPanel from '../ExtendingPanel.jsx';
import BrowserCheck from '../BrowserCheck';
import TopPanel from '../TopPanel';

import {
    WAFCOMMON_ENDPOINT,
} from '../../resources/prod-env.jsx';
import {
    TabLabel,
} from '../common';
import {
    AppLayout,
    Button,
    ColumnLayout,
    Form,
    FormField,
    Select,
    SpaceBetween,
    Container,
    Link,
    HelpPanel,
    Box,
    Icon,
    Alert,
    Checkbox,
    ProgressBar,
    Badge,
    StatusIndicator,
    Tabs,
    Input,
} from '@cloudscape-design/components';

import '../../styles/form.scss';
// function cwr(operation, payload) { };
import { sendAnalytics, sendError } from '../../resources/rum-provider.ts';
const demoModule = "WAFRBR";

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class WAFRBR extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleAlert = this.handleAlert.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.dataProvider = new DataProvider();
        this.state = {
            toolsIndex: 0, toolsOpen: false, alert: false,
            noOfAttempts: "", currentCounter: 0, applyWAF: { checked: false },
            requestType: { checked: false },
            rbrType: "sourceIp", rbrSignature: "", 
            reset: false,
            addlParam1: { label: "", placeholder: "", value: "", show: false },
            addlParam2: { label: "", placeholder: "", value: "", show: false }
        };
        this.signatures = {
            ip_header: {
                param1: {
                    label: "X-Forwarded-For", value: "10.1.1.1", placeholder: 'Enter valid IP', show: true
                },
                param2: {
                    label: "", value: "", placeholder: "", show: false 
                }
            },
            count: {
                param1: {
                    label: "", value: "", placeholder: "", show: false 
                },
                param2: {
                    label: "", value: "", placeholder: "", show: false 
                }
            },
            custom_querystring: {
                param1: {
                    label: "Query Parameter Name", value: "arg", placeholder: 'Enter query parameter name', show: true
                },
                param2: {
                    label: "Query Parameter value", value: "1", placeholder: "Enter query parameter value", show: true
                }
            },
            custom_path: {
                param1: {
                    label: "URL Path", value: "/demopath2", placeholder: 'Enter URL path', show: true
                },
                param2: {
                    label: "", value: "", placeholder: "", show: false 
                }
            },
            custom_queryarg: {
                param1: {
                    label: "Query Argument Value", value: "demovalue1", placeholder: 'Enter query argument', show: true
                },
                param2: {
                    label: "", value: "", placeholder: "", show: false 
                }
            },
            custom_multiple: {
                param1: {
                    label: "X-Forwarded-For", value: "10.1.1.2", placeholder: 'Enter valid IP', show: true
                },
                param2: {
                    label: "Query Argument Value", value: "demovalue2", placeholder: 'Enter query argument', show: true
                }
            }
        }
    }

    onInputChange(name, e) {
        e.preventDefault();
        console.log("In onInputChange %s %j", name, e);
        if (name === "noOfAttempts") {
            this.setState({ [name]: e.detail.selectedOption });
            return;
        }
        else if (name === "rbrSignature") {
            this.setState({ [name]: e.detail.selectedOption })
            this.setState({ addlParam1: this.signatures[e.detail.selectedOption.value].param1, addlParam2: this.signatures[e.detail.selectedOption.value].param2});
        }
        else if (name === 'applyWAF') {
            this.setState({ [name]: e.detail });
        }
        else if (name === 'rbrType') {
            this.setState({ [name]: e.detail.activeTabId });
        }
        else if (name === 'param1') {
            this.setState(prevState => ({
                addlParam1: {
                  ...prevState.addlParam1,
                  value: e.detail.value
                }
            }));
        }
        else if (name === 'param2') {
            this.setState(prevState => ({
                addlParam2: {
                  ...prevState.addlParam2,
                  value: e.detail.value
                }
            }));
        }
    }

    handleClick(e) {
        e.preventDefault();
        if(this.state.reset) {
            this.setState({ reset: false, currentCounter: 0 });
            return;
        } 

        sendAnalytics({ demo: demoModule });

        let url = `${WAFCOMMON_ENDPOINT}/waf_rbr`;
        // console.log("Apply WAF:%j", this.state.applyWAF);
        
        let attempts = 0;
        let successAttempts = 0;
        let failureAttempts = 0;
        this.setState({
            currentFailureCounter: 0,
            currentFailureResponse: "-",
            currentSuccessCounter: 0,
            currentSuccessResponse: "-",
            currentFailureResponseHeaders: "-",
            reset: true
        });

        let requestHeaders = {};
        if (this.state.applyWAF?.checked) {
            requestHeaders.applywaf = "true";
        }
        let params = {action: "rbr"};
        if(this.state.rbrType === 'sourceIp') {
            if (!this.state.noOfAttempts) {
                this.setState({ alert: true, reset: false });
                return;
            }
            else {
                this.handleAlert();
            }
            url += '/source_ip'
        }
        else if (this.state.rbrType === 'dynamic') {
            if (!this.state.noOfAttempts) {
                this.setState({ alert: true, reset: false });
                return;
            }
            else if (!this.state.rbrSignature) {
                this.setState({ alert: true, reset: false });
                return;
            }
            else if (this.state.addlParam1.show && !this.state.addlParam1.value) {
                this.setState({ alert: true, reset: false });
                return;
            }
            else if (this.state.addlParam2.show && !this.state.addlParam2.value) {
                this.setState({ alert: true, reset: false });
                return;
            }
            else {
                this.handleAlert();
            }

            let rbrSignatureValue = this.state.rbrSignature.value;            
            url += '/' + rbrSignatureValue;

            if (rbrSignatureValue === 'ip_header') {
                requestHeaders['X-Forwarded-For'] = this.state.addlParam1.value;
            }
            else if (rbrSignatureValue === 'custom_querystring') {
                params[this.state.addlParam1.value] = this.state.addlParam2.value;
            }
            else if (rbrSignatureValue === 'custom_path') {
                url += this.state.addlParam1.value;
            }
            else if (rbrSignatureValue === 'custom_queryarg') {
                params.arg1 = this.state.addlParam1.value;
            }
            else if (rbrSignatureValue === 'custom_multiple') {
                requestHeaders['X-Forwarded-For'] = this.state.addlParam1.value;
                params.arg1 = this.state.addlParam2.value;
            }
        }

        let intervalCFImages = setInterval(() => {
            if (attempts < this.state.noOfAttempts.value && this.state.reset) {
        
                attempts++;
                this.dataProvider.getWAFCommonData(params, requestHeaders, url, response => {
                    this.setState({ result: response });
                    console.log("Response :%j", response);
                    if (response.response.error) {
                        failureAttempts++;
                        this.setState({
                            currentFailureCounter: failureAttempts,
                            currentFailureResponse: response.response,
                            currentFailureResponseHeaders: response.responseHeaders,
                        });
                    }
                    else {
                        successAttempts++;
                        this.setState({
                            currentSuccessCounter: successAttempts,
                            currentSuccessResponse: response.response
                        });
                    }
                }, this.dataProvider);
                this.setState({ currentCounter: attempts });
            }
            else {
                this.setState({reset: false});
                clearInterval(intervalCFImages);
            }
        }, 300);
    }

    handleAlert() {
        // console.log("In handleAlert");
        this.setState({ alert: false });
    }
    componentDidCatch(error, info) {
        console.log(error);
        // cwr('recordError', error);
        sendError({ demo: demoModule, error: error });
    };

    componentDidMount() {
        // this.dataProvider.getData('images', response => this.setState({ images: response }));
    }

    render() {
        return (
            <React.Fragment>
                <TopPanel label={demoModule} />
                <AppLayout
                    navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
                    // breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
                    content={
                        <Content
                            // Changes the Help panel content when the user clicks an 'info' link
                            replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
                            handleClick={this.handleClick}
                            handleAlert={this.handleAlert}
                            onInputChange={this.onInputChange}
                            currentState={this.state}
                        />
                    }
                    contentType="default"
                    tools={Tools[this.state.toolsIndex]}
                    onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
                    toolsOpen={this.state.toolsOpen}
                    navigationOpen={this.state?.navigationState}
                    onNavigationChange={e => this.setState({ navigationState: e.detail.open })}
                />
            </React.Fragment>
        );
    }
};

const SourceIPRBRPanel = props => {
    return (
        <SpaceBetween direction='horizontal' size='l'>
            <FormField label="# Attempts" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(4)}>
                    Info
                </Link>
            }>
                <Select
                    selectedOption={props.currentState?.noOfAttempts}
                    onChange={props.onInputChange.bind(this, 'noOfAttempts')}
                    options={[
                        { label: "50", value: "50" },
                        { label: "100", value: "100" },
                        { label: "150", value: "150" },
                        { label: "200", value: "200" },
                    ]}
                    selectedAriaLabel="Selected"
                />
            </FormField>
            <FormField label="Apply WAF" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(2)}>
                    Info
                </Link>
            }>
                <Checkbox
                    onChange={props.onInputChange.bind(this, 'applyWAF')}
                    checked={props.currentState?.applyWAF?.checked}
                />
            </FormField>
        </SpaceBetween>
    );
};

const DynamicRBRPanel = props => {
    return (
        <SpaceBetween direction='horizontal' size='l'>
            <FormField label="Signature" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(1)}>
                    Info
                </Link>
            }>
                <Select
                    selectedOption={props.currentState?.rbrSignature}
                    onChange={props.onInputChange.bind(this, 'rbrSignature')}
                    options={[
                        { label: "IP Header", value: "ip_header" },
                        { label: "Count All", value: "count" },
                        { label: "Query string", value: "custom_querystring" },
                        { label: "Path", value: "custom_path" },
                        { label: "Query Arg", value: "custom_queryarg" },
                        { label: "Multiple", value: "custom_multiple" },
                    ]}
                    selectedAriaLabel="Selected"
                />
            </FormField>
            <FormField label="# Attempts" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(4)}>
                    Info
                </Link>
            }>
                <Select
                    selectedOption={props.currentState?.noOfAttempts}
                    onChange={props.onInputChange.bind(this, 'noOfAttempts')}
                    options={[
                        { label: "50", value: "50" },
                        { label: "100", value: "100" },
                        { label: "150", value: "150" },
                        { label: "200", value: "200" },
                    ]}
                    selectedAriaLabel="Selected"
                />
            </FormField>
            { props.currentState.addlParam1.show && 
                <FormField label={props.currentState.addlParam1.label} info={
                    <Link variant="info" onFollow={() => props.replaceToolsContent(6)}>
                        Info
                    </Link>
                }>
                <Input inputMode='text' className='large_input_width'
                    onChange={props.onInputChange.bind(this, 'param1')}
                    value={props.currentState.addlParam1.value}
                    placeholder={props.currentState.addlParam1.placeholder}
                />

                </FormField>
            }
            { props.currentState.addlParam2.show && 
                <FormField label={props.currentState.addlParam2.label} info={
                    <Link variant="info" onFollow={() => props.replaceToolsContent(6)}>
                        Info
                    </Link>
                }>
            
                <Input inputMode='text' className='large_input_width'
                    onChange={props.onInputChange.bind(this, 'param2')}
                    value={props.currentState.addlParam2.value}
                    placeholder={props.currentState.addlParam2.placeholder}
                />

                </FormField>
            }
            <FormField label="Apply WAF" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(2)}>
                    Info
                </Link>
            }>
                <Checkbox
                    onChange={props.onInputChange.bind(this, 'applyWAF')}
                    checked={props.currentState?.applyWAF?.checked}
                />
            </FormField>
        </SpaceBetween>
    );
};


const ResultPanel = props => {
    if (props.currentState?.currentCounter) {
        return (
            <ColumnLayout columns="3" variant='text-grid'>
                <ProgressBar
                    value={props.currentState.currentCounter * 100 / props.currentState.noOfAttempts.value}
                    variant={'standalone'}
                    additionalInfo={`blocked ${Math.round(props.currentState.currentFailureCounter * 100 / props.currentState.noOfAttempts.value)} %`}
                    description={`# Attempts :${props.currentState.currentCounter}`}
                // label="Progress bar label"
                />
                <SpaceBetween direction='vertical' size='l'>
                    <ValueWithLabel label="Last Success Response">
                        {JSON.stringify(props.currentState.currentSuccessResponse, null, 2)}
                    </ValueWithLabel>
                    <ValueWithLabel label="Allowed Requests">
                        <Badge color='green'>{props.currentState.currentSuccessCounter}</Badge>
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween direction='vertical' size='l'>
                    <ValueWithLabel label="Last Failure Response">
                        {JSON.stringify(props.currentState.currentFailureResponse, null, 2)}
                    </ValueWithLabel>
                    <ValueWithLabel label="Response Headers">
                        {JSON.stringify(props.currentState.currentFailureResponseHeaders, null, 2)}
                    </ValueWithLabel>
                    <ValueWithLabel label="Blocked Requests">
                        <Badge color='red'>{props.currentState.currentFailureCounter}</Badge>
                    </ValueWithLabel>
                </SpaceBetween>
            </ColumnLayout>
        )
    } else {
        return ("");
    }
};

const ValueWithLabel = ({ label, children }) => (
    <React.Fragment>
        <Box variant="awsui-key-label">{label}</Box>
        <Box>{children !== "NaN" && children}</Box>
    </React.Fragment>
);
const Content = props => {

    return (
        <React.Fragment>
            <BrowserCheck />
            <SpaceBetween direction='vertical' size='l'>
                <ExtendingPanel label="Synopsis">
                    In this demo, you can show how WAF Rate based rules can be used to
                    block request floods. The threshold set is 100
                </ExtendingPanel>
                <Container>
                    <form onSubmit={props.handleClick}>
                        <Form
                            actions={
                                <SpaceBetween direction="vertical" size="xs">
                                    {props.currentState.alert && <Alert type='error'
                                        // visible={props.currentState.alert}
                                        onDismiss={() => props.handleAlert()}
                                        // dismissAriaLabel={<Box variant='strong'>Close alert</Box>}
                                        dismissible
                                    // header={<Box variant='p'>Select AWS Region</Box>}
                                    >
                                        <Box variant='small'>Enter required inputs and hit 'Fetch'</Box>
                                    </Alert>}
                                    <StatusIndicator type='info' wrapText='true' dismissible='true'>
                                        {/* <Box variant='p'> */}
                                        It may take couple of minutes and iterations for the RBR rule to kick in.
                                        Current threshold : 100
                                        {/* </Box> */}
                                    </StatusIndicator>
                                    {props.currentState.rbrSignature.value === 'count' 
                                    && props.currentState.rbrType === 'dynamic'
                                    && <StatusIndicator type='info' wrapText='true' dismissible='true'>
                                        {/* <Box variant='p'> */}
                                        Count All requests may be blocked if a different user is using this signature 
                                        at same time. This is expected as Count All aggregates requests across users.
                                        {/* </Box> */}
                                    </StatusIndicator>}
                                    <Button variant="primary">{props.currentState.reset ? 'Reset' : 'Fetch'}</Button>
                                </SpaceBetween>
                            }
                        >

                            <Tabs
                                onChange={props.onInputChange.bind(this, 'rbrType')}
                                activeTabId={props.currentState.rbrType}
                                tabs={[
                                    {
                                        label: <TabLabel label="Quick (Source IP)" />,
                                        id: "sourceIp",
                                        content: <SourceIPRBRPanel currentState={props.currentState} onInputChange={props.onInputChange} replaceToolsContent={props.replaceToolsContent} />
                                    },
                                    {
                                        label: <TabLabel label="Advanced Config (Dynamic Aggregations)" />,
                                        id: "dynamic",
                                        content: <DynamicRBRPanel currentState={props.currentState} onInputChange={props.onInputChange} replaceToolsContent={props.replaceToolsContent} />
                                    },
                                ]}
                            />

                            
                        </Form>
                    </form>
                </Container>
                <Container header=<Box variant='h4'>Result</Box>>
                    <ResultPanel currentState={props.currentState} />
                </Container>
                <ExtendingPanel label="Extend it">
                    <ColumnLayout columns={2} variant='text-grid' className='extend_it'>
                        <Box>Questions/ideas on the demo?</Box>
                        <SpaceBetween direction='vertical'>
                            <Box variant='strong'>
                                Kiruthi Jayachandran,
                                kiruthij@amazon.com
                            </Box>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExtendingPanel>
            </SpaceBetween>
        </React.Fragment >
    );
};

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
    <HelpPanel
        header={<h3>WAF</h3>}
        footer={
            <div>
                <h3>
                    Learn more <Icon name="external" />
                </h3>
                {/* <ul>
          <li>
            <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/private-content-signed-urls.html" target="_blank">
              Using SignedURLs in Amazon CloudFront?
            </a>
          </li>
        </ul> */}
            </div>
        }
        index="0">
        <p>
            MODULE NAME
        </p>
    </HelpPanel >,
    <HelpPanel header={<h2>Apply On</h2>} index="1">
        Choose the request aggregation type to apply the attack signature
    </HelpPanel>,
    <HelpPanel header={<h2>Apply WAF</h2>} index="2">
        Apply WAF protection on the request
    </HelpPanel>,
    <HelpPanel header={<h2>Record ID</h2>} index="3">
        ID of the record to fetch from database
    </HelpPanel>,
    <HelpPanel header={<h2># Attempts</h2>} index="4">
        No. of requests made by your browser
    </HelpPanel>,
    <HelpPanel header={<h2>Request Type</h2>} index="5">
        Whether to use GET (default) or POST request method.
    </HelpPanel>,
    <HelpPanel header={<h2>Request Type</h2>} index="6">
        Additional parameters for aggregating the requests
        eg: Query String, X-Forwarded-For header, URL path, etc
    </HelpPanel>,
];