import React from 'react';
import DataProvider from '../../resources/data-provider';
import ServiceNavigation from '../ServiceNavigation.jsx';
import ExtendingPanel from '../ExtendingPanel.jsx';
import SynopsisPanelV2 from '../SynopsisPanelV2';
import BrowserCheck from '../BrowserCheck';
import TopPanel from '../TopPanel';
import architectureReqImg from './images/customrequest-architecture.png';
import architectureResImg from './images/customresponse-architecture.png';
import { syntaxHighlight } from '../common/util.jsx';

import {
    WAFCOMMON_ENDPOINT,
} from '../../resources/prod-env.jsx';
import {
    TabLabel,
} from '../common';
import {
    AppLayout,
    Button,
    ColumnLayout,
    Form,
    FormField,
    Select,
    SpaceBetween,
    Container,
    Link,
    HelpPanel,
    Box,
    Icon,
    Alert,
    Checkbox,
    ProgressBar,
    Badge,
    StatusIndicator,
    Tabs,
    Input,
} from '@cloudscape-design/components';

import '../../styles/form.scss';
import { sendAnalytics, sendError } from '../../resources/rum-provider';
const demoModule = "Custom Request/Response";

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class WAFCustomReqResp extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleAlert = this.handleAlert.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.dataProvider = new DataProvider();
        this.state = {
            reqSignature: "", respSignature: "",
            toolsIndex: 0, toolsOpen: false, alert: false,
            wafType: "req",
            responseFrom: ""
        };
    }

    onInputChange(name, e) {
        e.preventDefault();
        if (name === "reqSignature") {
            this.setState({ [name]: e.detail.selectedOption });
        }
        else if (name === "respSignature") {
            this.setState({ [name]: e.detail.selectedOption })
        }
        else if (name === 'wafType') {
            this.setState({ [name]: e.detail.activeTabId });
            this.setState({ result: null });
        }
    }

    handleClick(e) {
        e.preventDefault();

        sendAnalytics({ demo: demoModule });
        this.backendCall(false);
        this.backendCall(true);
    }

    backendCall(isDefault) {
        let url = `${WAFCOMMON_ENDPOINT}`;
        
        this.setState({
            currentRequest: "-",
            currentResponse: "-",
            currentResponseHeaders: "-",
            currentResponseStatus: "-",
            defaultRequest: "-",
            defaultResponse: "-",
            defaultResponseStatus: "-"
        });

        let requestHeaders = {};
        requestHeaders.applywaf = "true";

        let params = {};
        if(this.state.wafType === 'req') {
            if (!this.state.reqSignature) {
                this.setState({ alert: true});
                return;
            }
            else {
                url += '/custom_request/' + this.state.reqSignature.value;
                this.handleAlert();
            }
            params.action = "custom_request";
            if(!isDefault) {
                params.type = "<script>hello</script>"
            }
            this.setState({responseFrom: 'Origin'});
        }
        else if (this.state.wafType === 'resp') {
            if (!this.state.respSignature) {
                this.setState({ alert: true });
                return;
            }
            else {
                if(isDefault) {
                    url += '/custom_response/default';
                } else {
                    url += '/custom_response/' + this.state.respSignature.value;
                }
                this.handleAlert();
            }
            this.setState({
                currentRequest: url
            });
            params.action = "rbr";
            params.type = "<script>hello</script>"
            this.setState({responseFrom: 'WAF'})
        }

        this.dataProvider.getWAFCommonData(params, requestHeaders, url, response => {
            this.setState({ result: response });
            console.log("Response :%j", response);
            if(response.status > 0) {
                if(isDefault) {
                    this.setState({
                        defaultResponse: response.response,
                        defaultResponseStatus: response.status,
                        defaultRequest: decodeURIComponent(response.url)
                    });
                } else {
                    this.setState({
                        currentResponse: response.response,
                        currentResponseHeaders: response.responseHeaders,
                        currentResponseStatus: response.status,
                        currentRequest: decodeURIComponent(response.url)
                    });
                }
            }
        }, this.dataProvider);
    }

    handleAlert() {
        this.setState({ alert: false });
    }

    componentDidCatch(error, info) {
        console.log(error);
        // cwr('recordError', error);
        sendError({ demo: demoModule, error: error });
    };

    componentDidMount() {
        // this.dataProvider.getData('images', response => this.setState({ images: response }));
    }

    render() {
        return (
            <React.Fragment>
                <TopPanel label={demoModule} />
                <AppLayout
                    navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
                    // breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
                    content={
                        <Content
                            // Changes the Help panel content when the user clicks an 'info' link
                            replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
                            handleClick={this.handleClick}
                            handleAlert={this.handleAlert}
                            onInputChange={this.onInputChange}
                            currentState={this.state}
                        />
                    }
                    contentType="default"
                    tools={Tools[this.state.toolsIndex]}
                    onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
                    toolsOpen={this.state.toolsOpen}
                    navigationOpen={this.state?.navigationState}
                    onNavigationChange={e => this.setState({ navigationState: e.detail.open })}
                />
            </React.Fragment>
        );
    }
};

const RequestPanel = props => {
    return (
        <SpaceBetween direction='horizontal' size='l'>
            <FormField label="Scenario" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(1)}>
                    Info
                </Link>
            }>
                <Select
                    selectedOption={props.currentState?.reqSignature}
                    onChange={props.onInputChange.bind(this, 'reqSignature')}
                    options={[
                        { label: "Response from Origin", value: "xss_filter" },
                    ]}
                    selectedAriaLabel="Selected"
                />
            </FormField>
        </SpaceBetween>
    );
};

const ResponsePanel = props => {
    return (
        <SpaceBetween direction='horizontal' size='l'>
            <FormField label="Custom Response Format" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(2)}>
                    Info
                </Link>
            }>
                <Select
                    selectedOption={props.currentState?.respSignature}
                    onChange={props.onInputChange.bind(this, 'respSignature')}
                    options={[
                        { label: "Json", value: "json" },
                        { label: "HTML", value: "html" },
                        { label: "Text", value: "text" },
                    ]}
                    selectedAriaLabel="Selected"
                />
            </FormField>
        </SpaceBetween>
    );
};

const ResultPanel = props => {
    
    if (props.currentState?.result) {
        return (
            <ColumnLayout columns="2" variant='text-grid'>
                <SpaceBetween direction='vertical' size='l'>
                    { props.currentState.wafType == "req" && <Box variant='h3'>Default Response from Origin</Box> }
                    { props.currentState.wafType == "resp" && <Box variant='h3'>Default Response from WAF</Box> }

                    <ValueWithLabel label="Request Payload">
                    { props.currentState.wafType == "req" && <p><i>Example request without any cross site script injection</i></p> }
                    { props.currentState.wafType == "resp" && <p><i>Example cross site script injection using query string parameter</i></p> }
                        {JSON.stringify(props.currentState.defaultRequest, null, 2)}
                    </ValueWithLabel>
                    <ValueWithLabel label={"Response from " + props.currentState.responseFrom }>
                        { props.currentState.wafType == "req" && <div><p><i>Origin returns all the request header values it receives in the response.</i></p> 
                        <pre style={{'white-space': 'pre-wrap'}}
                            dangerouslySetInnerHTML={{
                                __html: syntaxHighlight(JSON.stringify(props.currentState.defaultResponse, null, 2), '"x-amzn-waf-contains_xss": "true"')
                            }}
                        />
                        </div>
                        }
                        
                        { props.currentState.wafType == "resp" && 
                            <pre style={{'white-space': 'pre-wrap'}}>{JSON.stringify(props.currentState.defaultResponse, null, 2)}</pre>
                        }
                    </ValueWithLabel>
                    <ValueWithLabel label={"Response Status from " + props.currentState.responseFrom }>
                        {JSON.stringify(props.currentState.defaultResponseStatus, null, 2)}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween direction='vertical' size='l'>
                    { props.currentState.wafType == "req" && <Box variant='h3'>Custom Request Header Injection</Box> }
                    { props.currentState.wafType == "resp" && <Box variant='h3'>Custom Response from WAF</Box> }
                    <ValueWithLabel label="Request Payload">
                        <p><i>Example cross site script injection using query string parameter</i></p>
                        {JSON.stringify(props.currentState.currentRequest, null, 2)}
                    </ValueWithLabel>
                    <ValueWithLabel label={"Response from " + props.currentState.responseFrom }>
                        { props.currentState.wafType == "req" && <div><p><i>Origin returns all the request header values it receives in the response. <span dangerouslySetInnerHTML={{__html: syntaxHighlight('Header added by WAF is highlighted', 'highlighted')}} /></i></p> 
                        <pre style={{'white-space': 'pre-wrap'}}
                            dangerouslySetInnerHTML={{
                                __html: syntaxHighlight(JSON.stringify(props.currentState.currentResponse, null, 2), '"x-amzn-waf-contains_xss": "true"')
                            }}
                        />
                        </div>
                        }
                        { props.currentState.wafType == "resp" && 
                            <div>
                                <p><i>Modified response body returned by WAF </i></p> 
                                <pre style={{'white-space': 'pre-wrap'}}>{JSON.stringify(props.currentState.currentResponse, null, 2)}</pre>
                            </div>
                        }
                    </ValueWithLabel>
                    { props.currentState.currentResponseHeaders && <ValueWithLabel label={"Response Header from " + props.currentState.responseFrom }>
                        {JSON.stringify(props.currentState.currentResponseHeaders, null, 2)}
                    </ValueWithLabel>
                    }
                    <ValueWithLabel label={"Response Status from " + props.currentState.responseFrom }>
                        { props.currentState.wafType == "resp" && <p><i>Modified response status from WAF </i></p> }
                        {JSON.stringify(props.currentState.currentResponseStatus, null, 2)}
                    </ValueWithLabel>
                </SpaceBetween>
            </ColumnLayout>
        )
    }
};

const ValueWithLabel = ({ label, children }) => (
    <React.Fragment>
        <Box variant="awsui-key-label">{label}</Box>
        <Box>{children !== "NaN" && children}</Box>
    </React.Fragment>
);

const Content = props => {

    return (
        <React.Fragment>
            <BrowserCheck />
            <SpaceBetween direction='vertical' size='l'>
                <SynopsisPanelV2 label="Synopsis" architectureImgs={[architectureReqImg,architectureResImg]}>
                    This demo showcases custom web request and response handling behavior of AWS WAF.
                </SynopsisPanelV2>
                <Container>
                    <form onSubmit={props.handleClick}>
                        <Form
                            actions={
                                <SpaceBetween direction="vertical" size="xs">
                                    {props.currentState.alert && <Alert type='error'
                                        onDismiss={() => props.handleAlert()}
                                        dismissible
                                    >
                                        <Box variant='small'>Enter required inputs and hit 'Fetch'</Box>
                                    </Alert>}
                                    
                                    <Button variant="primary">Fetch</Button>
                                </SpaceBetween>
                            }
                        >

                            <Tabs
                                onChange={props.onInputChange.bind(this, 'wafType')}
                                activeTabId={props.currentState.wafType}
                                tabs={[
                                    {
                                        label: <TabLabel label="Request" />,
                                        id: "req",
                                        content: <RequestPanel currentState={props.currentState} onInputChange={props.onInputChange} replaceToolsContent={props.replaceToolsContent} />
                                    },
                                    {
                                        label: <TabLabel label="Response" />,
                                        id: "resp",
                                        content: <ResponsePanel currentState={props.currentState} onInputChange={props.onInputChange} replaceToolsContent={props.replaceToolsContent} />
                                    },
                                ]}
                            />
                            
                        </Form>
                    </form>
                </Container>
                <Container header=<Box variant='h4'>Result</Box>>
                    <ResultPanel currentState={props.currentState} />
                </Container>
                <ExtendingPanel label="Extend it">
                    <ColumnLayout columns={2} variant='text-grid' className='extend_it'>
                        <Box>Questions/ideas on the demo?</Box>
                        <SpaceBetween direction='vertical'>
                            <Box variant='strong'>
                                Kiruthi Jayachandran, kiruthij@amazon.com
                            </Box>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExtendingPanel>
            </SpaceBetween>
        </React.Fragment >
    );
};

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
    <HelpPanel
        header={<h3>Custom Request / Response</h3>}
        footer={
            <div>
                <h3>
                    Learn more <Icon name="external" />
                </h3>
                {/* <ul>
          <li>
            <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/private-content-signed-urls.html" target="_blank">
              Using SignedURLs in Amazon CloudFront?
            </a>
          </li>
        </ul> */}
            </div>
        }
        index="0">
        <p>
            MODULE NAME
        </p>
    </HelpPanel >,
    <HelpPanel header={<h2>Scenario</h2>} index="1">
        AWS WAF can insert custom headers into the original HTTP request when a rule action doesn't block the request
    </HelpPanel>,
    <HelpPanel header={<h2>Custom Response Format</h2>} index="2">
        Select the content type of custom response that should be returned by WAF. AWS WAF can send a custom HTTP response back to the client for rule actions that are set to Block
    </HelpPanel>,
];
