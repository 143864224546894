import React from 'react';
import DataProvider from '../../resources/data-provider';
import ServiceNavigation from '../ServiceNavigation.jsx';
import ExtendingPanel from '../ExtendingPanel.jsx';
import BrowserCheck from '../BrowserCheck';
import TopPanel from '../TopPanel';

import {
    AppLayout,
    ColumnLayout,
    SpaceBetween,
    Container,
    HelpPanel,
    Box,
    Icon,
    Flashbar,
} from '@cloudscape-design/components';

import '../../styles/form.scss';
// function cwr(operation, payload) { };
import { sendAnalytics, sendError } from '../../resources/rum-provider.ts';
const demoModule = "WAFCAPTCHA";

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class WAFCaptcha extends React.Component {

    constructor(props) {
        super(props);
        this.handleAlert = this.handleAlert.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.dataProvider = new DataProvider();
        this.state = {
            toolsIndex: 0, toolsOpen: false, alert: false,
            noOfAttempts: "", currentCounter: 0, applyWAF: { checked: false },
            requestType: { checked: false },
        };
    }

    onInputChange(name, e) {
        e.preventDefault();
        console.log("In onInputChange %s %j", name, e);
        if (name === "noOfAttempts") {
            this.setState({ [name]: e.detail.selectedOption });
            return;
        }
        else if (name === 'applyWAF') {
            this.setState({ [name]: e.detail });
        }
    }

    handleAlert() {
        // console.log("In handleAlert");
        this.setState({ alert: false });
    }
    componentDidCatch(error, info) {
        console.log(error);
        // cwr('recordError', error);
        sendError({ demo: demoModule, error: error });
    };

    componentDidMount() {
        // this.dataProvider.getData('images', response => this.setState({ images: response }));
    }

    render() {
        return (
            <React.Fragment>
                <TopPanel label={demoModule} />
                <AppLayout
                    navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
                    // breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
                    content={
                        <Content
                            // Changes the Help panel content when the user clicks an 'info' link
                            replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
                            handleAlert={this.handleAlert}
                            onInputChange={this.onInputChange}
                            currentState={this.state}
                        />
                    }
                    contentType="default"
                    tools={Tools[this.state.toolsIndex]}
                    onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
                    toolsOpen={this.state.toolsOpen}
                    navigationOpen={this.state?.navigationState}
                    onNavigationChange={e => this.setState({ navigationState: e.detail.open })}
                />
            </React.Fragment>
        );
    }
};

const Content = props => {

    return (
        <React.Fragment>
            <BrowserCheck />
            <SpaceBetween direction='vertical' size='l'>
                <ExtendingPanel label="Synopsis">
                    In this demo, you can show how WAF CAPTCHA works.
                </ExtendingPanel>
                <Container>
                    <Flashbar items={[{
                        type: "success",
                        content: "If you are seeing this page, you have solved a CAPTCHA puzzle. To get the CAPTCHA challenge again you will have to wait for until the immunity time has passed (set to 60 seconds)"
                    }]} />
                </Container>
                <ExtendingPanel label="Extend it">
                    <ColumnLayout columns={2} variant='text-grid' className='extend_it'>
                        <Box>Questions/ideas on the demo?</Box>
                        <SpaceBetween direction='vertical'>
                            <Box variant='strong'>
                                Jaiganesh Girinathan,
                                ganeshji@amazon.com
                            </Box>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExtendingPanel>
            </SpaceBetween>
        </React.Fragment >
    );
};

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
    <HelpPanel
        header={<h3>ATP</h3>}
        footer={
            <div>
                <h3>
                    Learn more <Icon name="external" />
                </h3>
                {/* <ul>
          <li>
            <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/private-content-signed-urls.html" target="_blank">
              Using SignedURLs in Amazon CloudFront?
            </a>
          </li>
        </ul> */}
            </div>
        }
        index="0">
        <p>
            MODULE NAME
        </p>
    </HelpPanel >,
    <HelpPanel header={<h2>Apply On</h2>} index="1">
        Choose the request attribute to apply the attack signature
    </HelpPanel>,
    <HelpPanel header={<h2>Apply WAF</h2>} index="2">
        Apply WAF protection on the request
    </HelpPanel>,
    <HelpPanel header={<h2>Record ID</h2>} index="3">
        ID of the record to fetch from database
    </HelpPanel>,
    <HelpPanel header={<h2># Attempts</h2>} index="4">
        No. of requests made by your browser
    </HelpPanel>,
    <HelpPanel header={<h2>Request Type</h2>} index="5">
        Whether to use GET (default) or POST request method.
    </HelpPanel>,
];