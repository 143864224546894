/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

  https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import DataProvider from '../../resources/data-provider';
import ServiceNavigation from '../ServiceNavigation.jsx';
import Breadcrumbs from '../Breadcrumbs.jsx';
import Timer from '../Timer.jsx';
import ResponseHeaders from '../ResponseHeaders.jsx';
import ExtendingPanel from '../ExtendingPanel.jsx';
import image1 from '../../resources/images/image1.jpeg'
import image2 from '../../resources/images/image2.jpeg'
import image3 from '../../resources/images/image3.jpeg'
import image4 from '../../resources/images/image4.jpeg'
import amazonLogo from '../../resources/logos/Amazon_logo.png'
import awsLogo from '../../resources/logos/AWS_logo.png'

import {
  ALLOWED_HTTP_METHOD_OPTIONS,
  COOKIE_OPTIONS,
  CURRENT_COMPRESSION_OPTIONS,
  FORWARD_HEADER_OPTIONS,
  PRICE_CLASS_OPTIONS,
  QUERY_STRING_OPTIONS,
  SSL_CERTIFICATE_OPTIONS,
  SUPPORTED_HTTP_VERSIONS_OPTIONS,
  VIEWER_PROTOCOL_POLICY_OPTIONS,
  DELIVERY_METHOD,
  SIGNEDURL_MODES
} from '../../resources/form-config.jsx';
import {
  AppLayout,
  AttributeEditor,
  BreadcrumbGroup,
  Button,
  Badge,
  Checkbox,
  ColumnLayout,
  ExpandableSection,
  Form,
  FormField,
  Grid,
  Input,
  Multiselect,
  RadioGroup,
  Select,
  Textarea,
  Tiles,
  Tabs,
  Header,
  SpaceBetween,
  Container,
  Link,
  HelpPanel,
  Box,
  Icon
} from '@cloudscape-design//components';
import '../../styles/form.scss';

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class LambdaAtEdge extends React.Component {

  constructor(props) {
    super(props);
    this.state = { toolsIndex: 0, toolsOpen: false, ttl: 8, uri:"/watermark", image:{value: 'item1'}, logo:{value: 'logo1'}, watermarkedImageURL : {value: ""} };
    this.handleClick = this.handleClick.bind(this);
    this.dataProvider = new DataProvider();//dataProvider.bind(this);
  }
  componentDidMount() {
    //dataProvider.getData('content-origins', contentOrigins => this.setState({ contentOrigins: contentOrigins }));
  }

  handleClick(e) {
    e.preventDefault();
    //console.log("State :%j",this.state);
    console.log(this.state)
    this.createWatermakedImage(e)
  }

  createWatermakedImage(e){
    this.dataProvider.getWatermarkedImage({image:this.state.image, logo: this.state.logo},response => {
          console.log(response)
          //console.log(URL.createObjectURL(response));
          //var blobObj = new Blob(response, {type: "image/jpg"})
          //this.state.watermarkedImageURL = URL.createObjectURL(blobObj)
          //const imageObjectURL = URL.createObjectURL(response)
          const wObjectUrl = URL.createObjectURL(response)
          this.setState({watermarkedImageURL: {
            value: wObjectUrl
            }
            })
          console.log( this.state.watermarkedImageURL)
        },this.dataProvider);
  }

  render() {
    return (
      <AppLayout
        navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
        breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
        content={
          <Content
            // Changes the Help panel content when the user clicks an 'info' link
            replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
            handleClick={this.handleClick}
            currentState={this.state}
            update={this.update}
          />
        }
        contentType="default"
      />
    );
  }
}


// The content in the main content area of the App layout
const Content = props => {

  const [
      selectedOption, setSelectedOption,
    ] = React.useState({
      label: "Item 1",
      value: "item1"
    }
    )

    const [selectedLogo, setSelectedLogo] = React.useState(
      {
        label: "Logo 1",
        value: "logo1"
      }
    )

  return (<div>
    <ExtendingPanel label="Synopsis">
    But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"
    </ExtendingPanel>
    <SpaceBetween direction="vertical" size="l">

    <Container header={<Header variant="h2" info={
           <Link variant="info" onFollow={() => props.replaceToolsContent(0)}>
             Info
           </Link>
         }>Choose Image</Header>}>
           <SpaceBetween size="l">
            <Tiles
      onChange={({ detail }) => {
        console.log(detail)
        props.currentState.image = detail.value;
        setSelectedOption(detail.value)}
      }
      value={selectedOption}
      columns={3}
      items={[
        {
          label: "Item 1",
          image: (
            <img
              src={image1}
              alt="image1"
              width="100"
              height="125"
            />
          ),
          value: "image1_watermark.jpeg"
        },
        {
          label: "Item 2",
          image: (
            <img
              src={image2}
              alt="placeholder"
              width="100"
              height="125"
            />
          ),
          value: "image2_watermark.jpeg"
        },
        {
          label: "Item 3",
          image: (
            <img
              src={image4}
              alt="placeholder"
              width="100"
              height="125"
            />
          ),
          value: "image4_watermark.jpeg"
        }
      ]}
    />

           </SpaceBetween>
         </Container>

    <Container header={<Header variant="h2" info={
           <Link variant="info" onFollow={() => props.replaceToolsContent(0)}>
             Info
           </Link>
         }>Choose Watermark</Header>}>
           <SpaceBetween size="l">
           <form onSubmit={props.handleClick}>
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="primary">Test</Button>
                </SpaceBetween>
              }
            >
            <Tiles
      onChange={({ detail }) => {
        console.log(detail)
        props.currentState.logo = detail.value;
        setSelectedLogo(detail.value)
      }
      }
      value={selectedLogo}
      columns={3}
      items={[
        {
          image: (
            <img
              src={amazonLogo}
              alt="image1"
              width="200"
              height="60"
            />
          ),
          value: "Amazon_logo.png"
        },
        {
          image: (
            <img
              src={awsLogo}
              alt="placeholder"
              width="100"
              height="60"
            />
          ),
          value: "AWS_logo.png"
        }
      ]}
    />
           </Form>
           </form>
           </SpaceBetween>
         </Container>


    <Container header={<Header variant="h2">Watermarked Image</Header>}>
      <img
              src={props.currentState.watermarkedImageURL.value}
              alt=""
            />


    </Container>
    </SpaceBetween>
    </div>
    );
};

// Breadcrumb content
const BreadcrumbsItems =
[
  {
    text: 'CloudFront',
    href: '#/index'
  },
  {
    text: 'Images',
    href: '#/images'
  }
];
