import React, { useContext } from 'react';
import DataProvider from '../../resources/data-provider';
import ServiceNavigation from '../ServiceNavigation.jsx';
import Breadcrumbs from '../Breadcrumbs.jsx';
import CFTableTools from '../CFTableTools.jsx';
import ExtendingPanel from '../ExtendingPanel.jsx';
import StatsPanel from '../StatsPanel.jsx';
import prettyBytes from 'pretty-bytes';
import TopPanel from '../TopPanel';
import BottomPanel from '../BottomPanel';
import { Clock, DollarSign, DownloadCloud, MapPin, BarChart2, Hash, UploadCloud, TrendingUp } from 'react-feather';
import { GlobalStateContext, GlobalDispatchContext, NAVIGATION } from '../Global';
import calculatePercentile from '../common/percentile';
import {
    NO_ATTEMPTS,
    NO_ATTEMPTS_LARGEFILES,
    PERFORMANCE_ENTRIES,
} from '../../resources/dev-env.jsx';
import {
    TOTAL_TIMEP90,
    TOTAL_TIMEP50,
    AGE,
    SSL_TIMEP50,
    SSL_TIMEP90,
    DTO_SAVINGS,
} from '../../resources/form-config.jsx';
import {
    TabLabel,
} from '../common';
import {
    AppLayout,
    Button,
    Badge,
    ColumnLayout,
    Form,
    FormField,
    Select,
    Table,
    Tabs,
    Grid,
    Header,
    SpaceBetween,
    Container,
    Link,
    HelpPanel,
    Box,
    Icon,
    ProgressBar,
    LineChart,
    Alert,
    AreaChart,
    PieChart,
    Popover,
    Input,
    Checkbox,
    RadioGroup,
    Toggle,
    ExpandableSection,
    Flashbar
} from '@cloudscape-design/components';

import '../../styles/form.scss';
import BrowserCheck from '../BrowserCheck';
function cwr(operation, payload) { };

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class AGAcceleration extends React.Component {
    static context = GlobalStateContext;
    static dispatch = GlobalDispatchContext;

    constructor(props) {
        super(props);
        // console.log(performance.now());
        // console.log("Global state ", this.context);

        this.state = {
            toolsIndex: 0, toolsOpen: false, alert: false,
            totalBytes: { total: 0, cloudfront: 0, apis: 0, s3: 0 },
            noOfRequests: { total: 0, cloudfront: 0, apis: 0, s3: 0 },
            throughput: { total: 0, cloudfront: 0, apis: 0, s3: 0 },
            CFPricing: [],
            cfstatic: [], cfdynamic: [], mode: 'cfstatic', cfstatic_stats: [],
            cfdynamic_stats: [], direct_stats: [],
            CFLargeFiles: [], DirectLargeFiles: [],
            CFImages: [], DirectImages: [],
            CFAPIsGET: [], DirectAPIsGET: [],
            CFAPIsPOST: [], DirectAPIsPOST: [],
            CFCSSJavascript: [], DirectCSSJavascript: [],
            DirectImagesSpinner: false, DirectAPIsSpinner: false,
            DirectCSSJavascriptSpinner: false, DirectAPIsPOSTSpinner: false,
            CFImagesResponses: [], DirectImagesResponses: [],
            CFCSSJavascriptResponses: [], DirectCSSJavascriptResponses: [],
            // CFAPIsGETResponses: [], DirectAPIsGETResponses: [],
            CFImagesInfo: [], DirectImagesInfo: [],
            CFCSSJavascriptInfo: [], DirectCSSJavascriptInfo: [],
            CFAPIsGETInfo: [], DirectAPIsGETInfo: [],
            modes: ["APIsGET", "APIsPOST"],
            // modes: ["APIsPOST"],
            pop: '', cfprotocol: '-', s3protocol: '-', apiprotocol: '-',
            compression: '-', age: '-',
            CFTSAPIsPOST: {
                rttfb: [], sthupttfb: [], sthupdns: [], sthupconnect: [],
                sthdownttfb: [], rtssl: [],
            }, dto: 10, dtounit: { label: "TB/month", value: "tb" },
            dtocountry: { label: "United States", value: "United States" },
            numberFormatScale2: new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 2,
                // maximumSignificantDigits: 3,

            }),
            numberFormatScale4: new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 4,
                // maximumSignificantDigits: 3,

            }),
            compressiblepercent: 70,
            columnDefinitions1:
                [
                    {
                        id: "asset",
                        header: <Box variant='code'>Asset</Box>,
                        cell: item => item.link || "-",
                    },
                    {
                        id: "durationp50",
                        header: <Box variant='small'>{TOTAL_TIMEP50}</Box>,
                        cell: item => item.durationp50 || "-",
                    },
                    {
                        id: "durationp90",
                        header: <Box variant='small'>{TOTAL_TIMEP90}</Box>,
                        cell: item => item.durationp90 || "-",
                    },
                    {
                        id: "size",
                        header: <Box variant='small'>Size</Box>,
                        cell: item => item.size || "-",
                    },
                    {
                        id: "age",
                        header: <Box variant='small'>{AGE}</Box>,
                        cell: item => item.age || "-",
                    },
                ],
            columnDefinitions2:
                [
                    {
                        id: "asset",
                        header: <Box variant='small'>Asset</Box>,
                        cell: item => item.link || "-",
                    },
                    {
                        id: "durationp50",
                        header: <Box variant='small'>{TOTAL_TIMEP50}</Box>,
                        cell: item => item.durationp50 || "-",
                    },
                    {
                        id: "durationp90",
                        header: <Box variant='small'>{TOTAL_TIMEP90}</Box>,
                        cell: item => item.durationp90 || "-",
                    },

                    {
                        id: "size",
                        header: <Box variant='small'>Size</Box>,
                        cell: item => item.size || "-",
                    },
                    {
                        id: "compression",
                        header: <Box variant='small'>Compression</Box>,
                        cell: item => item.compression || "-",
                    },
                    {
                        id: "age",
                        header: <Box variant='small'>{AGE}</Box>,
                        cell: item => item.age || "-",
                    },
                ],
            columnDefinitions3:
                [
                    {
                        id: "asset",
                        header: <Box variant='small'>Asset</Box>,
                        cell: item => item.link || "-",
                    },
                    // {
                    //   id: "size",
                    //   header: <Box variant='small'>Size</Box>,
                    //   cell: item => item.size || "-",
                    // },
                    // {
                    //   id: "age",
                    //   header: <Box variant='small'>{AGE}</Box>,
                    //   cell: item => item.age || "-",
                    // },
                    {
                        id: "durationp50",
                        header: <Box variant='small'>{TOTAL_TIMEP50}</Box>,
                        cell: item => item.durationp50 || "-",
                    },
                    {
                        id: "durationp90",
                        header: <Box variant='small'>{TOTAL_TIMEP90}</Box>,
                        cell: item => item.durationp90 || "-",
                    },
                    {
                        id: "ssltimep50",
                        header: <Box variant='small'>{SSL_TIMEP50}</Box>,
                        cell: item => item.ssltimep50 || "-",
                    },
                    {
                        id: "ssltimep90",
                        header: <Box variant='small'>
                            {SSL_TIMEP90}</Box>,
                        cell: item => item.ssltimep90 || "-",
                    },
                ],
            navigationState: true,
        };
        this.handleClick = this.handleClick.bind(this);
        this.handlePricing = this.handlePricing.bind(this);
        this.handleAlert = this.handleAlert.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleRegionSelection = this.handleRegionSelection.bind(this);
        this.dataProvider = new DataProvider();
        this.items = [];
        this.urls = [];
        this.noOfRequests = { total: 0, cloudfront: 0, apis: 0, s3: 0 };
        this.throughput = { total: 0, cloudfront: 0, apis: 0, s3: 0 };
        this.totalBytes = { total: 0, cloudfront: 0, apis: 0, s3: 0 };
        // this.state.modes.forEach(mode => this.items[`CF${mode}`] = []);
        // this.state.modes.forEach(mode => this.items[`Direct${mode}`] = []);
        performance.setResourceTimingBufferSize(PERFORMANCE_ENTRIES);
        // const pageTiming = performance.getEntriesByType('navigation');
        // console.log(performance.now());
    }

    componentDidCatch(error, info) {
        console.log(error);
        cwr('recordError', error);
    };

    componentDidMount() {
        this.dataProvider.getData('agacceleration-endpoints', response => this.setState({ contentRegions: response }));
        this.dataProvider.getData('countries', response => this.setState({ countries: response }));
        this.dataProvider.getData('dtounit', response => this.setState({ dtounitoptions: response }));
    }

    onInputChange(name, e) {
        e.preventDefault();
        // console.log("In onInputChange %s %j", name, e);
        if (name == 'dtounit' || name == 'dtocountry') {
            this.setState({ [name]: e.detail.selectedOption })
        }
        else if (name == 'compressible' || name == 'cfssb' || name == 'enablepricing') {
            this.setState({ [name]: e.detail });
        }
        else if (name == 'compareprice' || name == 'togglePricing') {
            this.setState({ [name]: e.detail });
        }
        else {
            this.setState({
                [name]: e.detail.value
            });
        }
    }

    resetCounters(withCache, direct) {
        if (direct) {
            this.setState({ direct_stats: [] });
        }
        else if (withCache) {
            this.setState({ cfstatic_stats: [] });
        }
        else {
            this.setState({ cfdynamic_stats: [] });
        }
    }

    handleRegionSelection(e) {
        // console.log("Region selected ", e.value);
        this.setState({ selectedRegion: e });
        performance.clearResourceTimings();
    }

    refreshCFPanel(mode, resolve) {
        // this.state = {cfstatic:[],cfdynamic:[],mode:e.target[0].value};
        // console.log("in refreshCFPanel ", mode);

        // this.setState({ mode: [] });
        let regionEndpoints = this.state.selectedRegion.value;
        let endpoints = regionEndpoints[mode];
        //CF link
        let url = this.state[[`${mode}Links`]][0];
        let bucket = this.state[[`${mode}Links`]][2];
        // this.state[[`${mode}Links`]] = url;
        let attempts = 0;
        //clear the previous responses
        this.setState({ [`CF${mode}Responses`]: [] });

        // this.dataProvider.getCFData({}, url, response => { }, this.dataProvider);
        // performance.clearMarks('beginSquareRootLoop');
        // performance.clearMarks('endSquareRootLoop');
        // performance.clearMeasures('measureSquareRootLoop');
        this.items[`CF${mode}`] = [];
        this.items[`CF${mode}`].push(url);
        this.urls[url] = {};
        let beginLabel = `beginCF${mode}`;
        let endLabel = `endCF${mode}`;
        this.urls[url]['response'] = [];

        let invocationCount = NO_ATTEMPTS;
        if (mode === "LargeFiles") {
            invocationCount = NO_ATTEMPTS_LARGEFILES;
        }
        let bodyString = JSON.stringify(this.state.contentRegions);
        // bodyString += bodyString;
        // bodyString += bodyString;
        // bodyString += bodyString;
        // bodyString += bodyString;

        // console.log("Test Image ", bodyString.length);

        let intervalCFImages = setInterval(() => {
            if (attempts < invocationCount) {
                attempts++;
                // console.log("Attempts :", attempts);
                this.incrementNoOfRequests(bucket);

                performance.mark(beginLabel + attempts);

                this.dataProvider.fetchData(mode, url, { attempt: attempts }, bodyString, response => {
                    performance.mark(endLabel + response.params.attempt);
                    performance.measure(`measureCF${mode}`, beginLabel + response.params.attempt, endLabel + response.params.attempt);
                    // console.log("Attempt %s - %s - %s", response.url, endLabel, response.params.attempt);
                    this.urls[response.url]['response'].push(response);

                    const measureTiming = performance.getEntriesByName(`measureCF${mode}`);
                    // console.log("Measure timing count :%s %j", measureTiming.length, measureTiming[0]);
                    let cfItems = [];
                    measureTiming.map((measure, index) => cfItems.push({ x: index + 1, y: Math.round(measure.duration) }))
                    // let val = { x: cfItems.length + 1, y: response.time };
                    // cfItems.push(val);
                    // let key = `CF${mode}`;
                    // console.log("cfitems ", cfItems);
                    this.setState({ [`CF${mode}`]: cfItems });
                    // console.log("Checking :%s,%s,%s", invocationCount, this.urls[response.url]['response'].length, (invocationCount == this.urls[response.url]['response'].length));

                    if (invocationCount == this.urls[response.url]['response'].length) {
                        this.finalize(response.url, true, mode, invocationCount, bucket);
                    }

                    // this.state[[`CF${mode}Responses`]].push(response);
                    // console.log("With CF :%j" + JSON.stringify(response));
                    // let resourceTiming = performance.getEntriesByName('fetch');
                    // console.log("resourceTiming :%s - %j", resourceTiming.length, JSON.stringify(resourceTiming));
                }, this.dataProvider);
            } else {
                // console.log(performance.getEntriesByName('measureSquareRootLoop'));
                // const measureTiming = performance.getEntriesByName(`measureCF${mode}`);
                // for (const entry of measureTiming) {
                //   console.log("measureTiming timing :%s - %j", measureTiming.length, entry);
                // }
                clearInterval(intervalCFImages);
            }
        }, 500);

        // const resourceTiming = performance.getEntriesByType('resource');
        // console.log("resourceTiming :%s - %j", resourceTiming.length, JSON.stringify(resourceTiming));

        // performance.clearResourceTimings();

        let directItems = [];
        let attempts1 = 0;
        // Direct link
        let url1 = this.state[[`${mode}Links`]][1];
        let bucket1 = this.state[[`${mode}Links`]][3];
        this.setState({ [`Direct${mode}Spinner`]: true });

        // this.dataProvider.getCFData({}, url1, response => { }, this.dataProvider);
        this.items[`Direct${mode}`] = [];
        this.items[`Direct${mode}`].push(url1);
        this.urls[url1] = {};
        this.urls[url1]['response'] = [];

        let beginLabel1 = `beginDirect${mode}`;
        let endLabel1 = `endDirect${mode}`;

        let intervalDirectImages = setInterval(() => {
            if (attempts1 < invocationCount) {
                attempts1++;
                this.incrementNoOfRequests(bucket1);
                performance.mark(beginLabel1 + attempts1);
                this.dataProvider.fetchData(mode, url1, { attempt: attempts1 }, bodyString, response => {

                    performance.mark(endLabel1 + response.params.attempt);
                    performance.measure(`measureDirect${mode}`, beginLabel1 + response.params.attempt, endLabel1 + response.params.attempt);

                    // console.log("Attempt %s - %s - %s", response.url, endLabel1, response.params.attempt);
                    this.urls[response.url]['response'].push(response);

                    const measureTiming = performance.getEntriesByName(`measureDirect${mode}`);
                    let cfItems = [];
                    measureTiming.map((measure, index) => cfItems.push({ x: index + 1, y: Math.round(measure.duration) }))

                    this.setState({ [`Direct${mode}`]: cfItems });
                    // console.log("Checking :%s,%s,%s", invocationCount, this.urls[response.url]['response'].length, (invocationCount == this.urls[response.url]['response'].length));
                    if (invocationCount === this.urls[response.url]['response'].length) {
                        this.finalize(response.url, false, mode, invocationCount, bucket1);
                        resolve("Done " + mode);
                    }

                }, this.dataProvider);
            } else {
                clearInterval(intervalDirectImages);
            }
        }, 500);
    }

    finalize(url, viaCloudFront, mode, invocationCount, bucket) {
        console.log("In finalize :%s,%s,%s", url, viaCloudFront, mode);
        this.populateAdditionalInfo(url);
        this.populateAdditionalInfo1(url, viaCloudFront, mode);

        if (this.urls[url]['response'][0]?.pop) {
            this.setState({
                pop: this.urls[url]['response'].find(el => el.pop !== '-')?.pop
            });
        };
        // if (this.urls[url]['performance'][0]?.nextHopProtocol) {

        if (viaCloudFront) {
            let elem = this.urls[url]['performance'].find(el => el.nextHopProtocol !== '-');
            if (elem) {
                this.setState({
                    cfprotocol: elem?.nextHopProtocol
                });
            }
        }
        else {
            if (mode === "APIsGET" || mode === "APIsPOST") {
                this.setState({ apiprotocol: this.urls[url]['performance'].find(el => el.nextHopProtocol !== '-')?.nextHopProtocol });
            } else {
                let value = this.urls[url]['performance'].find(el => el.nextHopProtocol !== '-')?.nextHopProtocol;
                this.setState({ s3protocol: value ? value : "http/1.1" });
            }
        }

        if (viaCloudFront) {
            this.setState({ [`CF${mode}Info`]: this.urls[url]['info'] });
            this.updateTotalBytes(bucket, this.urls[url]['info']['transferSize'] * invocationCount);
            this.calculateProtocolImprovements(bucket, this.urls[url], this.urls[url]['info']['transferSize'] * invocationCount);
            // this.totalBytes += this.urls[url]['info']['transferSize'] * invocationCount;
        }
        else {
            this.setState({ [`Direct${mode}Info`]: this.urls[url]['info'] });
            // this.totalBytes += this.urls[url]['info']['content_length'] * invocationCount;
            this.updateTotalBytes(bucket, this.urls[url]['info']['content_length'] * invocationCount);
        }

        if (viaCloudFront) {
            if (mode === "APIsPOST") {
                // console.log("For now looking at only post api calls for CF", url);
                let itemsRTTTFB = [];
                let itemsSTHUpTTFB = [];
                let itemsSTHDownTTFB = [];
                let itemsSTHUpDNS = []
                let itemsSTHUpConnect = [];
                let itemsRTSSL = [];
                this.urls[url]['performance'].map((item, index) => {
                    let clientTTFB = Math.round(item['duration']);
                    let sth = item.serverTiming;
                    // console.log("STH :%j", sth);

                    let tempVal = sth.find(el => el.name == 'cdn-upstream-fbl')?.duration;
                    // clientTTFB -= tempVal;
                    itemsSTHUpTTFB.push({ x: index + 1, y: tempVal });

                    tempVal = sth.find(el => el.name == 'cdn-upstream-dns')?.duration;
                    // clientTTFB -= tempVal != 0 ? tempVal : 0;
                    itemsSTHUpDNS.push({ x: index + 1, y: tempVal });

                    tempVal = sth.find(el => el.name == 'cdn-upstream-connect')?.duration;
                    // clientTTFB -= tempVal;
                    itemsSTHUpConnect.push({ x: index + 1, y: tempVal });

                    tempVal = sth.find(el => el.name == 'cdn-downstream-fbl')?.duration;
                    itemsSTHDownTTFB.push({ x: index + 1, y: tempVal });

                    itemsRTTTFB.push({ x: index + 1, y: clientTTFB });

                    tempVal = Math.round(item['requestStart'] - item['startTime']);
                    itemsRTSSL.push({ x: index + 1, y: tempVal });
                });
                // console.log("Setting CFTS :%j", itemsSTHUpTTFB);
                this.setState({
                    [`CFTS${mode}`]: {
                        rttfb: itemsRTTTFB,
                        sthupttfb: itemsSTHUpTTFB,
                        sthupdns: itemsSTHUpDNS,
                        sthupconnect: itemsSTHUpConnect,
                        sthdownttfb: itemsSTHDownTTFB,
                        rtssl: itemsRTSSL,
                    }
                });
                // const measureTiming = performance.getEntriesByName(`measureDirect${mode}`);
                // measureTiming.map((measure, index) => cfItems.push({ x: index + 1, y: Math.round(measure.duration) }))

                // this.setState({ [`Direct${mode}`]: cfItems });
            }
        }
        // this.setState({ totalBytes: this.totalBytes });
        // console.log("URL list %j", this.urls);
    }

    calculateProtocolImprovements(bucket, urls, totalBytes) {
        let totalTime = 0;
        urls['response'].map(entry => totalTime += entry.time);
        this.throughput[bucket] = totalBytes / totalTime;
        this.setState({ throughput: this.throughput });
    }

    incrementNoOfRequests(bucket) {
        ++this.noOfRequests.total;
        this.noOfRequests[bucket] = 1 + this.noOfRequests[bucket];
        // console.log("Total req :%j", this.noOfRequests);
        this.setState({ noOfRequests: this.noOfRequests });
    }

    updateTotalBytes(bucket, value) {
        this.totalBytes.total = this.totalBytes.total + value;
        this.totalBytes[bucket] = value + this.totalBytes[bucket];
        // console.log("Total req :%j", this.noOfRequests);
        this.setState({ totalBytes: this.totalBytes });
    }

    populateAdditionalInfo(url) {
        const resourceTiming = performance.getEntriesByType(`resource`);
        this.urls[url]['performance'] = [];
        resourceTiming
            .filter(res => (res.initiatorType == 'fetch' && res.name === url))
            .map(res => {
                // console.log("filtered :%j", res);
                this.urls[url]['performance'].push(res);
            });
    }

    populateAdditionalInfo1(url, viaCloudFront, mode) {

        let info = {};

        // console.log("In populateAdditionalInfo1 for :%s", url);
        if (viaCloudFront) {

            let values = [];
            // calculate age stats
            this.urls[url]['response'].map(item => values.push(item['age']));
            let stats = calculatePercentile(values);
            // console.log("Age stats :%j", stats);
            // this.setState({ age: ageStats });
            info['age'] = stats;

            // calculate TTFB
            values = [];
            // only applicable for CF and API endpoints. S3 does not have the info
            this.urls[url]['performance'].map(item => values.push(item['responseStart'] - item['requestStart']));

            stats = calculatePercentile(values);
            // console.log("TTFB stats :%j", stats);
            // this.setState({ age: ageStats });
            info['ttfb'] = stats;

            // calculate SSL negotiation
            values = [];
            // only applicable for CF and API endpoints. S3 does not have the info
            this.urls[url]['performance'].map(item => values.push(Math.round(item['requestStart'] - item['connectStart'])));

            stats = calculatePercentile(values);
            // console.log("TTFB stats :%j", stats);
            // this.setState({ age: ageStats });
            let chartSSL = [];
            values.map((item, index) => chartSSL.push({ x: index + 1, y: item }));

            info['ssltime'] = { stats: stats, readings: chartSSL };

            // calculate TTLB
            values = [];
            // only applicable for CF and API endpoints. S3 does not have the info
            this.urls[url]['performance'].map(item => values.push(item['responseEnd'] - item['requestStart']));

            stats = calculatePercentile(values);
            info['ttlb'] = stats;

            let response = this.urls[url]['response']?.at(0);
            info['compression'] = response ? response['compression'] : "-";
            let performance = this.urls[url]['performance'].at(0);
            info['encodedBodySize'] = performance ? performance['encodedBodySize'] : response['content_length'];
            info['transferSize'] = performance ? performance['transferSize'] : response['content_length'];

            // // calculate total bytes downloaded
            // this.urls[url]['performance'].map(item => values.push(this.totalBytes += item['encodedBodySize']));

            // calculate Duration
            values = [];
            this.state[`CF${mode}`].map(item => values.push(item['y']));
            stats = calculatePercentile(values);
            info['duration'] = stats;
        } else {
            let response = this.urls[url]['response']?.at(0);
            info['content_length'] = response ? response['content_length'] : 0;
            // calculate Duration
            let values = [];
            this.state[`Direct${mode}`].map(item => values.push(item['y']));
            let stats = calculatePercentile(values);
            info['duration'] = stats;

            // calculate SSL negotiation
            values = [];
            // only applicable for CF and API endpoints. S3 does not have the info
            this.urls[url]['performance'].map(item => values.push(Math.round(item['requestStart'] - item['connectStart'])));

            stats = calculatePercentile(values);
            if (stats.p90 > 0) {
                let chartSSL = [];
                values.map((item, index) => chartSSL.push({ x: index + 1, y: item }));

                info['ssltime'] = { stats: stats, readings: chartSSL };
            }
            // info['ssltime'] = { stats: stats, readings: values };
        }

        this.urls[url]['info'] = info;
    }

    pruneItems(items, noOfItems) {
        if (items.length > noOfItems) {
            items.shift();
        }
    }

    refreshDirectPanel(e) {
        this.setState({ direct: [], mode: e.target[1].value });

        this.resetCounters(false, true);
        let url = this.state.selectedRegion.value.direct;

        let items = [];
        let interval = setInterval(() => {
            if (items.length < 10) {
                this.dataProvider.getDirectData(url, response => {
                    items.push(response);
                    this.setState({ direct: items });
                }, this.dataProvider);
            } else {
                clearInterval(interval);
                calculatePercentile(items, false, true);
            }
        }, 1000);
    }

    render() {
        return (
            <React.Fragment>
                <TopPanel label="Acceleration" />
                <AppLayout
                    navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
                    // breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
                    content={
                        <Content
                            // Changes the Help panel content when the user clicks an 'info' link
                            replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
                            handleClick={this.handleClick}
                            handlePricing={this.handlePricing}
                            handleAlert={this.handleAlert}
                            handleRegionSelection={this.handleRegionSelection}
                            onInputChange={this.onInputChange}
                            currentState={this.state}
                            update={this.update}
                        />
                    }
                    contentType="default"
                    tools={Tools[this.state.toolsIndex]}
                    onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
                    toolsOpen={this.state.toolsOpen}
                    navigationOpen={this.state?.navigationState}
                    onNavigationChange={e => this.setState({ navigationState: e.detail.open })}
                />
                <BottomPanel label="GlobalAccelerator" />

            </React.Fragment>
        );
    }

    initializeCounters() {
        this.noOfRequests = { total: 0, cloudfront: 0, apis: 0, s3: 0 };
        this.throughput = { total: 0, cloudfront: 0, apis: 0, s3: 0 };
        this.totalBytes = { total: 0, cloudfront: 0, apis: 0, s3: 0 };
        // this.totalBytes = 0;
        this.setState({ noOfRequests: this.noOfRequests });
        this.setState({ throughput: this.throughput });
        this.setState({ totalBytes: this.totalBytes });
        this.setState({ AmazonS3DTO: {} });
        this.setState({ AmazonCloudFrontDTO: {} });

    }

    handleAlert() {
        // console.log("In handleAlert");
        this.setState({ alert: false });
    }

    handleClick(e) {
        e.preventDefault();
        this.initializeCounters();

        performance.clearMeasures();
        performance.clearResourceTimings();
        // console.log('State is:', this.state.mode);
        // console.log("Region selected ", this.state.selectedRegion);

        if (!this.state.selectedRegion) {
            // console.log("Region not selected");
            this.setState({ alert: true });
            return;
        }
        else {
            this.handleAlert();
        }

        let regionEndpoints = this.state.selectedRegion.value;
        // reset the items
        this.items = [];
        this.urls = [];
        let totalAttempts = 0;
        let promises = [];
        let skippedLargeFiles = false;
        if (this.state.togglePricing?.checked) {
            // console.log("Invoking pricing");
            this.handlePricing(e);
        }

        this.state.modes.forEach(mode => {
            // skip large files test for now
            if (mode != "LargeFiles") {

                let endpoints = regionEndpoints[mode];
                let index = Math.floor(Math.random() * endpoints.length);
                let url = `${regionEndpoints.aga_domain}${endpoints[index]}`;
                let url1 = (mode === "APIsGET" || mode === "APIsPOST") ? `${regionEndpoints.alb_domain}${endpoints[index]}` : `-`;
                this.state[[`${mode}Links`]] = [url, url1, "cloudfront", (mode === "APIsGET" || mode === "APIsPOST") ? "apis" : "s3"];

                promises.push(new Promise((resolve, reject) => {
                    this.refreshCFPanel(mode, resolve);
                }));
            }
            else {
                skippedLargeFiles = true;
            }
            totalAttempts += (mode === "LargeFiles") ? NO_ATTEMPTS_LARGEFILES * 2 : NO_ATTEMPTS * 2;
        });

        // Promise.all(promises).then((values) => {
        //     console.log(values); // [3, 1337, "foo"]
        //     // console.log("calling large files");
        //     if (skippedLargeFiles) {
        //         new Promise((resolve, reject) => {
        //             let mode = "LargeFiles";
        //             let endpoints = regionEndpoints[mode];
        //             let index = Math.floor(Math.random() * endpoints.length);
        //             let url = `${regionEndpoints.cloudfront_domain}${endpoints[index]}`;
        //             let url1 = (mode === "APIsGET" || mode === "APIsPOST") ? `${regionEndpoints.alb_domain}${endpoints[index]}` : `${regionEndpoints.s3_domain}${endpoints[index]}`;
        //             this.state[[`${mode}Links`]] = [url, url1, "cloudfront", (mode === "APIsGET" || mode === "APIsPOST") ? "apis" : "s3"];
        //             this.refreshCFPanel(mode, resolve);
        //             // totalAttempts += (mode === "LargeFiles") ? NO_ATTEMPTS_LARGEFILES * 2 : NO_ATTEMPTS * 2;
        //         }).then((value) => {
        //             console.log("Large file done");
        //         });
        //     }
        // });

        // console.log("Total attempts :%s", totalAttempts);
        this.setState({ totalAttempts: totalAttempts });

        console.log("Urls :%j", this.urls);
    }

    populateAdditionalInfo0() {
        const resourceTiming = performance.getEntriesByType(`resource`);
        resourceTiming
            .map(res => {
                // console.log("%s  ,  %s,  %s , %s", res.name, res.initiatorType, (res.connectEnd - res.connectStart), (res.requestStart - res.secureConnectionStart));
            });
    }

    handlePricing(e) {
        e.preventDefault();
        // console.log("In handlePricing");
        if (!this.state.selectedRegion) {
            // console.log("Region not selected");
            this.setState({ alert: true });
            return;
        }
        else {
            this.handleAlert();
        }

        let serviceCode = "AmazonCloudFront";
        let region = this.state.selectedRegion.value;

        this.dataProvider.getPricingData(serviceCode, this.state.dtocountry.value, pricing => {
            this.processDTO(serviceCode, pricing);
        });

        let serviceCode1 = "AmazonS3";

        this.dataProvider.getPricingData(serviceCode1, region.regionCode, pricing => {
            this.processDTO(serviceCode1, pricing);
        });
    }

    processDTO(serviceCode, pricing) {

        // console.log("Request Pricing response :%j", pricing.requests);
        let multiplier = 1;
        let dtounit = this.state.dtounit.value;
        if (dtounit === "gb") {
            multiplier = 1;
        }
        else if (dtounit === "tb") {
            multiplier = 1024;
        }
        else if (dtounit === "pb") {
            multiplier = 1024 * 1024;
        }
        let enteredDTO = this.state.dto * multiplier;

        if (serviceCode == "AmazonCloudFront" && this.state.compressible?.checked) {
            enteredDTO -= (enteredDTO * this.state.compressiblepercent) / 100
        }
        let remainingDTO = enteredDTO;
        // console.log("Entered DTO ", enteredDTO);
        let baseLevel = (serviceCode == "AmazonCloudFront") ? 1024 : 100;

        remainingDTO -= baseLevel;

        // console.log("DTO Pricing response :%j", pricing.dto);
        let applicableDTO = [];
        // if in free tier, then no need to proceeed.
        applicableDTO.push({
            consumed: remainingDTO < 0 ? enteredDTO : baseLevel, consumedCost: 0, beginRange: 0,
            endRange: baseLevel, freeTier: true,
        });

        pricing.dto.every((dto, index) => {
            // console.log(dto);
            let endRange = dto.endRange;

            // console.log(endRange);
            if (endRange != "Inf") {
                let range = endRange - dto.beginRange;
                // console.log("Begin End Range :%s - %s - %s", dto.beginRange, endRange, range);
                if (remainingDTO > range) {
                    remainingDTO -= range;
                    dto["consumed"] = range;
                    dto.beginRange = parseInt(dto.beginRange);
                    dto["consumedCost"] = dto["consumed"] * dto.pricePerUnit.USD;
                    applicableDTO.push(dto)
                }
                else {
                    if (remainingDTO > 0) {
                        // console.log("Stopping as no more left");
                        dto["consumed"] = remainingDTO;
                        dto["consumedCost"] = dto["consumed"] * dto.pricePerUnit.USD;
                        applicableDTO.push(dto);
                    }
                    return false;
                }
            }
            else {
                dto["consumed"] = remainingDTO;
                dto.endRange = "Infinity";
                dto["consumedCost"] = (dto["consumed"] * dto.pricePerUnit.USD);//.toLocaleString(undefined, { maximumFractionDigits: 2 });
                applicableDTO.push(dto);
            }
            // console.log("Remaining DTO ", remainingDTO);

            // if (index === 3) return false;
            return true;
        });
        // console.log("Applicable DTO Pricing :%j", applicableDTO);
        let totalCost = 0;
        applicableDTO.map(price => totalCost += price.consumedCost);

        this.setState({ [`${serviceCode}DTO`]: { totalCost: totalCost, items: applicableDTO } });
    }
}

const PricingTab = props => {
    return (
        // <Container disableHeaderPaddings>

        <Grid
            gridDefinition={[
                { colspan: 6 }, { colspan: 6 },
                { colspan: 12 }
            ]}
        >
            {/* <form onSubmit={props.handlePricing}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="primary">Compare Price</Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween direction='horizontal' size="l">
              <FormField label="Estimated Data Transfer" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(14)}>
                  Info
                </Link>
              }>
                <SpaceBetween direction='horizontal' size="xxs">
                  <Input
                    onChange={props.onInputChange.bind(this, 'dto')}
                    // onChange={({ detail }) => setValue(detail.value)}
                    value={props.currentState.dto}
                    autoFocus
                    disableBrowserAutocorrect
                    placeholder="Enter estimated data transfer out to the internet"
                  />
                  <Select
                    selectedOption={props.currentState.dtounit}
                    onChange={props.onInputChange.bind(this, 'dtounit')}
                    // filteringType="auto"
                    options={props.currentState.dtounitoptions}
                    selectedAriaLabel="Selected"
                  />
                </SpaceBetween>
              </FormField>
              <FormField label="Location" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(15)}>
                  Info
                </Link>
              }>
                <Select
                  selectedOption={props.currentState.dtocountry}
                  onChange={props.onInputChange.bind(this, 'dtocountry')}
                  options={props.currentState.countries}
                  selectedAriaLabel="Selected"
                />
              </FormField>
              <FormField label="Compressible? (%)" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(16)}>
                  Info
                </Link>
              }>
                <Checkbox
                  onChange={props.onInputChange.bind(this, 'compressible')}
                  checked={props.currentState.compressible?.checked}
                >
                  <Input
                    onChange={props.onInputChange.bind(this, 'compressiblepercent')}
                    // onChange={({ detail }) => setValue(detail.value)}
                    value={props.currentState.compressiblepercent}
                    disableBrowserAutocorrect
                    inputMode='numeric'
                    type="number"
                    placeholder="percentage"
                  />
                </Checkbox>
              </FormField>
              <FormField label="CloudFront Savings Bundle?" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(17)}>
                  Info
                </Link>
              }>
                <Checkbox
                  onChange={props.onInputChange.bind(this, 'cfssb')}
                  checked={props.currentState.cfssb?.checked}
                >
                </Checkbox>
              </FormField>
            </SpaceBetween>
          </Form>
        </form> */}

            <PricingTable serviceCode="AmazonCloudFront" label="CloudFront"
                regionLabel={props.currentState?.dtocountry?.value}
                items={props.currentState?.AmazonCloudFrontDTO?.items}
                numberFormatScale2={props.currentState.numberFormatScale2}
                numberFormatScale4={props.currentState.numberFormatScale4}
                totalCost={props.currentState?.AmazonCloudFrontDTO?.totalCost}
                onInputChange={props.onInputChange}
                cfssb={props.currentState.cfssb}
                totalCostS3={props.currentState?.AmazonS3DTO?.totalCost} />
            <PricingTable serviceCode="AmazonS3" label=""
                regionLabel={props.currentState?.selectedRegion?.label}
                numberFormatScale2={props.currentState.numberFormatScale2}
                numberFormatScale4={props.currentState.numberFormatScale4}
                items={props.currentState?.AmazonS3DTO?.items}
                onInputChange={props.onInputChange}
                totalCost={props.currentState?.AmazonS3DTO?.totalCost} />
            <Box variant='small'>
                Use the <Link external href="https://calculator.aws/#/">AWS calculator</Link> for a more accurate estimate of cost.
            </Box>
            {/* </ColumnLayout>
        </Container> */}
        </Grid >
        // </Container>
    );
};

const PricingTable = props => {
    return (
        props.regionLabel && <Table variant='container'
            // variant="embedded"
            columnDefinitions={[
                {
                    id: `${props.label}_start`,
                    header: <Box variant='small'>Start (GB)</Box>,
                    cell: item => <Box variant='code'>{item.beginRange || "0"}</Box>,
                    // sortingField: "name"
                },
                {
                    id: `${props.label}_end`,
                    header: <Box variant='small'>End (GB)</Box>,
                    cell: item => <Box variant='code'>{item.endRange || "-"}</Box>,
                    // sortingField: "alt"
                },
                {
                    id: `${props.label}_unitprice`,
                    header: <Box variant='small'>Unit price</Box>,
                    cell: item => <Box variant='code'>{(item?.pricePerUnit?.USD && props.numberFormatScale4.format(item?.pricePerUnit?.USD)) || "0"}</Box>,
                    // sortingField: "alt"
                },
                {
                    id: `${props.label}_consumed`,
                    header: <Box variant='small'>Consumed (GB)</Box>,
                    cell: item => <Box variant='code'>{item.consumed || "-"}</Box>
                },
                {
                    id: `${props.label}_cost`,
                    header: <Box variant='small'>Cost</Box>,
                    cell: item => <Box variant='code'>{item.freeTier ? <Badge color='grey'>Free Tier</Badge> : (item.consumedCost ?
                        item.consumedCost && props.numberFormatScale2.format(item.consumedCost) : "0")}</Box>
                }
            ]}
            items={props.items}
            loadingText="Loading resources"
            sortingDisabled
            // empty={
            //   <Box textAlign="center" color="inherit">
            //     <b>No resources</b>
            //     <Box
            //       padding={{ bottom: "s" }}
            //       variant="p"
            //       color="inherit"
            //     >
            //       No resources to display.
            //     </Box>
            //     <Button>Create resource</Button>
            //   </Box>
            // }
            header={props.regionLabel && <Header variant='h5'> {props.label} DTO from {props.regionLabel}</Header>}
            footer={<SummarizeCostSavingsPanel totalCost={props.totalCost}
                numberFormatScale2={props.numberFormatScale2}
                totalCostS3={props.totalCostS3}
                cfssb={props.cfssb} />}
        />
    );
}

const ValueWithSavings = props => {
    if (props.val1 < props.val2) {
        if (props.concise) {
            return (
                <Box color='text-status-success' variant='code'>
                    {props.savingslabel}
                    {100 - Math.round(props.val1 * 100 / props.val2)}%
                </Box>
            );
        }
        else {
            return (
                <SpaceBetween direction='horizontal' size='xxs'>
                    <Box variant='code'>{props.label}</Box>
                    <Box color='text-status-success' variant='code'>
                        ({props.savingslabel}
                        {100 - Math.round(props.val1 * 100 / props.val2)}%)
                    </Box>
                </SpaceBetween>
            )
        }
    }
    else if (props.val1 >= props.val2) {
        if (props.concise) {
            return (<Badge color='blue'>Consider Private Pricing..</Badge>);
        }
        else {
            return (<Box variant='code'>{props.label}</Box>);
        }
    }
    else {
        if (props.concise) {
            return ("");
        }
        else {
            return (<Box variant='code'>{props.label}</Box>);
        }
    }
}

const SummarizeCostSavingsPanel = props => {

    if (props.concise) {
        if (props.cfssb?.checked) {
            return (<ValueWithSavings savingslabel={DTO_SAVINGS} concise={props.concise} symbol="$" label={(props.totalCost ? props.numberFormatScale2.format(props.totalCost * 0.70) : "0")}
                val1={props.totalCost * 0.70} val2={props.totalCostS3} />);
        }
        else {
            return (<ValueWithSavings savingslabel={DTO_SAVINGS} concise={props.concise} symbol="$" label={(props.totalCost ? props.numberFormatScale2.format(props.totalCost) : "0")}
                val1={props.totalCost} val2={props.totalCostS3} />);
        }
    }
    if (props.cfssb?.checked) {
        return (
            <ColumnLayout columns={2} variant='text-grid'>
                <Box variant='code'>Total Cost</Box>
                <ValueWithSavings symbol="$" savingslabel={DTO_SAVINGS} label={(props.totalCost ? props.numberFormatScale2.format(props.totalCost) : "0")} />
                <Box variant='code'>Less after 30% SSB Discount</Box>
                <ValueWithSavings symbol="$" savingslabel={DTO_SAVINGS} label={(props.totalCost ? props.numberFormatScale2.format(props.totalCost * 0.70) : "0")}
                    val1={props.totalCost * 0.70} val2={props.totalCostS3} />
            </ColumnLayout>
        );
    } else {
        return (
            <ColumnLayout columns={2} variant='text-grid'>
                <Box variant='code'>Total Cost</Box>
                <ValueWithSavings symbol="$" savingslabel={DTO_SAVINGS} label={(props.totalCost ? props.numberFormatScale2.format(props.totalCost) : "0")}
                    val1={props.totalCost} val2={props.totalCostS3} />
            </ColumnLayout>
        );
    }
}

const OverviewPanel2 = props => {
    return (
        <ExpandableSection
            variant="container"
            header={
                <Header actions={
                    <SpaceBetween direction='horizontal' size='l'>
                        {props.currentState.AmazonS3DTO?.totalCost != 0 && <SummarizeCostSavingsPanel concise totalCost={props.currentState.AmazonCloudFrontDTO?.totalCost}
                            numberFormatScale2={props.currentState.numberFormatScale2}
                            totalCostS3={props.currentState.AmazonS3DTO?.totalCost}
                            cfssb={props.currentState?.cfssb} />}
                        <Box variant="code"><Hash size={14} />{props.currentState.noOfRequests?.total}</Box>
                        <Box variant="code" textAlign='center'><DownloadCloud size={18} /> {props.currentState.totalBytes?.total != 'NaN' && prettyBytes(props.currentState.totalBytes?.total)} </Box>
                        {/* <Box variant="code"><MapPin size={18} /> <Badge color="grey">{props.currentState.pop}</Badge> </Box> */}
                    </SpaceBetween>
                } > <SpaceBetween direction='horizontal' size='l'>
                        <Box variant='h4'>Summary</Box>
                    </SpaceBetween>
                </Header>
            }
        >
            <ColumnLayout columns={3} borders='vertical'>
                {/* <ProtocolTable overview={props.currentState} replaceToolsContent={props.replaceToolsContent} /> */}
                <SpaceBetween direction='vertical'>
                    {/* <Box variant="code"><Hash size={14} /> Total Requests: {props.currentState.noOfRequests.total}</Box> */}
                    <Box variant="code"><Hash size={14} /> AGA: {props.currentState.noOfRequests.cloudfront}</Box>
                    <Box variant="code"><Hash size={14} /> APIs: {props.currentState.noOfRequests.apis}</Box>
                </SpaceBetween>
                <SpaceBetween direction='vertical'>
                    <Box variant="code"><DownloadCloud size={14} /> AGA: {prettyBytes(props.currentState.totalBytes.cloudfront)}</Box>
                    <Box variant="code"><DownloadCloud size={14} /> APIs: {prettyBytes(props.currentState.totalBytes.apis)}</Box>
                </SpaceBetween>
            </ColumnLayout>
        </ExpandableSection>
    );
}

const OverviewPanel = props => {

    return (
        <Container header={<Header variant="h3" actions={
            <ProgressBar
                // resultText={<RequestsChart overview={props.overview} />}
                // status={Math.round(props.overview.noOfRequests.total * 100 / (props.overview.totalAttempts)) == 100 ? "success" : "in-progress"}
                value={props.overview.noOfRequests?.total && Math.round(props.overview.noOfRequests.total * 100 / (props.overview.totalAttempts))}
                additionalInfo={<SpaceBetween direction='vertical' size='xxs'>
                    {/* <RequestsChart overview={props.overview} /> */}
                    <Box variant="code"># Total Requests: {props.overview.noOfRequests.total}</Box>
                    <Box variant="code"># CloudFront: {props.overview.noOfRequests.cloudfront}</Box>
                    <Box variant="code"># APIs: {props.overview.noOfRequests.apis}</Box>
                    <Box variant="code"># S3: {props.overview.noOfRequests.s3}</Box>
                </SpaceBetween>
                }
                description={<Box variant="code">{props.overview?.totalBytes?.total && prettyBytes(props.overview.totalBytes?.total)} downloded</Box>}
                label={<Box variant="code">Edge location: <Badge color="green">{props.overview.pop}</Badge> </Box>}
            />
        }><ProtocolTable overview={props.overview} replaceToolsContent={props.replaceToolsContent} /></Header>}>

            {/* <ColumnLayout columns={2} variant="text-grid"> */}


            {/* <SpaceBetween size="l">
          <SpaceBetween direction='horizontal' size='l'>
            <Box variant="p"><strong>#Requests:</strong><code>{props.overview.noOfRequests}</code></Box>
            <Box variant="p"><strong>Total Bytes:</strong><code>{props.overview.totalBytes}</code></Box>
            <Box variant="p"><strong>Edge Location:</strong><code>{props.overview.pop}</code></Box></SpaceBetween> */}

            {/* <ValueWithLabel label="Age (sec)">
            p90 <code>  {props.overview.age.p90}</code></ValueWithLabel>
          <ValueWithLabel label="">
            p50 <code>  {props.overview.age.p50}</code></ValueWithLabel> */}


            {/* <ValueWithLabel label="PoP"><code>{props.overview.pop}</code></ValueWithLabel> */}

            {/*           
          <ValueWithLabel label="HTTP Protocol Version">
            CloudFront <code>{props.overview.cfprotocol}</code></ValueWithLabel>
          <ValueWithLabel>
            Lambda(APIs) <code>{props.overview.apiprotocol}</code></ValueWithLabel>
          <ValueWithLabel>
            S3 <code>{props.overview.s3protocol}</code></ValueWithLabel> */}
            {/* </SpaceBetween> */}
            {/* </ColumnLayout> */}
        </Container>
    );
};

const RequestsChart = props => {
    return (
        <React.Fragment>
            <PieChart
                data={[
                    { title: "CloudFront", value: props.overview.noOfRequests.cloudfront },
                    { title: "APIs", value: props.overview.noOfRequests.apis },
                    { title: "S3", value: props.overview.noOfRequests.s3 },
                ]}
                i18nStrings={{
                    detailsValue: "Value",
                    detailsPercentage: "Percentage",
                    filterLabel: "Filter displayed data",
                    filterPlaceholder: "Filter data",
                    filterSelectedAriaLabel: "selected",
                    detailPopoverDismissAriaLabel: "Dismiss",
                    legendAriaLabel: "Legend",
                    chartAriaRoleDescription: "pie chart",
                    segmentAriaRoleDescription: "segment"
                }}
                ariaDescription="Donut chart showing generic progress."
                ariaLabel="Small donut chart"
                errorText="Error loading data."
                hideDescriptions
                hideFilter
                // hideLegend
                hideTitles
                innerMetricValue={props.overview.noOfRequests.total}
                loadingText="Loading chart"
                recoveryText="Retry"
                size="small"
                variant="donut"
            // empty={
            //   <Box textAlign="center" color="inherit">
            //     <b>No data available</b>
            //     <Box variant="p" color="inherit">
            //       There is no data available
            //     </Box>
            //   </Box>
            // }
            // noMatch={
            //   <Box textAlign="center" color="inherit">
            //     <b>No matching data</b>
            //     <Box variant="p" color="inherit">
            //       There is no matching data to display
            //     </Box>
            //     <Button>Clear filter</Button>
            //   </Box>
            // }
            />
            <Box variant="code" > {props.overview?.totalBytes?.total && prettyBytes(props.overview.totalBytes?.total)} downloded</Box >
        </React.Fragment>
    );
}

const ProtocolTable = props => {
    return (
        <Table
            columnDefinitions={[
                {
                    id: "cf",
                    header: <Box variant='small'>CloudFront</Box>,
                    cell: item => item.cf || "-",
                },
                {
                    id: "api",
                    header: <Box variant='small'>APIs</Box>,
                    cell: item => item.api || "-",
                    sortingField: "alt"
                },
                {
                    id: "s3",
                    header: <Box variant='small'>S3</Box>,
                    cell: item => item.s3 || "-"
                }
            ]}
            items={[
                {
                    cf: props.overview.cfprotocol,
                    api: props.overview.apiprotocol,
                    s3: props.overview.s3protocol,
                },
            ]}
            loadingText="Loading resources"
            sortingDisabled
            variant="embedded"
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No resources</b>
                </Box>
            }
            header={<ValueWithInfo label="HTTP Protocol" index="12" replaceToolsContent={props.replaceToolsContent} />
            }
        />
    );
};

const ShowCurrency = props => {
    if (props.value) {
        let temp = props.value * props.multiplier;
        temp = temp.toFixed(props.limit);
        return `$${temp}`;
    }
    else {
        return '';
    }
};

// The content in the main content area of the App layout
const Content = props => {

    return (
        <React.Fragment>
            <BrowserCheck />

            <SpaceBetween direction='vertical' size='l'>
                <ExtendingPanel label="Synopsis">
                    In this demo, we will show you the application acceleration when it is
                    served through Amazon Global Accelerator (AGA) vs directly from Origin such as ALBs.
                    You can choose the AWS region which will act as Origin. We will run tests
                    accessing assets via AGA and comparing that versus other origins to see
                    the acceleration and compression benefits as applicable.

                    This demo displays a graph of performance differences in 2 categories:
                    APIsGET and APIsPOST.
                    You have visibility into the content requested, the time it took to fetch the content,
                    its size, age in CloudFront cache and what type of compression was applied.
                </ExtendingPanel>

                <Container>
                    <form onSubmit={props.handleClick}>
                        <Form
                            actions={
                                <SpaceBetween direction="vertical" size="xs">
                                    <Button variant="primary">Compare</Button>
                                </SpaceBetween>
                            }
                        >
                            <Grid
                                gridDefinition={[
                                    { colspan: 2 },
                                    { colspan: 9 }, { colspan: 1 },

                                ]}
                            >

                                <SpaceBetween direction='vertical' size='xxs'>

                                    <FormField label="Origin AWS Region" info={
                                        <Link variant="info" onFollow={() => props.replaceToolsContent(6)}>
                                            Info
                                        </Link>
                                    }>
                                        <Select
                                            selectedOption={props.currentState.selectedRegion}
                                            onChange={({ detail }) =>
                                                props.handleRegionSelection(detail.selectedOption)
                                            }
                                            options={props.currentState.contentRegions}
                                            // filteringType="auto"
                                            selectedAriaLabel="Selected"
                                        />
                                        <Alert type='error'
                                            visible={props.currentState.alert}
                                            onDismiss={() => props.handleAlert()}
                                            // dismissAriaLabel={<Box variant='strong'>Close alert</Box>}
                                            dismissible
                                        // header={<Box variant='p'>Select AWS Region</Box>}
                                        >
                                            <Box variant='small'>Select 'Origin AWS Region' and hit 'Test'</Box>
                                        </Alert>
                                    </FormField>
                                    {/* <Box float='right'>
                                        <Toggle
                                            onChange={props.onInputChange.bind(this, 'togglePricing')}
                                            checked={props.currentState?.togglePricing?.checked}>
                                            Compare price
                                        </Toggle>
                                    </Box> */}

                                </SpaceBetween>
                                <Box variant='h5' display={props.currentState.togglePricing?.checked ? 'inline-block' : 'none'}>
                                    <SpaceBetween direction='horizontal' size="l">
                                        <FormField
                                            label="Estimated Data Transfer" info={
                                                <Link variant="info" onFollow={() => props.replaceToolsContent(14)}>
                                                    Info
                                                </Link>
                                            }>
                                            <SpaceBetween direction='horizontal' size="xxs">
                                                <Input className='input_width'
                                                    onChange={props.onInputChange.bind(this, 'dto')}
                                                    // onChange={({ detail }) => setValue(detail.value)}
                                                    value={props.currentState.dto}
                                                    autoFocus
                                                    disableBrowserAutocorrect
                                                    placeholder="Enter estimated data transfer out to the internet"
                                                />
                                                <Select
                                                    selectedOption={props.currentState.dtounit}
                                                    onChange={props.onInputChange.bind(this, 'dtounit')}
                                                    // filteringType="auto"
                                                    options={props.currentState.dtounitoptions}
                                                    selectedAriaLabel="Selected"
                                                />
                                            </SpaceBetween>
                                        </FormField>
                                        <FormField label="Location" info={
                                            <Link variant="info" onFollow={() => props.replaceToolsContent(15)}>
                                                Info
                                            </Link>
                                        }>
                                            <Select
                                                selectedOption={props.currentState.dtocountry}
                                                onChange={props.onInputChange.bind(this, 'dtocountry')}
                                                options={props.currentState.countries}
                                                selectedAriaLabel="Selected"
                                            />
                                        </FormField>
                                        {/* <Grid
                      gridDefinition={[
                        { colspan: 7 },
                        { colspan: 5 }]}> */}
                                        <FormField label="Compressible? (%)" info={
                                            <Link variant="info" onFollow={() => props.replaceToolsContent(16)}>
                                                Info
                                            </Link>
                                        }>
                                            <Checkbox
                                                onChange={props.onInputChange.bind(this, 'compressible')}
                                                checked={props.currentState.compressible?.checked}
                                            >
                                                <Input className='input_width'
                                                    onChange={props.onInputChange.bind(this, 'compressiblepercent')}
                                                    // onChange={({ detail }) => setValue(detail.value)}
                                                    value={props.currentState.compressiblepercent}
                                                    disableBrowserAutocorrect
                                                    inputMode='numeric'
                                                    type="number"
                                                    placeholder="percentage"
                                                />
                                            </Checkbox>
                                        </FormField>
                                        <FormField label="CloudFront Savings Bundle?" info={
                                            <Link variant="info" onFollow={() => props.replaceToolsContent(17)}>
                                                Info
                                            </Link>
                                        }>
                                            <Checkbox
                                                onChange={props.onInputChange.bind(this, 'cfssb')}
                                                checked={props.currentState.cfssb?.checked}
                                            >Apply</Checkbox>
                                        </FormField>
                                        {/* </Grid> */}
                                    </SpaceBetween>

                                </Box>
                                <ProgressBar
                                    resultText={<Box variant='small'>completed</Box>}
                                    status={Math.round(props.currentState.noOfRequests?.total * 100 / (props.currentState.totalAttempts)) == 100 ? "success" : "in-progress"}
                                    value={props.currentState.noOfRequests?.total && Math.round(props.currentState.noOfRequests.total * 100 / (props.currentState.totalAttempts))}
                                />
                            </Grid>
                        </Form>
                    </form>
                </Container>
                <OverviewPanel2 currentState={props.currentState} replaceToolsContent={props.replaceToolsContent} />
                <Tabs
                    // activeTabId="second"
                    tabs={[
                        {
                            // label: <TabLabel icon=<TrendingUp /> label="Performance" />,
                            label: "Performance",
                            id: "first",
                            content: <PerformanceTab currentState={props.currentState} replaceToolsContent={props.replaceToolsContent} />
                        },
                    ]}
                />
                <ExtendingPanel label="Extend it">
                    <ColumnLayout columns={2} variant='text-grid' className='extend_it'>
                        <Box>
                            Questions/ideas on this demo?</Box>
                        <Box variant='strong'>
                            Jaiganesh Girinathan,
                            ganeshji@amazon.com
                        </Box>
                    </ColumnLayout>
                </ExtendingPanel>
            </SpaceBetween>
        </React.Fragment>
    );
};

const PerformanceTab = props => {
    return (
        <div>
            {/* <Box variant='p' textAlign="center" padding={{ bottom: "l" }}> Plot of time taken in milliseconds over number of requests</Box> */}
            <ChartResultPanel currentState={props.currentState} replaceToolsContent={props.replaceToolsContent} />
        </div>
    );
};

const ValueWithLabel = ({ label, children }) => (
    <div>
        <Box variant="awsui-key-label">{label}</Box>
        <div>{children !== "NaN" && children}</div>
    </div>
);

const ValueWithInfo = ({ label, replaceToolsContent, index, children }) => (
    <div>
        <FormField label={label} info={
            <Link variant="info" onFollow={() => replaceToolsContent(index)}>
                Info
            </Link>}>
        </FormField>
        <div>{children !== "NaN" && children}</div>
    </div >
);

const ValueWithLabelHorizontal = ({ label, children }) => (
    <div>
        <Box variant="awsui-key-label">{label}</Box>
        <Box variant="p">{children !== "NaN" && children}</Box>
    </div>
);

const CFPreviewPanel = props => {
    return (
        <Container header={<Header variant="h2">Overview</Header>}>
            <ProgressBar
                value={100}
                additionalInfo="Additional information"
                description="Progress bar description"
                label="Progress bar label"
            />

        </Container>
    );
};

const ChartResultPanel = props => {
    return (

        <Grid
            gridDefinition={[
                { colspan: { default: 6, xxs: 6 } },
                { colspan: { default: 6, xxs: 6 } },
            ]}>
            <div>
                <ChartPanel currentState={props.currentState}
                    icon=<DownloadCloud size="14" />
                    replaceToolsContent={props.replaceToolsContent}
                    line2Id="DirectAPIsGET" line1Id="CFAPIsGET"
                    sourceLink={props.currentState.APIsGETLinks} columnDefinitionIndex="3"
                    mode="APIsGET" label="APIsGET" description="Comparing download speeds - Amazon CloudFront vs Lambda origins" />
            </div>
            <div>
                <ChartPanel currentState={props.currentState}
                    icon=<UploadCloud size="14" />
                    replaceToolsContent={props.replaceToolsContent}
                    line2Id="DirectAPIsPOST" line1Id="CFAPIsPOST"
                    sourceLink={props.currentState.APIsPOSTLinks} columnDefinitionIndex="3"
                    mode="APIsPOST" label="APIsPOST" description="Comparing POST upload speeds - Amazon CloudFront vs Lambda origins" />
            </div>
        </Grid >
    );
};

const AreaChartPanel = props => {
    return (
        // <Container
        //   header={<Box variant='h4'>{props.label}</Box>}>
        <SpaceBetween size='l'>
            <Box variant='h5'>{props.label}</Box>
            <AreaChart
                series={
                    [
                        {
                            title: "TCP/TLS negotiation time",
                            type: "area",
                            data: props.currentState[`${props.lineId}`]['rtssl'],
                        },
                        {
                            title: "Origin DNS lookup",
                            type: "area",
                            data: props.currentState[`${props.lineId}`]['sthupdns'],
                        },
                        {
                            title: "Origin connect",
                            type: "area",
                            data: props.currentState[`${props.lineId}`]['sthupconnect'],
                        },
                        {
                            title: "Origin TTFB",
                            type: "area",
                            data: props.currentState[`${props.lineId}`]['sthupttfb'],
                        },
                        // {
                        //   title: "Persistent connection",
                        //   type: "threshold",
                        //   x: 5,
                        // },
                    ]}
                xDomain={
                    [
                        1, props.mode === "LargeFiles" ? NO_ATTEMPTS_LARGEFILES : NO_ATTEMPTS
                    ]}
                hideFilter
                ariaLabel="Stacked area chart, multiple metrics"
                errorText="Error loading data."
                height={150}
                loadingText="Loading chart"
                recoveryText="Retry"
                xScaleType="linear"
                yScaleType='log'
                // yTitle={< Box variant='small' > Total time(ms)</Box >}
                xTitle=<Box variant='small'># Attempts</Box>
            />
        </SpaceBetween>);
};

const RenderLabels = props => {
    // console.log("In renderlabels ", props);
    if (props && props['data']) {
        // console.log("Includes");
        return (
            // props.data.map((label1) => {
            <div>
                <div><Link external href={props.data[0]}>CloudFront Link</Link>
                </div>
                <div>
                    <Link external href={props.data[1]}>Source Link</Link>
                </div>
            </div>
            // }));
        );
    }
    else {
        return (
            <label></label>
        );
    }
}

const ChartPanel = props => {
    return (
        <Container
            header={<Box variant='h4'>{props.icon} {props.label}</Box>}>
            <LineChart
                series={[
                    {
                        title: "via AGA",
                        type: "line",
                        data: props.currentState[`${props.line1Id}`],
                        // valueFormatter: function l(e) {
                        //   return Math.abs(e) >= 1e9
                        //     ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
                        //     "G"
                        //     : Math.abs(e) >= 1e6
                        //       ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
                        //       "M"
                        //       : Math.abs(e) >= 1e3
                        //         ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
                        //         "K"
                        //         : e.toFixed(2);
                        // }
                    },
                    {
                        title: "Direct from source",
                        type: "line",
                        data: props.currentState[`${props.line2Id}`],
                        // valueFormatter: function l(e) {
                        //   return Math.abs(e) >= 1e9
                        //     ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
                        //     "G"
                        //     : Math.abs(e) >= 1e6
                        //       ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
                        //       "M"
                        //       : Math.abs(e) >= 1e3
                        //         ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
                        //         "K"
                        //         : e.toFixed(2);
                        // }
                    }
                    // {
                    //   title: "Performance goal",
                    //   type: "threshold",
                    //   y: 250000,
                    //   // valueFormatter: function l(e) {
                    //   //   return Math.abs(e) >= 1e9
                    //   //     ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
                    //   //     "G"
                    //   //     : Math.abs(e) >= 1e6
                    //   //       ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
                    //   //       "M"
                    //   //       : Math.abs(e) >= 1e3
                    //   //         ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
                    //   //         "K"
                    //   //         : e.toFixed(2);
                    //   // }
                    // }
                ]}
                xDomain={[
                    1, props.mode === "LargeFiles" ? NO_ATTEMPTS_LARGEFILES : NO_ATTEMPTS
                ]}
                // yDomain={[0, 500000]}
                // i18nStrings={{
                //   filterLabel: "Filter displayed data",
                //   filterPlaceholder: "Filter data",
                //   filterSelectedAriaLabel: "selected",
                //   legendAriaLabel: "Legend",
                //   chartAriaRoleDescription: "line chart",
                //   xTickFormatter: e =>
                //     e
                //       .toLocaleDateString("en-US", {
                //         month: "short",
                //         day: "numeric",
                //         hour: "numeric",
                //         minute: "numeric",
                //         hour12: !1
                //       })
                //       .split(",")
                //       .join("\n"),
                //   yTickFormatter: undefined
                // }}
                ariaLabel="Single data series line chart"
                errorText="Error loading data."
                height={150}
                hideFilter
                loadingText="Loading chart"
                // recoveryText="Retry"
                xScaleType="linear"
                yScaleType='linear'
                // yScaleType={<ChartType info1={props.currentState[`${props.line1Id}Info`]} info2={props.currentState[`${props.line2Id}Info`]} />}
                // xTitle={<Popover
                //   dismissAriaLabel="Close"
                //   content={
                //     <StatusIndicator type="info">
                //       The number of requests
                //     </StatusIndicator>
                //   }><Box># Attempts</Box></Popover>}
                yTitle={<Box variant='small'>Total time(ms)</Box>}
                xTitle={<Box variant='small'># Attempts</Box>}
                empty={
                    < Box textAlign="center" color="inherit" >
                        <b>No data available</b>
                        <Box variant="p" color="inherit">
                            There is no data available
                        </Box>
                    </Box>
                }
                noMatch={
                    < Box textAlign="center" color="inherit" >
                        <b>No matching data</b>
                        <Box variant="p" color="inherit">
                            There is no matching data to display
                        </Box>
                        <Button>Clear filter</Button>
                    </Box >
                }
            />
            {/* <RenderLabels data={props.sourceLink} /> */}
            <MoreInfoPanel1 columnDefinitions={props.currentState[`columnDefinitions${props.columnDefinitionIndex}`]}
                currentState={props.currentState} info1={props.currentState[`${props.line1Id}Info`]} label={props.label}
                info2={props.currentState[`${props.line2Id}Info`]} links={props.sourceLink} replaceToolsContent={props.replaceToolsContent} />
        </Container >
    );
};

const ChartType = props => {
    if (props.info1?.duration?.p50 && props.info2?.duration?.p50) {
        if (Math.abs(props.info1.duration.p50 - props.info2.duration.p50) < 15) {
            return ("log");
        }
    }
    else {
        return ("linear");
    }
};

const DownloadStatsWithLabel = props => {
    if (props.info1) {
        return (
            <div>
                <Box>via AGA</Box>
                <Box>p90 {props.info1.duration?.p90}</Box>
            </div>
        );
    }
    else if (props.info2) {
        return (
            "Direct from source");
    }
    else {
        return ("");
    }
};

const MoreInfoPanel1 = props => {

    if (props.info1?.age && props.info2) {
        return (

            <ExtendingPanel label={<Box color='text-status-info'>More Info</Box>}>
                <Table
                    resizableColumns
                    columnDefinitions={props.columnDefinitions}
                    items={[
                        {
                            link: <Link external href={props.links[1]} variant='info'>Direct</Link>,
                            size: <Box variant='code'>{props.info2?.content_length && prettyBytes(props.info2.content_length)}</Box>,
                            durationp50: <Box variant='code'>{props.info2?.duration?.p50}</Box>,
                            durationp90: <Box variant='code'>{props.info2?.duration?.p90}</Box>,
                            ssltimep50: <Box variant='code'>{props.info2?.ssltime?.stats.p50}</Box >,
                            ssltimep90: <Box variant='code'>{props.info2?.ssltime?.stats.p90}</Box >,
                        },
                        {
                            link: <Link external href={props.links[0]} variant='info'>via AGA</Link>,
                            size: <Box variant='code'>{props.info1?.transferSize && prettyBytes(props.info1.transferSize)}</Box>,
                            age: <Box variant='code'>{props.info1.age.p90}</Box>,
                            durationp50: <Box variant='code'>{props.info1?.duration?.p50}</Box>,
                            durationp90: <Box variant='code'>{props.info1?.duration?.p90}</Box>,
                            compression: <Box variant='code'>{props.info1?.compression}</Box>,
                            ssltimep50: <Box variant='code'>{props.info1?.ssltime?.stats.p50}</Box>,
                            ssltimep90: <Box variant='code'>{props.info1?.ssltime?.stats.p90}</Box>,
                        },
                        {
                            link: <Box variant='p'><Clock color="green" size={18} /> savings</Box>,
                            durationp50: <ValueWithSavings val1={props.info1?.duration?.p50} val2={props.info2?.duration?.p50}></ValueWithSavings>,
                            durationp90: <ValueWithSavings val1={props.info1?.duration?.p90} val2={props.info2?.duration?.p90}></ValueWithSavings>,
                            ssltimep50: <ValueWithSavings val1={props.info1?.ssltime?.stats.p50} val2={props.info2?.ssltime?.stats.p50}></ValueWithSavings>,
                            ssltimep90: <SpaceBetween direction='horizontal' size='xxs'>
                                <PopoverChart key="popoverchart" label="TCP/TLS negotiation time(ms)" line1={props.info1?.ssltime?.readings} line2={props.info2?.ssltime?.readings} />
                                < ValueWithSavings key="popoversaving" val1={props.info1?.ssltime?.stats.p90} val2={props.info2?.ssltime?.stats.p90} />
                            </SpaceBetween>,
                            // ssltimep90:
                            //   < ValueWithSavings val1={props.info1?.ssltime?.stats.p90} val2={props.info2?.ssltime?.stats.p90} ></ValueWithSavings>,
                            size: props.info1?.compression && <ValueWithSavings val1={props.info1?.transferSize} val2={props.info2?.content_length}></ValueWithSavings>,
                        }
                    ]
                    }
                    loadingText="Loading resources"
                    sortingDisabled
                    variant="embedded"
                    empty={
                        < Box textAlign="center" color="inherit" >
                            <b>No resources</b>
                            <Box
                                padding={{ bottom: "s" }}
                                variant="p"
                                color="inherit"
                            >
                                No resources to display.
                            </Box>
                            <Button>Create resource</Button>
                        </Box >
                    }
                // header={<Header> Simple table </Header>}
                />
            </ExtendingPanel >
        );
    }
    else {
        return (<div></div>);
    }
};

const LineChartPanel = props => {
    return (
        // <Container
        //   header={<Box variant='h4'>{props.label}</Box>}>
        <SpaceBetween size='l'>
            <Box variant='h5'>{props.label}</Box>
            <LineChart
                series={
                    [
                        {
                            title: "TCP/TLS negotiation time",
                            type: "line",
                            data: props.currentState[`${props.lineId}`]['rtssl'],
                        },
                        {
                            title: "Origin DNS lookup",
                            type: "line",
                            data: props.currentState[`${props.lineId}`]['sthupdns'],
                        },
                        {
                            title: "Origin connect",
                            type: "line",
                            data: props.currentState[`${props.lineId}`]['sthupconnect'],
                        },
                        {
                            title: "Origin TTFB",
                            type: "line",
                            data: props.currentState[`${props.lineId}`]['sthupttfb'],
                        },
                        // {
                        //   title: "Persistent connection",
                        //   type: "threshold",
                        //   x: 5,
                        // },
                    ]}
                xDomain={
                    [
                        1, props.mode === "LargeFiles" ? NO_ATTEMPTS_LARGEFILES : NO_ATTEMPTS
                    ]}
                hideFilter
                ariaLabel="Stacked area chart, multiple metrics"
                errorText="Error loading data."
                height={150}
                loadingText="Loading chart"
                recoveryText="Retry"
                xScaleType="linear"
                yScaleType='log'
                // yTitle={< Box variant='small' > Total time(ms)</Box >}
                xTitle=<Box variant='small'># Attempts</Box>
            />
        </SpaceBetween>);
};

const MoreInfoPanel = props => {

    if (props.info1?.age && props.info2) {
        return (

            <ExtendingPanel label={<Box color='text-status-info'>More Info</Box>}>
                <SpaceBetween>
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween direction="horizontal" size="s">

                            <Link external href={props.links[1]}>Source Link</Link>
                            {/* {props.info2 && */}
                            <ValueWithInfo label="Size" index="10" replaceToolsContent={props.replaceToolsContent}>
                                <code>
                                    {props.info2?.content_length && prettyBytes(props.info2.content_length)}
                                </code></ValueWithInfo>
                            {/* } */}
                        </SpaceBetween>

                        <SpaceBetween direction='vertical' size='xxs'>
                            {props.info2?.ssltime && <ValueWithInfo label="TCP/TLS negotiation time(ms)" index="13" replaceToolsContent={props.replaceToolsContent}>

                                <Box variant='text-body-secondary'><code>
                                    p50: {props.info2?.ssltime?.stats.p50},
                                    p90: {props.info2?.ssltime?.stats.p90}</code>
                                </Box>
                            </ValueWithInfo>}
                            <ValueWithInfo label="Total time(ms)" index="9" replaceToolsContent={props.replaceToolsContent}>

                                <Box variant='text-body-secondary'><code>
                                    p50: {props.info2?.duration?.p50},
                                    p90: {props.info2?.duration?.p90}</code>
                                </Box>
                            </ValueWithInfo>
                        </SpaceBetween>

                    </ColumnLayout>
                </SpaceBetween>
                <hr />
                <SpaceBetween direction="vertical" size="xxxs">
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween direction="horizontal" size="s">

                            <Link external href={props.links[0]}>CloudFront Link</Link>
                            <ValueWithInfo label="Compression" index="11" replaceToolsContent={props.replaceToolsContent}>

                                <code>  {props.info1.compression}</code></ValueWithInfo>
                            <ValueWithInfo label="Age(sec)" index="4" replaceToolsContent={props.replaceToolsContent}>
                                <code>  {props.info1.age.p90}</code></ValueWithInfo>
                            <ValueWithLabel label="Size" index="10" replaceToolsContent={props.replaceToolsContent}>

                                {props.info1.compression != "" ?
                                    <code>< ValueWithSavings label={props.info1?.transferSize && prettyBytes(props.info1.transferSize)} val1={props.info1.transferSize} val2={props.info2['content_length']} ></ValueWithSavings></code> :
                                    <code>props.info1?.transferSize</code>}

                            </ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween>
                            {/* <ValueWithInfo label="TTFB (ms)" index="7" replaceToolsContent={props.replaceToolsContent}>
                <Box variant='text-body-secondary'><code>
                  p90: {props.info1.ttfb.p90},
                  p50: {props.info1.ttfb.p50}</code>
                </Box>
              </ValueWithInfo>

              <ValueWithInfo label="TTLB (ms)" index="8" replaceToolsContent={props.replaceToolsContent}>
                <Box variant='text-body-secondary'><code>
                  p90: {props.info1.ttlb.p90},
                  p50: {props.info1.ttlb.p50}</code>
                </Box></ValueWithInfo> */}
                            {props.info2?.ssltime && <ValueWithInfo label={<PopoverChart label="TCP/TLS negotiation time(ms)" line1={props.info1?.ssltime?.readings} line2={props.info2?.ssltime?.readings} />} index="13" replaceToolsContent={props.replaceToolsContent}>

                                <Box variant='text-body-secondary'>
                                    <ValueWithSavings label={<code>
                                        p50: {props.info1?.ssltime?.stats.p50},
                                        p90: {props.info1?.ssltime?.stats.p90}</code>} val1={props.info1?.ssltime?.stats.p90} val2={props.info2?.ssltime?.stats.p90}></ValueWithSavings>
                                </Box>
                            </ValueWithInfo>}
                            <ValueWithLabel label="Total time(ms)" index="9" replaceToolsContent={props.replaceToolsContent}>
                                <Box variant='text-body-secondary'>
                                    <ValueWithSavings label={<code>
                                        p50: {props.info1?.duration?.p50},
                                        p90: {props.info1?.duration?.p90}</code>} val1={props.info1?.duration?.p90} val2={props.info2?.duration?.p90}></ValueWithSavings>
                                </Box></ValueWithLabel>
                        </SpaceBetween>
                    </ColumnLayout>
                </SpaceBetween >
                <SpaceBetween>
                    {props.label === "APIsPOST" && props.currentState["CFTSAPIsPOST"] && <AreaChartPanel currentState={props.currentState}
                        replaceToolsContent={props.replaceToolsContent}
                        lineId="CFTSAPIsPOST"
                        mode="APIsPOST" label="Time spent at various stages in CloudFront" description="Comparing POST upload speeds - Amazon CloudFront vs Lambda origins" />}
                </SpaceBetween>
            </ExtendingPanel >
        );
    }
    else {
        return (<div></div>);
    }
};

const PopoverChart = props => {
    return (
        <Popover key={props.label}
            // header={`${props.label}`}
            // renderWithPortal
            size="medium"
            // triggerType='custom'
            dismissButton={false}
            fixedWidth
            content={
                <LineChart
                    hideLegend
                    hideFilter
                    ariaLabel="Single data series line chart"
                    errorText="Error loading data."
                    height={75}
                    loadingText="Loading chart"
                    // recoveryText="Retry"
                    // xScaleType="linear"
                    yScaleType='log'
                    // yScaleType={<ChartType info1={props.currentState[`${props.line1Id}Info`]} info2={props.currentState[`${props.line2Id}Info`]} />}
                    // xTitle={<Popover
                    //   dismissAriaLabel="Close"
                    //   content={
                    //     <StatusIndicator type="info">
                    //       The number of requests
                    //     </StatusIndicator>
                    //   }><Box># Attempts</Box></Popover>}
                    yTitle={props.label}
                    // xTitle="# Attempts"
                    series={[
                        {
                            title: "via AGA",
                            type: 'line',
                            data: props.line1,
                            // valueFormatter: function l(e) {
                            //   return Math.abs(e) >= 1e9
                            //     ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
                            //     "G"
                            //     : Math.abs(e) >= 1e6
                            //       ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
                            //       "M"
                            //       : Math.abs(e) >= 1e3
                            //         ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
                            //         "K"
                            //         : e.toFixed(2);
                            // }
                        },
                        {
                            title: "Direct from source",
                            type: "line",
                            data: props.line2,
                            // valueFormatter: function l(e) {
                            //   return Math.abs(e) >= 1e9
                            //     ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
                            //     "G"
                            //     : Math.abs(e) >= 1e6
                            //       ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
                            //       "M"
                            //       : Math.abs(e) >= 1e3
                            //         ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
                            //         "K"
                            //         : e.toFixed(2);
                            // }
                        }]}
                />
            }
        >
            <BarChart2 />
        </Popover>
    );
}

const DirectPreviewPanel = props => {
    return (
        <Container header={<Header variant="h2">Direct</Header>}>
            <DirectTablePanel currentState={props.currentState} />
        </Container>
    );
};

const DirectTablePanel = props => {
    return (
        <div>
            <Table
                columnDefinitions={[
                    {
                        id: "contentlength",
                        header: "Content Length",
                        cell: item => item.length || "-",
                    },
                    {
                        id: "time",
                        header: "Time Taken(msec)",
                        cell: item => item.time || "-"
                    }
                ]}
                items={props.results}
                loadingText="Loading data.."
                sortingDisabled
            />
            <StatsPanel stats={props.stats} />
        </div>
    );
};

const PolicyPanel = props => {
    return (
        <FormField
            label=""
            info={<div><pre>{JSON.stringify(JSON.parse(props.policy), null, 2)}</pre></div>}
            stretch={true}
        >
        </FormField>
    );
};

// Breadcrumb content
const BreadcrumbsItems =
    [
        {
            text: 'Home',
            href: '/'
        },
        {
            text: 'Acceleration',
            href: '/acceleration'
        }
    ];

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
    <HelpPanel
        header={<h3>Content Acceleration</h3>}
        footer={
            <div>
                <h3>
                    Learn more <Icon name="external" />
                </h3>
                {/* <ul>
          <li>
            <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/private-content-signed-urls.html" target="_blank">
              Using SignedURLs in Amazon CloudFront?
            </a>
          </li>
        </ul> */}
            </div>
        }
    >
        <p>
            Accelerate delivery of content by using CloudFront vs connecting directly to your origin.
        </p>
    </HelpPanel>,
    ...CFTableTools
];
